import React, { createRef } from "react";
import { makeAutoObservable, autorun, toJS, reaction, observe } from "mobx";
import { phoneValidator, emailValidator, getValidPhone } from "../Helper/utils";
import { get } from "lodash";
import { SC } from "../Api/serverCall";

import debounce from "lodash/debounce";
import { InspectionService } from "../services";
import toast from "react-hot-toast";

class AssignToModal {
  operatorsBySupervisor = [];
  showModal = false;

  constructor() {
    // this.getUsersBySuperviser();
    makeAutoObservable(this);
  }

  toggle = () => {
    this.showModal = !this.showModal;

    if (this.showModal) {
      this.getUsersBySuperviser();
    } else {
      this.operatorsBySupervisor = [];
    }
  };

  assignOperatorSelected(currentUser) {
    this.operatorsBySupervisor
      .filter((us) => us._id != currentUser._id)
      .forEach((user) => (user.check = false));
    currentUser.setCheck();
  }
  searchQuery = debounce((text) => {
    this.getUsersBySuperviser(text);
  }, 1000);

  getUsersBySuperviser = (text = "") => {
    InspectionService.usersBySuperviser(text).then((res) => {
      this.operatorsBySupervisor = res.data.data.map((user) => new User(user));
    });
  };

  assignToOperator = (requestId, getData, reassign) => {
    if (this.operatorsBySupervisor.filter((user) => user.check).length) {
      let postData = {
        id: requestId,
        operator_id: this.operatorsBySupervisor.filter(
          (user) => user.check
        )?.[0]._id,
      };

      let service = reassign
        ? InspectionService.reAssignOperator
        : InspectionService.assignOperator;

      service(postData).then((res) => {
        if (res.status == 200) {
          this.toggle();
          toast.success(res.data.data);
          getData?.();
        }
      });
    } else {
      toast.error("Please Select User First!");
    }
  };
}

class User {
  active = null;
  created_at = null;
  department_id = null;
  dob = null;
  email = null;
  employee_id = null;
  end_date = null;
  first_name = null;
  lastLogin = null;
  last_name = null;
  module_ids = null;
  name = null;
  start_date = null;
  supervisor_id = null;
  type = null;
  updated_at = null;
  _id = null;
  check = false;
  new_request_ids = [];
  otp = null;
  otp_time = null;
  refreshToken_at = null;

  constructor(props) {
    if (props) {
      Object.entries(props).forEach(([name, value]) => {
        if (this.hasOwnProperty(name)) {
          this[name] = value;
        }
        this[name] = value;
      });

      autorun(() => {});
    }

    makeAutoObservable(this);
  }
  setCheck = () => {
    this.check = !this.check;
  };
}
export default AssignToModal;
