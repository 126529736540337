import { AppIcon } from "assets/images/icons/svgIcons";
import debounce from "lodash/debounce";
import get from "lodash/get";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { Edit, Plus, Trash } from "react-feather";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { appStatusProps, deleteAction } from "utility/Utils";
import List from "views/wasfaty/components/listing/List";
import { ClosedUnderWarantyService } from "../../wasfaty/services";
import AuthUser from "views/wasfaty/services/AuthService";

const SortOptions = [
  { label: "Request Date", value: "created_at" },
  { label: "Request No", value: "request_number" },
  { label: "Service Requested", value: "security_verfication" },
];

const ClosedUnderWaranty = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const searchRef = useRef();
  searchRef.current = search;

  const [searchLoading, setSearchLoading] = useState(false);
  const [statusTabs, setStatusTabs] = useState(appStatusProps);
  const [activeStatusTab, setActiveStatusTab] = useState("all");
  const activeStatusTabRef = useRef();
  activeStatusTabRef.current = activeStatusTab;

  const [sort, setSort] = useState(SortOptions[0].value);
  const sortRef = useRef();
  sortRef.current = sort;

  const [filters, setFilters] = useState();
  const filtersRef = useRef();
  filtersRef.current = filters;

  const [dateFilters, setDateFilters] = useState({});
  const dateFiltersRef = useRef();
  dateFiltersRef.current = dateFilters;

  const [columns, omitColumns] = useState({
    request_no: false,
    requested_by: false,
    requested_department: false,
    request_date: false,
    service_requested: false,
    status: false,
    progress: false,
  });
  const [items, setItems] = useState([]);
  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    to: 0,
    totalPages: 0,
    to: 0,
  });

  const Service = ClosedUnderWarantyService;

  const getAllItems = (query = { selected: 0, isFromSearch: false }) => {
    const params = {
      page: query.selected + 1,
      per_page: 10,
      search: searchRef.current,
      activetab: "passed",
      sortby: sortRef.current,
      filters: JSON.stringify(filtersRef.current),
      fromDate: dateFiltersRef.current?.fromDate,
      toDate: dateFiltersRef.current?.toDate,
    };

    console.log(params);
    setSearchLoading(query.isFromSearch);

    Service.find(params).then((res) => {
      if (res?.data?.data) {
        // setItems(requestsData);
        setItems(res.data.data.data);
        let counts = res.data.resultCount;
        if (counts) {
          let newTabs = {};
          Object.entries(statusTabs).forEach(([key, tab]) => {
            tab.count = counts[key];
            newTabs[key] = tab;
          });
          setStatusTabs(newTabs);
        }
        const _pagination = res.data.data;
        const page = _pagination.current_page;
        const perpage = _pagination.per_page;
        const totalPages = _pagination.total;
        const pageCount = Math.ceil(totalPages / perpage);
        const to = _pagination.to;

        setpagination({
          page,
          pageCount,
          totalPages,
          to,
        });
      }
      setSearchLoading(false);
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    onSearch({ isFromSearch: true, selected: 0 });
  };

  const onSearch = useRef(
    debounce((p) => {
      getAllItems(p);
    }, 1000)
  ).current;

  const basicColumns = [
    {
      name: "Created By",
      disableStartCase: true,
      sortable: false,
      omit: columns.license_number,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {get(row, "user.name")}
          </span>
        );
      },
    },
    {
      name: "Number",
      disableStartCase: true,
      sortable: false,
      omit: columns.license_number,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {get(row, "license_information.PERMIT_NO")}
            {get(row, "report_940.reportNo")}
            {get(row, "field_survey.inspectionNumber")}
          </span>
        );
      },
    },
    {
      name: "Varification Type",
      disableStartCase: true,
      sortable: false,
      omit: columns.verification_type,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {get(row, "verification_type.label", "N/A")}
          </span>
        );
      },
    },
    {
      name: "Excavation Stage",
      disableStartCase: true,
      sortable: false,
      omit: columns.excavation_stage,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {get(row, "excavation_stage.label", "N/A")}
          </span>
        );
      },
    },
    // {
    //   name: "",
    //   disableStartCase: true,
    //   omit: columns.excavation_stage,
    //   sortable: false,
    //   cell: (row) => {
    //     return (
    //       <span className="font-weight-bold text-truncate app-primary-table__column--dark-bold">
    //         {get(row, "excavation_stage") || "N/A"}
    //       </span>
    //     );
    //   },
    // },

    // {
    //   name: "Portfolio / Department",
    //   sortable: false,
    //   omit: columns.requested_department,

    //   cell: (row) => {
    //     return (
    //       <span className="font-weight-bold text-truncate">
    //         {get(row, "current_portfolio", "N/A")}
    //       </span>
    //     );
    //   },
    // },
    {
      name: "Created Date",

      omit: columns.created_at,
      sortable: false,
      cell: (row) => (
        <span>
          {moment(row.created_at).format("DD-MM-YYYY")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(row.created_at).format("h:mm")}
          </span>
        </span>
      ),
    },
    // {
    //   name: <StatusHeader />,
    //   disableStartCase: true,
    //   sortable: false,
    //   omit: columns.status,

    //   cell: (row) => {
    //     const color = appStatusProps?.[row?.status]?.color || "";
    //     const statusLabel = appStatusProps?.[row?.status]?.label || "";
    //     return (
    //       <Badge
    //         color="light-success"
    //         className={`app-primary-table__column--badge ${color}`}
    //       >
    //         {statusLabel || "N/A"}
    //       </Badge>
    //     );
    //   },
    // },

    {
      name: "Actions",
      sortable: false,
      width: "200px",
      cell: (row) => {
        return (
          <div className="app-primary-table__column--view-and-download">
            <button
              className={!AuthUser.isQualityTeam ? "view" : "viewqt"}
              onClick={() =>
                navigate(`/close-license-under-warranty/Details/${row._id}`)
              }
            >
              <AppIcon icon="eyeIcon" />
            </button>

            {(AuthUser.isSupervisor || AuthUser.isAdmin) &&
              !AuthUser.isQualityTeam && (
                <>
                  <Trash
                    size={18}
                    color="red"
                    onClick={() => deleteCall(row._id)}
                  />
                  <Edit
                    size={18}
                    className="mx-2"
                    color="#7C8597"
                    onClick={() =>
                      navigate(
                        `/close-license-under-warranty-visit/edit/${row._id}`
                      )
                    }
                  />
                </>
              )}

            {AuthUser.isQualityTeam ? (
              <>
                <Edit
                  size={18}
                  className="mx-2"
                  color="#7C8597"
                  onClick={() =>
                    navigate(
                      `/close-license-under-warranty-visit/edit/${row._id}`
                    )
                  }
                />
              </>
            ) : null}
            {/* <button
              className="download"
              onClick={() =>
                InspectionService.downloadPdf(row._id).then((file) => {
                  file.click();
                })
              }
            >
              <span className="download--text">Download</span>
            </button> */}
          </div>
        );
      },
    },
  ];

  const deleteCall = (id) => {
    deleteAction(() =>
      Service.delete(id).then((res) => {
        if (res.status == 200) {
          toast.success(res.data.data);
          getAllItems();
        }
      })
    );
  };

  const onFilters = (_filters) => {
    let filter = {};
    Object.entries(_filters).forEach(([key, { value }]) => {
      filter[key] = value;
    });

    setFilters(filter);
    setTimeout(() => {
      getAllItems({ isFromSearch: true, selected: 0 });
    }, 100);
  };

  return (
    <Fragment>
      <div className="app-list">
        <header>
          <div className="searchField">
            <InputGroup className="input-group">
              <InputGroupText>
                <AppIcon icon="searchIcon" />
              </InputGroupText>
              <Input
                type="text"
                value={search}
                placeholder={intl.formatMessage({
                  id: "Search",
                  defaultMessage: "Search",
                })}
                onChange={handleSearch}
              />
            </InputGroup>
            {searchLoading && (
              <div className="search-spinner">
                <Spinner />
              </div>
            )}
          </div>
          {AuthUser.isAdmin ? (
            <Button.Ripple
              color="primary"
              onClick={() => {
                navigate("/close-license-under-warranty/dashboard");
              }}
              style={{ height: 35 }}
              className={"waves-effect round mx-1"}
            >
              <FormattedMessage defaultMessage={"Dashboard"} id={"Dashboard"} />
            </Button.Ripple>
          ) : null}

          {!AuthUser.isQualityTeam ? (
            <Button.Ripple
              outline
              color="primary"
              onClick={() => {
                navigate("/close-license-under-warranty-visit");
              }}
              className={"waves-effect round"}
            >
              <Plus size={18} />
              <FormattedMessage defaultMessage={"Add"} id={"Add"} />
            </Button.Ripple>
          ) : null}

          {/* <div className="sortedBy">
            <small className="sort-label">Sorted by:</small>
            <AppListingColumnSelect
              defaultValue={"created_at"}
              chevronIcon={true}
              options={SortOptions}
              onChange={(_s) => {
                setSort(_s);
                onSearch();
              }}
            />
          </div> */}
        </header>
      </div>

      <List
        Mock={items}
        pagination={pagination}
        basicColumns={basicColumns}
        handlePageChange={getAllItems}
        exportUrl={`${Service.route}/export?status=passed`}
        isLoading={searchLoading}
        // filters={requestFilters}
        onFilters={onFilters}
        // noFilters={true}
        filters={{
          fromDate: dateFiltersRef.current?.fromDate,
          toDate: dateFiltersRef.current?.toDate,
          search: searchRef.current,
        }}
        exportName="submissions"
        isFilter={true}
        handleChange={(value) => {
          setDateFilters({
            fromDate: value[0]?.toDateString?.(),
            toDate: value?.[1]?.toDateString?.(),
          });
          setTimeout(() => {
            getAllItems({ isFromSearch: true, selected: 0 });
          }, 300);
        }}
        // tableProps={{
        //   onRowClicked: (row) => showData(row._id)
        // }}
      />

      {/* {pdf && (

        <FileViewer
          fileType={type}
          filePath={pdf}
        // errorComponent={CustomErrorComponent}
        // onError={this.onError} 
        />
      )} */}
    </Fragment>
  );
};

export default ClosedUnderWaranty;
const type = "pdf";
