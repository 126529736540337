import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";

import "./dashboard.scss";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
} from "reactstrap";
import { IntlService } from "views/wasfaty/services";
import { SC } from "views/wasfaty/Api/serverCall";
import { useLocation, useRoutes } from "react-router-dom";
import ListingTableHeader from "views/wasfaty/components/ListingTableHeader";
import { AppIcon } from "assets/images/icons/svgIcons";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import FlatPicker from "react-flatpickr";
const filterInitial = {
  municipality: "",
  nameConsultant: "",
  nameLaboratory: "",
  nameMunicipality: "",
  nameStreet: "",
  nameDistrict: "",
  nameDepartment: "",
  nameMain: "",
  contractorName: "",
  date: [],
};

const Widget = ({ title, count }) => {
  return (
    <>
      <div className="widget-container">
        <p className="title">{title}</p>
        <p className="count">{count}</p>
      </div>
      <div className="after" />
    </>
  );
};




const Submissions = ({ title, xTitle, data }) => {
  const settings = {
    series: [
      {
        data: Object.values(data),
        name: "count",
      },
    ],
    options: {
      colors: ["#10A945"],
      chart: {
        type: "bar",
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        // enabled: false,
      },
      xaxis: {
        title: {
          text: IntlService.m(xTitle),
        },

        categories: Object.keys(data),
      },
    },
  };
  return (
    <Card className="mb-2 p-2 transition shadow-none" style={{ height: 343 }}>
      <CardTitle
        className="card-heading"
        tag="h4"
        style={{ position: "absolute" }}
      >
        {title}
      </CardTitle>
      <CardBody className="mt-2">
        <Chart
          options={settings.options}
          series={settings.series}
          type="bar"
          height={270}
        />
      </CardBody>
    </Card>
  );
};

const FilterSidebar = ({
  setFilterOpen,
  filterOpen,
  handleChange,
  filterData,
  filterOptions,
  getDashboard,
  setfilterData,
  slug,
}) => {
  const toggleCanvas = () => {
    setFilterOpen((prev) => !prev);
  };



  const getfilterOptions = (optionsFor) => {
    return optionsFor.map((itm) => {
      return { label: itm, value: itm };
    });
  };

  return (
    <div className="demo-inline-spacing">
      <Offcanvas
        direction={"end"}
        isOpen={filterOpen}
        toggle={() => setFilterOpen((prev) => !prev)}
      >
        <OffcanvasHeader toggle={toggleCanvas}>Filter Bar</OffcanvasHeader>
        <OffcanvasBody className="my-auto d-flex flex-column gap-1">
          {slug != "940-verification" && (
            <>
              <div>
                <Label className="form-label">
                  {IntlService.m("Municipality")}
                </Label>

                <Select
                  isClearable
                  onChange={(e) => handleChange("municipality", e)}
                  value={filterData.municipality}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(
                    filterOptions?.municipalityName || []
                  )}
                />
              </div>
              <div>
                <Label className="form-label">
                  {IntlService.m("Consultant")}
                </Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("nameConsultant", e)}
                  value={filterData.nameConsultant}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(
                    filterOptions?.consultantName || []
                  )}
                />
              </div>
              <div>
                <Label className="form-label">
                  {IntlService.m("Laboratory")}
                </Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("nameLaboratory", e)}
                  value={filterData.nameLaboratory}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(
                    filterOptions?.laboratoryName || []
                  )}
                />
              </div>

              <div>
                <Label className="form-label">{IntlService.m("Street")}</Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("nameStreet", e)}
                  value={filterData.nameStreet}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(filterOptions?.streetName || [])}
                />
              </div>
              <div>
                <Label className="form-label">
                  {IntlService.m("District")}
                </Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("nameDistrict", e)}
                  value={filterData.nameDistrict}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(filterOptions?.districtName || [])}
                />
              </div>
              <div>
                <Label className="form-label">
                  {IntlService.m("Department")}
                </Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("nameDepartment", e)}
                  value={filterData.nameDepartment}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(
                    filterOptions?.departmentName || []
                  )}
                />
              </div>

              <div>
                <Label className="form-label">
                  {IntlService.m("Contractor")}
                </Label>
                <Select
                  isClearable
                  onChange={(e) => handleChange("contractorName", e)}
                  value={filterData.contractorName}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={getfilterOptions(
                    filterOptions?.mainContractorName || []
                  )}
                />
              </div>
            </>
          )}

          <div>
            <Label className="form-label">{IntlService.m("Date Range")}</Label>
            <FlatPicker
              className="form-control date-picker-input"
              options={{
                dateFormat: "d-m-Y",
                mode: "range",
              }}
              onClose={(e) => {
                console.log("e", e);
                if (e.length == 2) handleChange("date", e);
              }}
              value={filterData?.date}
              placeholder={IntlService.m("Filter Date")}
            />
          </div>
          <div className="d-flex gap-2 justify-content-center mt-2">
            <Button
              color="primary"
              onClick={() => {
                getDashboard();
                toggleCanvas();
              }}
            // className="mb-1"
            >
              Filter
            </Button>
            <Button
              outline
              color="secondary"
              onClick={() => {
                setfilterData(filterInitial);
                toggleCanvas();
                getDashboard(true);
              }}
            >
              Reset
            </Button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

function DashboardJD() {
  const slug = window.location.pathname.split("/")[1];
  const [filterOpen, setFilterOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(null);

  const [filterData, setfilterData] = useState(filterInitial);
  const handleChange = (name, value) => {
    setfilterData((prev) => ({ ...prev, [name]: value }));
  };


  const location = useLocation();
  const currentPath = location.pathname;
  const extractedPath = currentPath.split('/')[1]
  console.log(extractedPath, "current");


  const getDashboard = (reset) => {
    let data = {
      municipality: filterData.municipality?.value,
      nameConsultant: filterData.nameConsultant?.value,
      nameLaboratory: filterData.nameLaboratory?.value,
      nameMunicipality: filterData.nameMunicipality?.value,
      nameStreet: filterData.nameStreet?.value,
      nameDistrict: filterData.nameDistrict?.value,
      nameDepartment: filterData.nameDepartment?.value,
      nameMain: filterData.nameMain?.value,
      contractorName: filterData.contractorName?.value,
      date: {
        to: filterData.date[1]?.toISOString(),
        from: filterData.date[0]?.toISOString(),
      },
    };
    if (reset) data = {};

    SC.postCall({ url: slug === "dashboard" ? "main-dashboard" : "dashboard", data: { ...data, slug } }).then((res) => {
      setData(res?.data?.data);
      setLoading(false);
    });
  };

  const getFilters = () => {
    SC.getCall({ url: "licenceFilters" }).then((res) => {
      setFilterOptions(res?.data?.data);
    });
  };

  useEffect(() => {
    setLoading(true);

    getDashboard();
    getFilters();
  }, [slug]);

  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  return (
    <div>
      <div className="d-flex justify-content-end my-2">
        <Button
          outline
          className="filter-label"
          onClick={() => setFilterOpen((prev) => !prev)}
        >
          <AppIcon icon="filterIcon" />
          <div className="text">Filter</div>
        </Button>
      </div>

      <FilterSidebar
        setFilterOpen={setFilterOpen}
        filterOpen={filterOpen}
        handleChange={handleChange}
        filterData={filterData}
        filterOptions={filterOptions}
        getDashboard={getDashboard}
        setfilterData={setfilterData}
        slug={slug}
      />
      {slug != "dashboard" && slug != "mababni-inspection-visit" ?
        <Card>
          <CardBody className="widget-body">
            {data?.widgets?.map((wid) => (
              <Widget title={wid.title} count={wid.value} />
            ))}
          </CardBody>
        </Card>
        :
        <Card>
          <CardBody>
            {data?.widgets && data.widgets.length > 0 && (
              <div className="widget-body d-flex">
                {data.widgets.slice(0, Math.ceil(data.widgets.length / 2)).map((wid) => (
                  <Widget key={wid.title} title={wid.title} count={wid.value} />
                ))}
              </div>
            )}
            {data?.widgets && data.widgets.length > 1 && (
              <div className="widget-body d-flex">
                {data.widgets.slice(Math.ceil(data.widgets.length / 2)).map((wid) => (
                  <Widget key={wid.title} title={wid.title} count={wid.value} />
                ))}
              </div>
            )}
          </CardBody>
        </Card>
      }


      {extractedPath != "dashboard" ?
        <Submissions
          title="Number of reports for each Inspector"
          xTitle="Inspectors Names"
          data={data?.totaluserSubmission || []}
        /> : null}
      {extractedPath === "940-verification" ?
        <Submissions
          title="Number of visit for each permit number"
          xTitle="Permit Numbers"
          data={data?.totalPermitSubmission || []}
        /> : null}
      {extractedPath != "940-verification" && extractedPath != "dashboard" ? <Submissions
        title="Number of Submission for per main classification "
        xTitle="Main Classification Submissions Numbers"
        data={data?.totaluserSubmissionOnClasification || []}
      /> : null}
    </div>
  );
}

export default DashboardJD;
