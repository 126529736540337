import { lazy } from "react";
import Dashboard from "../../views/pages/Graph/Dashboard";
import CompanyDetails from "../../views/pages/Graph/CompanyDetail";
import MumtahilList from "views/pages/List/MumtahilList";
import ClosedReportList from "views/pages/List/ClosedReportList";
import EmergencyVisit from "views/pages/List/EmergencyVisit";
import ClosedUnderWaranty from "views/pages/List/ClosedUnderWaranty";
import DashboardJD from "views/pages/Graph/DashboardJD";
import MababniList from "views/pages/List/MababniList";
import SchduleTaskList from "views/pages/List/SchduleTaskList";

const UserList = lazy(() => import("../../views/pages/List/UserList"));
const CompletedRequestsList = lazy(() =>
  import("../../views/pages/List/CompletedRequestsList")
);
const CloseInspection = lazy(() =>
  import("../../views/pages/List/CloseInspection")
);
const FieldObservation = lazy(() =>
  import("../../views/pages/List/FieldObservation")
);
const LicenseList = lazy(() => import("../../views/pages/List/LicenseList"));

const RequestsList = lazy(() => import("../../views/pages/List/RequestsList"));
// const RegionList = lazy(() => import("../../views/pages/List/RegionList"));
const ModuleList = lazy(() => import("../../views/pages/List/ModuleList"));
// const SiteList = lazy(() => import("../../views/pages/List/SiteList"));

const ListRoutes = [
  {
    element: <UserList />,
    path: "/users",
    route: "users",
    slug: "users",
    title: "Users",
  },
  // {
  //   element: <RequestsList />,
  //   path: "/requests",
  //   route: "requests",
  //   slug: "Requests",
  //   title: "Requests",
  // },
  {
    element: <CompletedRequestsList />,
    path: "/inspection-list",
    route: "inspection-list",
    slug: "inspection-list",
    title: "Submissions",
    isAuth: true,
  },
  {
    element: <EmergencyVisit />,
    path: "/emergency-list",
    route: "emergency-list",
    slug: "emergency-list",
    title: "Submissions",
    isAuth: true,
  },

  {
    element: <CloseInspection />,
    path: "/940-verification",
    route: "940-verification-form",
    slug: "940-verification-form",
    title: "940 Verification Form",
    isAuth: true,
  },
  {
    element: <SchduleTaskList />,
    path: "/Schedule-Task",
    route: "Schedule-Task-Form",
    slug: "Schedule-Task-Form",
    title: "Schedule Task Form",
    isAuth: true,
  },
  // {
  //   element: <ClosedReportList />,
  //   path: "/expired-verfication-license",
  //   route: "Expired verfication license-form",
  //   slug: "Expired verfication license-form",
  //   title: "Expired verfication license",
  //   isAuth: true,
  // },
  {
    element: <MababniList />,
    path: "/mababni-inspection-visit",
    route: "mababni-inspection-visit",
    slug: "mababni-inspection-visit",
    title: "Mababni Inspection Visit",
    isAuth: true,
  },
  {
    element: <ClosedUnderWaranty />,
    path: "/close-license-under-warranty",
    route: "close-license-under-warranty-form",
    slug: "close-license-under-warranty-form",
    title: "Close license under warranty",
    isAuth: true,
  },
  {
    element: <MumtahilList />,
    path: "/operation_and_maintenance_visit_form",
    route: "operation_and_maintenance_visit_form",
    slug: "operation_and_maintenance_visit_form",
    title: "Mumtahil Verification Form",
    isAuth: true,
  },
  {
    element: <FieldObservation />,
    path: "/field-observation-form",
    route: "field-observation-form",
    slug: "field-observation-form",
    title: "Close Verification Form",
    isAuth: true,
  },

  {
    element: <ModuleList />,
    path: "/Modules",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },
  {
    element: <LicenseList />,
    path: "/License",
    route: "License",
    slug: "License",
    title: "License",
  },
  // {
  //   element: <DashboardJD key="expired-verfication-license" />,
  //   path: "/expired-verfication-license/dashboard",
  //   route: "expired-verfication-license",
  //   slug: "expired-verfication-license",
  //   title: "Expired Verfication License Dasboard",
  // },
  {
    element: <DashboardJD key="mababni-inspection-visit" />,
    path: "/mababni-inspection-visit/dashboard",
    route: "mababni-inspection-visit",
    slug: "mababni-inspection-visit",
    title: "Mababni Inspection Visit License Dasboard",
  },
  {
    element: <DashboardJD key="field-observation-form" />,
    path: "/field-observation-form/dashboard",
    route: "field-observation-form",
    slug: "field-observation-form",
    title: "Field Observation Dashboard",
  },
  {
    element: <DashboardJD key="close-license-under-warranty" />,
    path: "/close-license-under-warranty/dashboard",
    route: "close-license-under-warranty",
    slug: "close-license-under-warranty",
    title: "Close License Under Warranty Dasboard",
  },
  {
    element: <DashboardJD key="inspection-list" />,
    path: "/inspection-list/dashboard",
    route: "inspection-list",
    slug: "inspection-list",
    title: "Inspection Dashboard",
  },
  {
    element: <DashboardJD key="940-verification-visit" />,
    path: "/940-verification/dashboard",
    route: "940-verification",
    slug: "940-verification",
    title: "940 Verification Dashboard",
  },

  // %template%
];
export default ListRoutes;
