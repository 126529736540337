import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Badge, Spinner } from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "reactstrap";
import startCase from "lodash/startCase";

import { IntlService, NavigationService } from "../wasfaty/services";
import ButtonGreen from "./buttons/ButtonGreen";
import ButtonGreenOutline from "./buttons/ButtonGreenOutline";
import AuthUser from "../wasfaty/services/AuthService";
import ReactSignatureCanvas from "react-signature-canvas";
import { observer } from "mobx-react";
import TaskModal from "views/wasfaty/Models/TaskModal";
import ScheduleTaskService from "views/wasfaty/services/SchduleTaskService";
import get from "lodash/get";
import { ScheduleTaskModal } from "views/wasfaty/Models/SchduleTaskModal";
import { SC } from "views/wasfaty/Api/serverCall";

const Signature = observer(({ task }) => {
  if (!task) return null;
  return (
    <div className="w-100">
      <p className="label">{IntlService.m("Signature")}</p>

      <ReactSignatureCanvas
        clearOnResize={false}
        ref={(ref) => {
          ref?.off?.();
          task.setSignatureRef(ref);
        }}
        penColor="transparent"
        canvasProps={{
          className: "signature-canvas",
        }}
      />
    </div>
  );
});

/**
 * @param {object} props
 * @param {TaskModal} props.task
 */
function SubmissionReview({ task }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState();

  const currentUrl = window.location.href;

  // Use a regular expression to extract formName, taskId, and scheduleId
  const match = currentUrl.match(
    /\/([^\/]+)\/Details\/([^?]+)\?task_id=([^&]+)/
  );

  const scheduleId = get(match, "3");

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const taskApproved = (isQa) => {
    setLoading(true);

    if (isQa) {
      SC.postCall({ url: "tasks/qa_approved", data: { id: scheduleId } }).then(
        (res) => {
          if (res.status == 200) {
            toast.success(res?.data?.data);
            setLoading(false);
            setSchedule(res?.data?.result);
          } else {
            toast.error(res.response.data.data);
          }
        }
      );
    } else {
      SC.postCall({ url: "tasks/approved", data: { id: scheduleId } }).then(
        (res) => {
          if (res.status == 200) {
            toast.success(res?.data?.data);
            setLoading(false);
            setSchedule(res?.data?.result);
          } else {
            toast.error(res.response.data.data);
          }
        }
      );
    }
  };

  const taskReject = () => {
    let data = {
      reason: inputValue,
    };

    SC.postCall({
      url: "tasks/rejected",
      data: { ...data, id: scheduleId },
    }).then((res) => {
      if (res.status == 200) {
        toast.success(res?.data?.data);
        setSchedule(res?.data?.result);
      } else {
        toast.error(res.response.data.data);
      }
    });
  };

  const schduleData = () => {
    ScheduleTaskService.getSchedule(scheduleId).then((res) => {
      if (res.status == 200) {
        setSchedule(res?.data?.data);
      } else {
        toast.error(res.response.data.data);
      }
    });
  };

  useEffect(() => {
    if (scheduleId) {
      schduleData();
    }
  }, [scheduleId]);

  const rejectedResons = schedule?.history
    ?.filter((his) => his.is_rejected && !!his.reason)
    .reverse();

  if (!schedule) return null;

  return (
    <div className="submission-review">
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        className="custom-modal"
        centered
      >
        <ModalHeader toggle={toggleModal}>
          {IntlService.m("Reject Reason")}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          {loading ? (
            <Spinner size="lg" />
          ) : (
            <Input
              type="textarea"
              value={inputValue}
              onChange={handleInputChange}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (inputValue != "") {
                taskReject();
                setModalOpen(false);
              } else {
                toast.error(IntlService.m("Please fill the Reject Reason!"));
              }
            }}
            disabled={loading}
          >
            {IntlService.m("Submit")}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            {IntlService.m("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <h3>{IntlService.m("Submission Review")}</h3>

      {((AuthUser.isSupervisor || AuthUser.isQualityTeam || AuthUser.isAdmin) &&
        (schedule.tasks[0].status === "submitted" ||
          schedule?.tasks[0].status === "re-submitted")) ||
      ((AuthUser.isAdmin || AuthUser.user.type == "track_manager") &&
        schedule?.tasks[0].status === "qa_approved") ? (
        <p>
          {IntlService.m(
            "Please review the submission below and select either"
          )}{" "}
          <strong>{IntlService.m("APPROVE")}</strong>
          {IntlService.m(" or ")}
          <strong>{IntlService.m("REJECTED")}</strong>
        </p>
      ) : null}

      <div className="submission-details">
        <div className="head">
          <div className="title">
            <p className="label">{IntlService.m("Submitted By")}</p>{" "}
            <p className="value">{schedule.tasks[0]?.user?.name}</p>
          </div>
          {((AuthUser.isQualityTeam || AuthUser.isAdmin) &&
            (schedule?.tasks[0].status === "submitted" ||
              schedule?.tasks[0].status === "re-submitted")) ||
          ((AuthUser.isAdmin || AuthUser.user.type == "track_manager") &&
            schedule?.tasks[0].status === "qa_approved") ? (
            <div className="approval-buttons">
              <ButtonGreen
                title="Approve"
                onClick={() =>
                  taskApproved(AuthUser.isQualityTeam ? true : false)
                }
                disabled={loading}
                icon={loading && <Spinner size="sm" />}
              />
              <ButtonGreenOutline
                title="Reject"
                onClick={() => {
                  toggleModal();
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="center-content">
          <div>
            <p className="label">{IntlService.m("Start Date")}</p>
            <p className="value">
              {moment(schedule.started_date).format("llll")}
            </p>
          </div>
          <div>
            <p className="label">{IntlService.m("Submitted Date")}</p>
            <p className="value">
              {moment(schedule.submitted_date).format("llll")}
            </p>
          </div>
          {/* 
          <div>
            <p className="label">{IntlService.m("Steps")}</p>
            <p className="steps">
              {schedule.steps.map((step, index) => {
                return (
                  <p key={index} className="step">
                    {IntlService.m(step.label)}
                  </p>
                );
              })}
            </p>
          </div> */}
          <div>
            <p className="label">{IntlService.m("Status")}</p>
            <p className="m-0">
              <span
                className={`badge ${
                  schedule?.tasks[0].status || "text-black"
                } `}
                style={{ fontWeight: 600 }}
              >
                {" "}
                {IntlService.m(startCase(schedule?.tasks[0].status) || "N/A")}
                {}
              </span>
            </p>
          </div>
        </div>

        {!!rejectedResons?.length && (
          <div className="center-content d-flex justify-content-start flex-column">
            <p className="label">{IntlService.m("Reject Reason")}</p>
            {rejectedResons.map((his, i) => {
              return (
                <>
                  <p className="value">
                    {i + 1}) {his.reason}
                  </p>
                </>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(SubmissionReview);
