import { Resources } from "../Resource";
import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "schedule-task";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  ranking = {};
  formNames = [
    {
      type: "Excavation Pathway",
      value: "inspection-visit",
      label: "Inspection Visit",
    },
    {
      type: "Excavation Pathway",
      value: "expired-verfication-license-form",
      label: "Expired Verfication License",
    },
    {
      type: "Excavation Pathway",
      value: "close-license-under-warranty-visit",
      label: "Close License Under Waranty",
    },
    {
      type: "Excavation Pathway",
      value: "emergency-visit",
      label: "Emergency Visit Form",
    },
    {
      type: "No Track (Pathway)",
      value: "940-verification-visit",
      label: "940 Verification Form",
    },
    {
      type: "Operation and Maintenance Pathway",
      value: "operation_and_maintenance_visit",
      label: "Operation And Maintenance",
    },
    {
      type: "Building Pathway",
      value: "field-observation-visit",
      label: "Field Observation Form",
    },

    {
      type: "Building Pathway",
      value: "mababni-inspection-form",
      label: "Mababni Inspection Visit",
    },
  ];

  formListNames = [
    { value: "inspection-list", label: "inspection-visit" },
    {
      value: "expired-verfication-license",
      label: "expired-verfication-license-form",
    },
    { value: "940-verification", label: "940-verification-visit" },
    {
      value: "operation_and_maintenance_visit_form",
      label: "operation_and_maintenance_visit",
    },
    { value: "field-observation-form", label: "field-observation-visit" },
    { value: "emergency-list", label: "emergency-visit" },
    {
      value: "close-license-under-warranty",
      label: "close-license-under-warranty-visit",
    },
    { value: "mababni-inspection-visit", label: "mababni-inspection-form" },
  ];

  constructor() {
    super(arguments);
  }

  usersBySuperviser = (text = "") => {
    return SC.getCall({
      url: "getUsersBySuperviser",
      params: {
        search: text,
      },
    });
  };

  assignOperator = (postData) => {
    return SC.postCall({ url: this.route + "/assigned-to", data: postData });
  };
  reAssignOperator = (postData) => {
    return SC.postCall({ url: this.route + "/reassign", data: postData });
  };

  startDate = (id) => {
    return SC.getCall({ url: this.route + `/start/${id}` });
  };

  getSchedule = (id) => {
    return SC.getCall({ url: this.route + `/show/${id}` });
  };
  reAssignInspector = (id, pastData) => {
    return SC.postCall({ url: this.route + `/reassign/${id}`, data: pastData });
  };
  aprovedTask = (id) => {
    return SC.getCall({ url: this.route + `/approved/${id}` });
  };
  rejectTask = (id, postData) => {
    return SC.postCall({ url: this.route + `/rejected/${id}`, data: postData });
  };

  async downloadPdf(id) {
    return new Promise((resolve, reject) => {
      SC.getCall({
        url: this.route + `/rcPdf/${id}`,
        params: {},
        responseType: "blob",
      }).then((res) => {
        try {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(res.data);
          link.download = "Completed Request";

          // console.log(link.href)

          resolve(link);

          // link.click();
        } catch (error) {
          // toast.error(IntlService.m("There is a problem with your network connection!"));
        }
      });
    });
  }

  uploadAttachment(data) {
    return new Promise((resolve, reject) => {
      SC.postCall({
        url: this.route + `/attachment`,
        data,
      }).then((res) => {
        try {
          resolve(res.data.data);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  deleteAttachment(id) {
    return new Promise((resolve, reject) => {
      SC.deleteCall({
        url: this.route + `/attachment/${id}`,
      }).then((res) => {
        resolve(true);
        // try {
        //   resolve(res.data.data);
        // } catch (error) {
        //   reject(error)
        // }
      });
    });
  }
  getFromate = (value) => {
    let formateValue = this.formListNames.find((item) => item.label === value);
    return formateValue?.value;
  };

  getNameBySlug = (slug) => {
    return this.formNames?.find((form) => form.value === slug);
  };
}

const ScheduleTaskService = new Service();

export default ScheduleTaskService;
