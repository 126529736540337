import Language from "./Language";
import { useNavigate } from "react-router-dom";

import { SC } from "../../../../views/wasfaty/Api/serverCall";
import UserDropdown from "../../../components/Profile/ProfileDropDown";
// import ExpertCallDropDown from "../../../components/ExperCall/ExpertCallDropDown";
import QuickAddDropdown from "./QuickAddDropdown";
import { Plus } from "react-feather";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
  Media,
} from "reactstrap";
import { Bell } from "react-feather";
import AuthUser from "views/wasfaty/services/AuthService";
import PerfectScrollbar from "perfect-scrollbar";
import { useEffect } from "react";
import NotificationPusherService from "views/wasfaty/services/NotificationPusherService";
import NotificatioModal from "views/wasfaty/Models/NotificatioModal";
import { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { startCase } from "lodash";

import passed from "../../../../assets/wasfaty/statusicon/passed.svg";
import reopen from "../../../../assets/wasfaty/statusicon/reopen.svg";
import reject from "../../../../assets/wasfaty/statusicon/reject.svg";
import underprcess from "../../../../assets/wasfaty/statusicon/underprocess.svg";
import underreview from "../../../../assets/wasfaty/statusicon/underreview.svg";
import { userTopIcon } from "assets/wasfaty/SVG";
import revert_back from "../../../../assets/wasfaty/statusicon/revert_back.svg";
import { IntlService } from "views/wasfaty/services";

const NavbarUser = observer(({ windowWidth }) => {
  // const [Noti, setNoti] = useState(() => new NotificatioModal());
  // const NotificationPusher = new NotificationPusherService(AuthUser.user._id);
  const navigate = useNavigate();
  const logOut = (e) => {
    e.preventDefault();
    navigate("/login");

    SC.getCall({ url: "logout" }).then((response) => {});
    localStorage.clear();
  };

  //listining notifications

  // useEffect(() => {
  //   if (AuthUser.user) {
  //     const listner = NotificationPusherService.subscribe(
  //       AuthUser.user._id,
  //       (res) => {
  //         Noti.setDatafromSocket(res);
  //       }
  //     );

  //     Noti.getAllNotifications();
  //     return () => {
  //       listner.unsubscribe();
  //     };
  //   }
  // }, []);

  const notiIcons = {
    under_processing: underprcess,
    re_open: reopen,
    passed: passed,
    under_reviewed: underreview,
    rejected: reject,
    revert_back: revert_back,
  };

  function calculateTimeDifference(inputMoment) {
    const currentMoment = moment();
    const duration = moment.duration(currentMoment.diff(inputMoment));

    let years = duration.asYears().toFixed();
    let days = duration.asDays().toFixed();
    let hours = duration.asHours().toFixed();
    let min = duration.asMinutes().toFixed();
    let seconds = duration.asSeconds().toFixed();

    if (years > 0) return `${years} Yr`;
    if (days > 0) return `${days} Day`;
    if (hours > 0) return `${hours} Hr`;
    if (min > 0) return `${min} Min`;
    if (seconds > 0) return `${seconds} Sec`;
  }

  return (
    <>
      <ul
        className="nav  d-flex align-items-center ms-auto flex-nowrap"
        style={{ marginTop: -5 }}
      >
        <li className="user-info">
          {/* <Language /> */}
          <figure>{userTopIcon}</figure>
          <div>
            {!!AuthUser.user?.department?.name && (
              <p className="hed">{AuthUser.user?.department?.name}</p>
            )}
            <p className="hed">
              {IntlService.m(startCase(AuthUser?.user?.type))}
            </p>
          </div>
        </li>

        <li className="mx-1 ">
          <Language />
        </li>
        {/* <li style={{ marginRight: 30 }}>
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
          >
            <DropdownToggle
              tag="a"
              className="nav-link nav-link-label"
              onClick={() => Noti.readAllNotification()}
            >
              <Bell size={21} />
              {!!Noti.count && (
                <Badge pill color="danger" className="badge-up">
                  {Noti.count}
                </Badge>
              )}
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
              <div>
                <div className="noti-header mt-0">
                  <p className="notification-title">Notifications</p>
                  <p className="clear" onClick={() => Noti.deleteAll()}>
                    Clear
                  </p>
                </div>
                <div className="" style={{ height: 250, overflow: "auto" }}>
                  {Noti.allNotifications.map((not) => (
                    <div
                      className="noti-container"
                      onClick={() => {
                        navigate(`/Requests/Details/${not.request_id}`);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img src={notiIcons[not?.status]} />
                        <div className="hed">
                          <p className="status">{startCase(not?.status)}</p>
                          <p className="message">{not.message}</p>
                        </div>
                      </div>
                      <p className="time">
                        {calculateTimeDifference(moment(not.created_at))}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li> */}
        <li>
          <UserDropdown logOut={logOut} />
        </li>
        {/* {AuthUser.isDPOperator && (
          <>
            <li className="line-seprator"></li>
            <li className="mx-1">
              <Button.Ripple
                outline
                onClick={() => {
                  navigate("/New-Request");
                }}
                className={classNames("new-request-button", {
                  "add-form-button-small m-1": windowWidth <= 1200,
                })}
              >
                {windowWidth > 1200 && (
                  <>
                    <Plus size={18} />
                    <p className="">
                      <FormattedMessage
                        defaultMessage={"New Request"}
                        id={"New Request"}
                      />
                    </p>
                  </>
                )}
                {windowWidth < 1200 && <Plus size={18} />}
              </Button.Ripple>
            </li>
          </>
        )} */}
      </ul>
    </>
  );
});
export default NavbarUser;
