import React from "react";
import { observer } from "mobx-react";
import PaginationComponent from "./Pagination";

import "./style.scss";

/**
 * @param {Object} props
 * @param {Object} props.pagination
 * @param {Function} props.handlePageChange
 * @param {Boolean} props.isModalPagination
 * @param {Boolean} props.isStopPaginationFirstCall
 *
 */

function AppPagination(props) {
  return <PaginationComponent {...props} />;
}

AppPagination.defaultProps = {
  isModalPagination: false,
  isStopPaginationFirstCall: false,
};

export default observer(AppPagination);
