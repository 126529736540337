import { autorun, makeAutoObservable } from "mobx";
import { getValidPhone, scrollToRequired } from "../Helper/utils";
import { IntlService } from "../services";
import toast from "react-hot-toast";
import ScheduleTaskService from "../services/SchduleTaskService";
import { debounce } from "lodash";

import { v4 as uuidv4 } from "uuid";
import { SC } from "../Api/serverCall";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export class ScheduleTaskModal {
  class_name = "SchduleTaskModel";
  isValidation = false;
  isSiteFetching = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isAllFilesUploaded = false;

  // Server fields require for payload
  _id = "";
  user = "";
  main_classification = null;
  sub_classification = null;
  form_name = null;
  due_date = new Date();

  inspector_id = "";
  status = "";
  updated_at = "";
  created_at = "";
  started_date = "";
  submitted_date = "";
  task_id = "";
  tasks = [];

  taskLoading = false;
  completed_date = "";
  is_pending = "";
  is_initiated = "";
  is_cancelled = "";
  is_submitted = "";
  is_approved = "";
  is_rejected = "";
  is_resubmitted = "";
  inspector = "";
  task_histroy = [];

  licenses = [];

  retrieve_data = false;

  muncipilityOptions = [];
  municipality = null;

  sub_municipality = null;
  district = null;
  subMuncipilityOptions = [];
  districtOptions = [];

  completed = null;
  pending = null;
  total = null;

  maxLiscence = null;

  track_type = null;

  idSearch_Loading = false;
  totalLicenseCount = "";
  constructor(props, show) {
    this.orignalItem = props;
    this.isEdit = !!props;

    if (props) {
      this.init(props);
    }
    //  else {
    //   this.licenses.push(new LiscenceModal());
    // }

    makeAutoObservable(this);
  }

  trackTypeOptions = [
    { label: "Excavation Pathway", value: "Excavation Pathway" },
    { label: "Building Pathway", value: "Building Pathway" },
    // {
    //   label: "Operation and Maintenance Pathway",
    //   value: "Operation and Maintenance Pathway",
    // },
    { label: "No Track (Pathway)", value: "No Track (Pathway)" },
  ];

  get formNames() {
    return [
      {
        type: "Excavation Pathway",
        value: "inspection-visit",
        label: "Inspection Visit",
      },
      // {
      //   type: "Excavation Pathway",
      //   value: "expired-verfication-license-form",
      //   label: "Expired Verfication License",
      // },
      {
        type: "Excavation Pathway",
        value: "close-license-under-warranty-visit",
        label: "Close License Under Waranty",
      },
      {
        type: "Excavation Pathway",
        value: "emergency-visit",
        label: "Emergency Visit Form",
      },
      {
        type: "No Track (Pathway)",
        value: "940-verification-visit",
        label: "940 Verification Form",
      },
      // {
      //   type: "Operation and Maintenance Pathway",
      //   value: "operation_and_maintenance_visit",
      //   label: "Operation And Maintenance",
      // },
      // {
      //   type: "Building Pathway",
      //   value: "field-observation-visit",
      //   label: "Field Observation Form",
      // },

      {
        type: "Building Pathway",
        value: "mababni-inspection-form",
        label: "Mababni Inspection Visit",
      },
    ].filter((form) => form.type == this.track_type?.value);
  }

  addLiscence() {
    this.licenses.push(new LiscenceModal());
  }

  // removeLiscence(id) {
  //   if (this.licenses.length > 1) {
  //     this.licenses = this.licenses.filter((it) => it.uuid != id);
  //   }
  // }

  getTasksForSchedule = () => {
    this.taskLoading = true;
    SC.getCall({ url: `schedule-task/history/${this._id}` }).then((res) => {
      this.tasks = res.data.data;
      this.taskLoading = false;
    });
  };

  removeLiscence(id) {
    if (this.licenses.length > 1) {
      MySwal.fire({
        title: "Are you sure you want to delete this license from the list?",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: IntlService.m("Okay"),
        cancelButtonText: IntlService.m("Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.licenses = this.licenses.filter((it) => it._id != id);
        }
      });
    } else {
      toast.error("Can not remove now!");
    }
  }

  init(props) {
    if (props) {
      this.setPayload(props);
    }
  }

  getLiscence = (limit, queryParam) => {
    if (limit || queryParam) {
      this.licenses = [];
      this.idSearch_Loading = true;

      SC.getCall({
        url: "schedule-license-detail",
        params: {
          limit,
          trackType: this.track_type?.label,
          municipalityName: this.municipality?.label,
          sub_municipality: this.sub_municipality?.label,
          district: this.district?.label,
          retrieve_data: this.retrieve_data || false,

          ...queryParam,
        },
      }).then((res) => {
        if (res.status == 200) {
          this.licenses = res.data?.data.map((lic, index) => ({
            ...lic,
            sr: index + 1,
          }));
        }
        this.idSearch_Loading = false;
      });
    }
  };

  getLiscenceCount = () => {
    this.idSearch_Loading = true;
    SC.getCall({
      url: "schedule-license-detail",
      params: {
        trackType: this.track_type?.label,
        municipalityName: this.municipality?.label,
        sub_municipality: this.sub_municipality?.label,
        district: this.district?.label,
        count: true,
      },
    }).then((res) => {
      this.totalLicenseCount = res?.data?.count || "";
      this.idSearch_Loading = false;
    });
  };
  getMuncilpilty = (track_type) => {
    SC.getCall({
      url: "muncipility/all",
      params: { trackType: track_type },
    }).then((res) => {
      if (res.status == 200) {
        this.muncipilityOptions = res.data?.data?.map((mun) => {
          return {
            ...mun,
            label: mun.municipality_name,
            value: mun._id,
          };
        });
      }
    });
  };
  getSubMuncilpiltyDistr = (muncipility) => {
    SC.getCall({
      url: "muncipility/district",
      params: {
        trackType: this.track_type?.value,
        municipalityName: muncipility,
      },
    }).then((res) => {
      this.districtOptions = res.data?.data?.district?.map((dis) => {
        return {
          ...dis,
          label: dis.name,
          value: dis._id,
        };
      });
      this.subMuncipilityOptions = res.data?.data?.subMuncipility?.map(
        (submun) => {
          return {
            ...submun,
            label: submun.name,
            value: submun._id,
          };
        }
      );
    });
  };

  setAttribute(name, value) {
    if (name == "main_classification") {
      this.sub_classification = "";
    }

    if (name == "track_type") {
      this.muncipilityOptions = [];
      this.municipality = null;
      this.sub_municipality = null;
      this.district = null;
      this.subMuncipilityOptions = [];
      this.districtOptions = [];
      // this.maxLiscence = null;
      this.licenses = [];

      this.getMuncilpilty(value?.value);
    }

    if (name == "municipality") {
      this.sub_municipality = null;
      this.district = null;
      this.subMuncipilityOptions = [];
      this.districtOptions = [];
      this.licenses = [];

      this.getSubMuncilpiltyDistr(value?.label);
    }

    if (name == "district" || name === "sub_municipality") {
      this.licenses = [];
    }

    // if (name == "maxLiscence") {
    //   this.getLiscence(value);
    // }

    if (name) {
      this[name] = value;
    }
  }

  handleChange = (name, value) => {
    this.setAttribute(name, value);
  };

  setValidation(check) {
    toast.error(IntlService.m("Please fill Required fields!"));
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
    this.auditor_email = user.email;
    this.auditor_phone = getValidPhone(user.phone);
  }

  get dropDownKeys() {
    return [
      "form_name",
      "main_classification",
      "sub_classification",
      "inspector",
    ];
  }

  setDropdown(value) {
    if (value) {
      return { label: value, value };
    }
  }

  isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: value, value };
    }

    return value;
  }

  setPayload(payload) {
    this.orignalItem = payload;
    let { form_name, due_date, task_id } = payload;
    if (payload) {
      Object.entries(payload).forEach(([name, value]) => {
        if (this.hasOwnProperty(name)) {
          this[name] = value;
        }
      });
    }
    this.task_id = payload.task_id;

    this.form_name = ScheduleTaskService.getNameBySlug(form_name);
    this.due_date = new Date(due_date);

    autorun(() => {});
  }

  getPayload() {
    let payload = {};
    payload.form_name = this.form_name?.value;
    payload.due_date = this.due_date?.toISOString?.();
    payload.municipality = this.municipality?.value;
    payload.sub_municipality = this.sub_classification?.value;
    payload.district = this.district?.value;
    payload.inspector_id = this.inspector?._id;
    payload.track_type = this.track_type?.value;
    // payload.retrieve_data = this.retrieve_data;

    payload.licenses = this.licenses.map(
      (item) => item?.reportNo || item?.PERMIT_NO || item?.license_number
    );
    return payload;
  }

  isValid() {
    let check = true;

    check =
      check &&
      !!this.form_name &&
      !!this.track_type?.value &&
      !!this.due_date &&
      !!this.municipality?.value &&
      // !!this.district?.value &&
      this.licenses.length &&
      !!this.inspector;

    scrollToRequired();
    return check;
  }
}

export class LiscenceModal {
  license = null;
  licenseData = null;
  uuid = uuidv4();
  invalidMessageShow = false;
  idSearch_Loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  isValid() {
    return !!this.license && !this.invalidMessageShow;
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  debouncedSearch = debounce((value, track_type) => {
    let reportNo = value;
    this.idSearch_Loading = true;
    this.invalidMessageShow = false;

    SC.getCall({
      url: "licenseInfoSearch",
      params: {
        licenceNo: reportNo,
        trackType: track_type?.value,
      },
    }).then((res) => {
      if (res.status == 200 && res.data?.data) {
        this.licenseData = res.data.data;
      } else {
        this.invalidMessageShow = true;
      }
      this.idSearch_Loading = false;
    });
  }, 1000);
}
