import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import InspectionService from "../../wasfaty/services/InspectionService";
import {
  // CountdownTimer,
  MababniModal,
} from "../../wasfaty/Models/MababniModal";
import { observer } from "mobx-react-lite";
import {
  CloseVerificationService,
  IntlService,
  MababniService,
  MumtahilService,
  Verification940Service,
} from "../../wasfaty/services";
import { fileIconDetails } from "../../../assets/wasfaty/SVG";
import moment from "moment";
import { Label, Row } from "reactstrap";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";
import AssignToModal from "../../wasfaty/Models/AssignToModal";

import get from "lodash/get";
import { useMemo } from "react";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";
import SubmissionReview from "views/components/SubmissionReview";

const MababniShow = ({ itemProp }, props) => {
  const [item, setItem] = useState(itemProp);
  // // const countDown = useMemo(() => new CountdownTimer(), []);
  const data = useMemo(() => new AssignToModal(), []);

  const { id } = useParams();

  const route = useLocation();
  const [errorMess, seterrorMess] = useState("");

  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);
  
  const Service = MababniService;

  const getData = () => {
    setItem(null);
    Service.show(id).then((res) => {
      if (res.status == 200) {
        setItem(new MababniModal(res.data?.data, true));

        // countDown.setPayload(res.data?.data);
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      // countDown.updateElapsedTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );
  }

  const TextView = ({ obj, name, head, md = 3 }) => {
    // if (!obj[name]) return null;
    if (!obj[name] && obj[name] != "") return null;

    return (
      <div className={`main-section_info-container_info col-md-${md}`}>
        <div className="info-head">{IntlService.m(head)}</div>
        <div className="info-content">
          {IntlService.m(obj[name]?.label || obj[name]) || "N/A"}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="show-container d-block ">
        <SubmissionReview />
        <div className="show-page p-1">
          <h2>{IntlService.m("Details")}</h2>{" "}
          <Row>
            <TextView
              head={"License Number"}
              obj={item}
              name={"closed_license_number"}
              md={4}
            />
            <TextView
              head={"Submission By"}
              obj={item.user}
              name={"name"}
              md={4}
            />
            <div className="main-section_info-container_info col-md-4">
              <div className="info-head">{IntlService.m("Date of Visit")}</div>
              <div className="info-content">
                {moment(item.created_at).format("lll")}
              </div>
            </div>
            <TextView
              head={"Is there any visual pollution"}
              obj={item}
              name={"visual_pollution"}
              md={4}
            />
            <TextView
              head={"Quality Check"}
              obj={item}
              name={"quality_check"}
              md={4}
            />
            {/* <TextView
              head={"Reason for not visiting"}
              obj={item}
              name={"reason_for_not_visiting"}
              md={4}
            />{" "} */}
            <TextView
              head={"Is the work has been started?"}
              obj={item}
              name={"check_work_started"}
              md={4}
            />
            {/* <TextView
              head={"Is there container in the site?"}
              obj={item}
              name={"is_there_container"}
              md={4}
            /> */}
            <div className="main-section_info-container_info col-md-4">
              <div className="info-head">
                {IntlService.m("Is there container in the site?")}
              </div>
              <div className="info-content">
                {item.is_there_container?.value}
              </div>

              {item.is_there_container?.value === "Yes" ? (
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.is_there_container_file?.images?.length
                      ? "is_there_container_file.images"
                      : "is_there_container_file",
                    []
                  ).map((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="main-section_info-container_info col-md-4">
              <div className="info-head">
                {IntlService.m("Reason for not visiting")}
              </div>
              <div className="info-content">
                {item?.reason_for_not_visiting?.value}
              </div>
              {item?.reason_for_not_visiting?.value != "Other" ? (
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.reason_for_not_visiting_file?.images?.length
                      ? "reason_for_not_visiting_file.images"
                      : "reason_for_not_visiting_file",
                    []
                  )?.map?.((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </Row>
          <h3 className="mt-1">{IntlService.m("Visual Pollution Pictures")}</h3>
          {!!item?.visual_pollution_file && (
            <div className="info-content ml-1 d-flex flex-column">
              {get(
                item,
                item?.visual_pollution_file?.images?.length
                  ? "visual_pollution_file.images"
                  : "visual_pollution_file",
                []
              )?.map?.((im, index) => (
                <div
                  className="link-div"
                  onClick={() => fetchImageAndOpenInNewTab(im)}
                >
                  {IntlService.m("Picture link")}
                </div>
              ))}
            </div>
          )}
          {!item.is_skip_observation && (
            <div className="main-section">
              <p className="heading mb-1">
                {IntlService.m("Observation Information")}
              </p>
              {item.observationInformations.map((it, index) => (
                <div
                  className="main-section_info-container row card p-2"
                  key={index}
                >
                  <h4 className="info-head">
                    {IntlService.m("Observation Information")}:{index + 1}
                  </h4>
                  <div className="d-flex flex-wrap">
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m(
                          "Enter the field survey inspection number from (Mumtathil) system"
                        )}
                      </div>
                      <div className="info-content">
                        {it.survey_inspection_number}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Main classification")}
                      </div>
                      <div className="info-content">
                        {it.main_classification.name}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Sub classification")}
                      </div>
                      <div className="info-content">
                        {it.sub_classification.name}
                      </div>
                    </div>
                    {it.showReason && (
                      <div className="main-section_info-container_info col-md-4">
                        <div className="info-head">
                          {IntlService.m("What the reason of it?")}
                        </div>
                        <div className="info-content">
                          {it.reason_of_sub_classification.label}
                        </div>
                      </div>
                    )}
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("The estimated quantites")}
                      </div>
                      <div className="info-content">
                        {it.estimated_quantites}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Description")}
                      </div>
                      <div className="info-content">{it.description}</div>
                    </div>
                  </div>
                  <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                  <div className="info-content ml-1 d-flex flex-column">
                    {get(
                      it,
                      it.picture?.images?.length ? "picture.images" : "picture",
                      []
                    ).map((im, index) => (
                      <div
                        className="link-div"
                        onClick={() => fetchImageAndOpenInNewTab(im)}
                      >
                        {IntlService.m("Picture link")}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(MababniShow);
