import { autorun, makeAutoObservable } from "mobx";
import {
  emailValidator,
  getValidPhone,
  scrollToRequired,
} from "../Helper/utils";
import FileUploadModal from "./FileUploadModel";

import DraftModal from "./DraftModel";
import { ModalMixin } from "./Utility";
import AuthUser from "../services/AuthService";

import debounce from "lodash/debounce";
import last from "lodash/last";
import { SC } from "../Api/serverCall";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { IntlService } from "../services";
import toast from "react-hot-toast";

import FileViewer from "react-file-viewer";
const MySwal = withReactContent(Swal);

import { v4 as uuidv4 } from 'uuid';

class ObservationInformationRepeater {
  // Observation Information
  uuid = uuidv4();
  survey_inspection_number = "";
  reason_of_sub_classification = "";
  main_classification = "";
  sub_classification = "";
  estimated_quantites = "";
  description = "";
  picture = new FileUploadModal();

  sub_classificationOptions = [];
  sub_classificationrReasonOptions = [
    { label: "رخص البناء", value: "رخص البناء" },
    { label: "رخص الحفريات مياه جوفية", value: "رخص الحفريات مياه جوفية" },
    { label: "مباني سكنية", value: "مباني سكنية" },
    { label: "مباني تجارية", value: "مباني تجارية" },
    { label: "مباني حكومية", value: "مباني حكومية" },
  ];

  reason_of_sub_classification = "";

  constructor(payload) {
    if (payload) {
      this.setPayload(payload);
    }
    makeAutoObservable(this);
  }

  setPayload(payload) {
    if (payload) {
      Object.entries(payload).forEach(([name, value]) => {
        if (this.hasOwnProperty(name)) {
          if (name == "picture") {
            this.picture = new FileUploadModal(value);
          } else this[name] = value;
        }
      });
    }

    autorun(() => {});
    return payload;
  }

  setAttribute(name, value) {
    if (name == "main_classification") {
      this.sub_classification = "";
    }

    if (name === "survey_inspection_number") {
      // console.log("value",value.length)

      this[name] = value.length <= 6 ? value : this.survey_inspection_number;
    } else if (name) {
      this[name] = value;
    }
  }

  getSubClasification(id) {
    SC.getCall({ url: `sub-classification/${id}` }).then((res) => {
      this.sub_classificationOptions = res.data.data.map((it) => {
        return {
          ...it,
          label: it.name,
          value: it._id,
        };
      });
    });
  }

  getPayload() {
    let payload = {};
    payload.survey_inspection_number = this.survey_inspection_number;
    payload.main_classification = this.main_classification;
    payload.sub_classification = this.sub_classification;
    payload.estimated_quantites = this.estimated_quantites;
    payload.description = this.description;
    payload.picture = this.picture?.images;

    if (this.showReason) {
      payload.reason_of_sub_classification = this.reason_of_sub_classification;
    }
    return payload;
  }

  isValid() {
    let check = true;

    check =
      check &&
      // !!this.survey_inspection_number &&
      !!this.main_classification &&
      !!this.sub_classification &&
      !!this.estimated_quantites &&
      !!this.description;
    if (this.showReason) {
      check = check && !!this.reason_of_sub_classification;
    }
    return check;
  }
  get showReason() {
    return this.sub_classification?.label == "تسرب المياه";
  }
}

export class ClosedUnderLisenceModel {
  class_name = "ClosedUnderLisenceModel";
  isValidation = false;
  isSiteFetching = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isAllFilesUploaded = false;

  // Server fields require for payload
  _id = "";
  user = "";
  image = "";

  // Identify License Information

  license_number = "";
  license_information = null;
  excavation_stage = "";
  visual_pollution_dropdown = "";

  // Operational Information
  consultant_on_site = "";
  consultant_on_site_file = new FileUploadModal();
  contractor_on_site = "";
  contractor_on_site_file = new FileUploadModal();
  same_stage_according = "";
  same_stage_according_file = new FileUploadModal();
  current_stage = "";
  // current_stage_file = new FileUploadModal();
  visual_pollution = "";
  visual_pollution_file = new FileUploadModal();

  // Observation Information
  observationInformations = [];

  user_id = ""; // Current Logged In user

  draft = null;
  // formFields = null;
  test = false;
  orignalItem = null;
  isEdit = false;
  show = false;

  priority = "Low";
  important = false;

  department = null;
  departments = [];

  status = null;

  idSearch_Loading = false;

  selectModel = false;

  security_verfication = null;
  elapsedTimeFormatted = null;

  // form options
  license_informationOptions = [];
  main_classificationOptions = [];
  current_stageOptions = [
    "Excavation Pathway",
    "Building Pathway",
    "Operation and Maintenace Pathway",
  ];
  verification_types = [
    {
      label: "Report 940",
      value: "report_940_number",
    },
    {
      label: "Mumtathil Report",
      value: "mumtathil_report_number",
    },
    {
      label: "Closed License",
      value: "closed_license_number",
    },
  ];
  visual_pollution_dropdown_options = [
    "الارصفة المتهالكة",
    "التشوين",
    "مخلفات الهدم والبناء",
    "الحواجز الخرسانية",
    "مشاريع الخدمات والحفريات",
    "حفر الشوارع والطرق",
    "تسربات المياه",
  ];

  verification_type = {
    label: "Closed License",
    value: "closed_license_number",
  };

  report_940_number = "";
  mumtathil_report_number = "";
  closed_license_number = "";
  reason_for_not_visiting = null;
  quality_check = null;
  constructor(props, show) {
    this.orignalItem = props;
    this.isEdit = !!props;

    if (!this.isEdit) {
      this.draft = new DraftModal(this);
    }
    // ModalMixin.setupFormOptions.call(this, "New-Request");
    // if (!this.isEdit) {
    //   this.draft = new DraftModal(this);
    // }

    this.getMainClasification();

    if (props) {
      this.init(props);
    } else {
      this.observationInformations.push(new ObservationInformationRepeater());
    }
    makeAutoObservable(this);
    if (show) {
      this.show = show;
    }
  }

  get formFields() {
    // if (this.skipFor) {
    //   return {
    //     identify_observation_information: [],
    //   };
    // }

    return {
      identify_license_information: [],
      // operational_information: [],
      observation_information: [],
    };
  }

  //get options
  getMainClasification() {
    SC.getCall({ url: "main-classification" }).then((res) => {
      this.main_classificationOptions = res.data.data.map((it) => {
        return {
          ...it,
          label: it.name,
          value: it._id,
        };
      });
    });
  }

  //observation repeater
  addObservations() {
    this.observationInformations.push(new ObservationInformationRepeater());
  }

  removeObservation(id) {
    if (this.observationInformations.length > 1){
      this.observationInformations = this.observationInformations.filter(
        (it) => it.uuid != id
      );}
  }

  init(props) {
    if (props) {
      this.setPayload(props);
    }
  }

  getFileExtension(urlOrPath) {
    const parts = urlOrPath.split(".");
    return last(parts);
  }

  seeFile(url) {
    let fileUrl = url;
    let extention = this.getFileExtension(fileUrl);

    MySwal.fire({
      html: (
        <div className="file-viewr">
          <FileViewer
            fileType={extention}
            filePath={fileUrl}
            onError={(err) => console.log("fileErr", err)}
          />
        </div>
      ),
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Close",
      focusConfirm: false,
      customClass: {
        cancelButton: "close",
      },
    });
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
      if (name === "verification_type") {
        this.report_940_number = "";
        this.mumtathil_report_number = "";
        this.closed_license_number = "";
        this.license_information = null;
        this.visual_pollution_dropdown = "";
      }

      if (name === "visual_pollution") {
        this.visual_pollution_dropdown = "";
      }
      if (
        [
          "closed_license_number",
          "report_940_number",
          "mumtathil_report_number",
        ].includes(name)
      ) {
        this.idSearch_Loading = true;
        this.license_information = null;
        this.searchForRequest();
      }
    }
  }

  searchForRequest = debounce(() => {
    let reportNo = this.report_940_number;
    let inspectionNumber = this.mumtathil_report_number;
    let PERMIT_NO = this.closed_license_number;

    SC.postCall({
      url: "report940LicencedMumtathilReport",
      data: {
        reportNo,
        inspectionNumber,
        PERMIT_NO,
      },
    }).then((res) => {
      if (res.status == 200 && res.data?.data) {
        this.license_information = res.data.data;
      }
      this.idSearch_Loading = false;
    });
  }, 1000);

  setValidation(check) {
    toast.error(IntlService.m("PLease fill Required fields!"));
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    if (this.checkValid()) {
      this.isReview = check;
      this.isValidation = false;
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    } // if (this.stepper?._currentIndex == 1) {
    //   if (this.isOperationalInformationValid()) {
    //     this.isReview = check;
    //   } else {
    //     this.isValidation = true;
    //     toast.error(IntlService.m("Please fill all the required fields!"));
    //   }
    // } else {
    //   if (this.isObservationInformationValid()) {
    //     this.isReview = check;
    //   } else {
    //     this.isValidation = true;
    //     toast.error(IntlService.m("Please fill all the required fields!"));
    //   }
    // }
  }

  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
    this.auditor_email = user.email;
    this.auditor_phone = getValidPhone(user.phone);
  }

  get dropDownKeys() {
    return [
      "current_stage",
      "excavation_stage",
      "quality_check",
      "visual_pollution_dropdown",
      "main_classification",
      "Sub_classification",
    ];
  }

  getCurrentStep() {
    return this.stepper?._steps[
      this.stepper?._currentIndex
    ]?.dataset?.target?.replace("#", "");
  }

  setDropdown(value) {
    if (value) {
      return { label: value, value };
    }
  }

  isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: value, value };
    }

    return value;
  }

  setDocument(key, value) {
    this.document_steps[key] = value;
  }
  setPayload(payload) {
    if (payload) {
      Object.entries(payload).forEach(([name, value]) => {
        if (this.hasOwnProperty(name)) {
          if (name == "observationInformations") {
            this.observationInformations = value.map(
              (it) => new ObservationInformationRepeater(it)
            );
          } else if (name == "visual_pollution_file") {
            this.visual_pollution_file.setImages(value);
          } else this[name] = value;
        }
      });
    }
    this.license_information =
      payload.license_information || payload.report_940 || payload.field_survey;

    if (payload.visual_pollution_dropdown) {
      this.visual_pollution_dropdown = {
        label: payload.visual_pollution_dropdown,
        value: payload.visual_pollution_dropdown,
      };
    }

    autorun(() => {});
    return payload;
  }

  getPayload() {
    let payload = {};

    payload.report_940_number = this.report_940_number;
    payload.mumtathil_report_number = this.mumtathil_report_number;
    payload.closed_license_number = this.closed_license_number;
    payload.visual_pollution_dropdown = this.visual_pollution_dropdown?.value;
    payload.verification_type = this.verification_type;
    payload.excavation_stage = this.excavation_stage;
    payload.consultant_on_site = this.consultant_on_site;
    payload.reason_for_not_visiting = this.reason_for_not_visiting;
    payload.quality_check = this.quality_check;
    payload.contractor_on_site = this.contractor_on_site;
    payload.same_stage_according = this.same_stage_according;
    payload.current_stage = this.current_stage;
    payload.consultant_on_site_file = this.consultant_on_site_file?.images;
    payload.contractor_on_site_file = this.contractor_on_site_file?.images;
    payload.same_stage_according_file = this.same_stage_according_file?.images;
    payload.visual_pollution_file = this.visual_pollution_file?.images;
    payload.visual_pollution = this.visual_pollution;

    payload.license_information = this.license_information;

    payload.observationInformations = this.observationInformations.map((item) =>
      item.getPayload()
    );

    return payload;
  }

  isValid = async () => {
    let check = true;

    // check =
    //   check &&
    //   !!this.excavation_stage &&
    //   !!this.consultant_on_site &&
    //   !!this.contractor_on_site &&
    //   !!this.same_stage_according &&
    //   !!this.current_stage &&
    //   !!this.visual_pollution;

    // if (!check) {
    //   this.isReview = false;
    //   this.onStepPress(0);
    // }
    return true;
  };

  isObservationInformationValid() {
    let check = true;

    this.observationInformations.map((it) => {
      check = check && it.isValid();
    });

    return check || this.is_skip_observation;
  }

  isOperationalInformationValid() {
    let check = true;
    check =
      check &&
      !!this.consultant_on_site &&
      this.consultant_on_site_file.images.length &&
      !!this.contractor_on_site &&
      this.contractor_on_site_file.images.length &&
      !!this.same_stage_according &&
      this.same_stage_according_file.images.length &&
      (!!this.current_stage || !this.is_evacuation_same) &&
      !!this.visual_pollution;

    scrollToRequired();

    return check;
  }
  isIdentifyLicenseInformationValid() {
    let check = true;
    check =
      check &&
      !!this.closed_license_number &&
      !!this.excavation_stage &&
      !!this.license_information;

    scrollToRequired();

    return check;
  }

  checkValid() {
    let check = this.verification_type;

    const stepName = this.getCurrentStep();
    if (stepName == "identify_license_information") {
      if (this.verification_type?.value === "closed_license_number") {
        return (
          check &&
          !!this.closed_license_number &&
          !!this.excavation_stage &&
          !!this.license_information &&
          this.visual_pollution
        );
      }
      if (this.visual_pollution == "Yes") {
        check = check && !!this.visual_pollution_dropdown?.value;
      }
      return (
        check &&
        this.license_information &&
        this.excavation_stage &&
        this.visual_pollution &&
        this.visual_pollution_file.images.length
      );
    }
    // if (stepName == "operational_information") {
    //   return this.isOperationalInformationValid();
    // }

    if (stepName == "observation_information") {
      return check && this.isObservationInformationValid();
    }

    scrollToRequired();
    return check;
  }

  //validation free all steps except
  nextStep() {
    // this.stepper.next();
    if (this.checkValid()) {
      this.stepper.next();
      this.isValidation = false;
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
    this.test = !this.test;
  }

  previousStep() {
    this.stepper.previous();
    this.test = !this.test;
  }
  onStepPress = ({ stepNumber }) => {
    if (this.checkValid()) {
      this.stepper.to(stepNumber);
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
  };

  get isFirstStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === 0;
  }

  get isLastStep() {
    return (
      this.isFinalStep ||
      (this.is_skip_observation && this.stepper?._currentIndex == 1) ||
      this.skipFor
    );
  }

  get isFinalStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === this.stepper?._steps?.length - 1;
  }

  get assignTo() {
    //false on submited
    return AuthUser.isSuperCurentDepartment(this.department.slug);
  }

  get tabs() {
    if (AuthUser.isOperator && !AuthUser.isDPOperator) {
      return [
        "time-line",
        "history",
        "document-check",
        "attachments",
        "comments",
        "e-signature",
      ];
    }

    return ["time-line", "history", "comments"];
  }
  // get elapsedTimeFormatted() {
  //   const now = moment(new Date());
  //   const elapsedTimeInSeconds = now.diff(moment(this.created_at), "seconds");
  //   const duration = moment.duration(elapsedTimeInSeconds, "seconds");
  //   return duration;
  // }

  get isMovingBack() {
    return this.department.slug === "DSCE" || this.is_moving_backward;
  }
  get labelForSubmit() {
    if (this.isMovingBack) {
      if (this.department.slug === "PSCO") return "Review";
      return "Approve";
    }

    return "Submit";
  }

  get is_evacuation_same() {
    return this.same_stage_according == "No" && this.same_stage_according != "";
  }

  get skipFor() {
    if (this.is_skip_observation) {
      return true;
    }

    return ["report_940_number", "mumtathil_report_number"].includes(
      this.verification_type?.value
    );
  }
  get is_skip_observation() {
    return this.visual_pollution == "No" ? true : false;
  }

  //show page
}
