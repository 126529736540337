import { useState } from "react";
import FlatPicker from "react-flatpickr";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  CardHeader,
} from "reactstrap";
import "./style.scss";
import {
  dateIcon,
  staffIcon,
  candidatesIcon,
  traineesIcon,
  secondmentIcon,
  companyIcon,
  permitIcon,
  arrowUpIcon,
} from "../../../assets/wasfaty/SVG";
import { FacilityModel } from "../../wasfaty/Models/FacilityModel";
import LineRequest from "./lineRequest";

import Performance from "./performance";
import RequestStatus from "./requestStatus";
import ListingTableHeader from "views/wasfaty/components/ListingTableHeader";
import AppModal from "views/wasfaty/components/AppModal";
import RenderModal from "./RenderModal";
import { SC } from "views/wasfaty/Api/serverCall";
import { useEffect } from "react";

import get from "lodash/get";
export let dashboardData = [
  {
    title: "Staff",
    count: "6730",
    icon: staffIcon,
  },
  {
    title: "Employment candidates",
    count: "6730",
    icon: candidatesIcon,
  },
  {
    title: "Trainees",
    count: "6730",
    icon: traineesIcon,
  },
  {
    title: "Secondment",
    count: "6730",
    icon: secondmentIcon,
  },
  {
    title: "3rd party companies",
    count: "6730",
    icon: companyIcon,
    isClickable: true,
    compName: "thirdPartyCompanies",
  },
  {
    title: "Permits",
    count: "6730",
    icon: permitIcon,
    isClickable: true,
    compName: "departments",
  },
];

export let dashboardRequestData = [
  {
    title: "Total Number",
    count: "6731",
    key: "totalCount",
    icon: arrowUpIcon,
    pecentage: 0,
  },
  {
    title: "Under Processing",
    key: "under_processing",
    count: "6732",
    icon: arrowUpIcon,
    pecentage: 0,
  },
  {
    key: "passed",
    title: "Complete",
    count: "6733",
    icon: arrowUpIcon,
    pecentage: 0,
  },
  {
    key: "cancelled",
    title: "Cancelled",
    count: "6734",
    icon: arrowUpIcon,
    pecentage: 0,
  },
];

const facilityModel = new FacilityModel();

function Dashboard() {
  const [item, setItem] = useState({});
  const [dateRange1, setDateRange1] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [totalRequest, setTotalRequest] = useState("7,728");

  const [date, setDate] = useState(new Date());

  const getdata = () => {
    setLoading(true);
    SC.postCall({ url: "dashboard" }).then((res) => {
      if (res.status == 200) {
        // console.log(res.data.data);
        setItem(res.data.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <ListingTableHeader className="dashboard-filters" />
      <Row id="graph-dashboard">
        <Col md="12">
          <Row>
            <RequestWidget
              count={item?.totalCount || 0}
              isLoading={isLoading}
              data={item?.total_request_graph_data}
            />
            <Widget data={dashboardData} isLoading={isLoading} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Card>
            <CardBody>
              <CardHeader>
                <CardTitle className="performance">
                  <span className="heading">Performance Indicator</span>
                  <p className="subheading">
                    No. of requests closed by the employees
                  </p>
                </CardTitle>
                {/* <div className="customWidth">
                  <div className="faltPicker">
                    {dateIcon}
                    <FlatPicker
                      className="customCalander w-100"
                      options={{ mode: "range", dateFormat: "M d, Y" }}
                      // onChange={() => setDate(date)}
                      placeholder="Click to select a date"
                    />
                  </div>
                </div> */}
              </CardHeader>

              <Performance />
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle>Requests Status</CardTitle>
              {/* <div className="customWidth">
                <div className="faltPicker">
                  {dateIcon}
                  <FlatPicker
                    className="customCalander w-100"
                    options={{ mode: "range", dateFormat: "M d, Y" }}
                    // onChange={() => setDate(date)}
                    placeholder="Click to select a date"
                  />
                </div>
              </div> */}
            </CardHeader>
            <CardBody>
              <Row>
                {dashboardRequestData.map((ite, key) => (
                  <Col md="3" className="requestStatus p-1">
                    <div className=" d-flex justify-content-between align-items-center">
                      <span className="heading">
                        {isLoading ? <Spinner /> : get(item, ite.key, 0)}
                      </span>
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <span className="icon">{ite.icon}</span>
                        <span className="percentage">{ite.pecentage}%</span>
                      </div>
                    </div>
                    <span className="title">{ite.title}</span>
                  </Col>
                ))}
              </Row>
              <RequestStatus data={item.status_bar_data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;

const RequestWidget = ({ count, isLoading, data }) => {
  return (
    <Col md="3" lg="4">
      <Card className="rcc-request-dashboard">
        <CardBody>
          {/* <div className="" /> */}
          <div className="mt-5 d-flex align-items-center flex-column">
            <h3 className="requestTitle">No of Total Requests</h3>
            <h1 className="requestCount">{isLoading ? <Spinner /> : count}</h1>
            <p className="requestDate">21 May 2022 - 31 May 2022</p>
          </div>
          <div>
            <LineRequest data={data} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const Widget = ({ data, isLoading }) => {
  const [modal, setModal] = useState({ show: false, compName: "" });
  const toggleModal = (e) => setModal({ show: !modal.show, compName: e || "" });
  // console.log(modal);
  return (
    <Col md="9" lg="8">
      <Card style={{ height: "418px" }} className="dashboard-widgets">
        <CardBody>
          <Row className="mt-1">
            {dashboardData.map((item, index) => {
              let widgetProps = {};
              if (item.isClickable) {
                widgetProps = {
                  ...widgetProps,
                  onClick: () => toggleModal(item.compName),
                };
              }
              return (
                <Col
                  key={index}
                  lg="3"
                  className={`${
                    item?.isClickable ? "clickable" : ""
                  } mb-5 d-flex justify-content-center align-items-center flex-column totalrecord`}
                  {...widgetProps}
                >
                  <p>{item.icon}</p>
                  <h1 className="heading">
                    {isLoading ? <Spinner /> : item.count}
                  </h1>
                  <p className="title">{item.title}</p>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
      <AppModal
        open={modal.show}
        toggle={toggleModal}
        Content={<RenderModal compName={modal.compName} />}
      />
    </Col>
  );
};
