import React, { createRef } from "react";
import { makeAutoObservable, autorun, toJS, reaction, observe } from "mobx";
import { phoneValidator, emailValidator, getValidPhone } from "../Helper/utils";
import { get } from "lodash";
import toast from "react-hot-toast";

class FileUploadModal {
  images = [];
  imagesWithLocation = [];
  fileRef = null;
  isAllFilesUploaded = false;

  locationInstance = false;
  imageLocations = {};
  imageTime = null;

  constructor(payload, locationInstance) {
    if (locationInstance) {
      this.locationInstance = true;
    }

    this.fileRef = React.createRef();
    if (payload?.length) {
      this.setImages(payload);
      this.isAllFilesUploaded = true;
    }
    makeAutoObservable(this);
  }

  setimageLocations = (imageLocations) => {
    this.imageLocations = imageLocations;
  };

  setImages = async (images = [], time) => {
    if (!this.imageTime && images?.length) {
      this.imageTime = new Date();
    }
    if (time) {
      this.imageTime = time;
    }

    this.images = images;
    this.fileRef?.current?.setImages?.(images);
    try {
      if (this.locationInstance) {
        let latitude = null;
        let longitude = null;
        let location = null;
        if (window.navigator.geolocation) {
          try {
            location = await new Promise((res, rej) => {
              window.navigator.geolocation.getCurrentPosition(
                (position) => {
                  latitude = position.coords.latitude;
                  longitude = position.coords.longitude;
                  res({
                    latitude,
                    longitude,
                  });
                  console.log(latitude, longitude);
                },
                (error) => {
                  console.error("Error getting geolocation:", error.message);
                }
              );
            });
          } catch (error) {
            toast.error(error);
          }
        } else {
          console.error("Geolocation is not supported by your browser");
        }
        let newLocations = {};
        images.forEach((url) => {
          if (this.imageLocations.hasOwnProperty(url)) {
            newLocations[url] = this.imageLocations[url];
          } else {
            newLocations[url] = location;
          }
        });
        this.imageLocations = newLocations;
        // console.log("imageLocations", this.imageLocations);
      }
    } catch (error) {
      console.log("Location Image", error);
    }
  };

  setImageTime(time) {
    this.imageTime = time;
  }

  setIsUploading = (check) => {
    this.isAllFilesUploaded = check;
  };

  onUploadingComplete = () => {
    this.setIsUploading(this.fileRef.current?.checkIsAllFilesUploaded?.());
  };

  get isImages() {
    return !!this.images?.length;
  }

  get first() {
    return get(this.images, "0");
  }
}

export default FileUploadModal;
