import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FieldObservationService } from "../../wasfaty/services";
import {
  // CountdownTimer,
  FieldObservationModal,
} from "../../wasfaty/Models/FieldObservationModal";
import { observer } from "mobx-react-lite";
import { IntlService } from "../../wasfaty/services";
import { fileIconDetails } from "../../../assets/wasfaty/SVG";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";
import AssignToModal from "../../wasfaty/Models/AssignToModal";

import get from "lodash/get";
import { useMemo } from "react";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";
import moment from "moment";
import MapContainer from "views/components/MapComponent/Map";
import SubmissionReview from "views/components/SubmissionReview";

/**
 * @param {Object} props
 * @param {FieldObservationModal} props.itemProp
 */

const FieldObservation = ({ itemProp }) => {
  const [item, setItem] = useState(itemProp);
  // // const countDown = useMemo(() => new CountdownTimer(), []);
  const data = useMemo(() => new AssignToModal(), []);

  const { id, task_id } = useParams();
  const [errorMess, seterrorMess] = useState("");
  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);

  const getData = () => {
    setItem(null);
    FieldObservationService.show(id).then((res) => {
      if (res.status == 200) {
        setItem(res.data?.data);
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      // countDown.updateElapsedTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );

  const FilesViewer = observer(({ title, attribute }) => {
    // console.log("item[attribute]", item[attribute]);
    return (
      <div className="main-section">
        <p className="heading">{IntlService.m(title)}</p>
        <div className="main-section_info-container row">
          <div className="main-section_info-container_file ">
            {item[attribute].images.map((url) => {
              const fileName =
                url.length > 12 ? url.substr(url.length - 12) : url;
              return (
                <div className="name" onClick={() => item.seeFile(url)}>
                  <figure>{fileIconDetails}</figure>
                  <p className="title">
                    {fileName}
                    {/* <br />
          10 kb */}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  });

  // const tabs = ["time-line", "comments", "document-check", "e-signature"];

  // return null;

  if (!item?.checkInImageLocation) {
    return (
      <>
        <div className="show-container d-block">
        <SubmissionReview  />
          <div className={`show-page p-1`}>
            <h2>{IntlService.m("Details")}</h2>

            <div className="main-section">
              <p className="heading mb-1">
                {IntlService.m("Observation Information")}
              </p>
              {item.observationInformations.map((it, index) => (
                <div
                  className="main-section_info-container row card p-2"
                  key={index}
                >
                  <h4 className="info-head">
                    {IntlService.m("Observation Information")}:{index + 1}
                  </h4>
                  <div className="d-flex flex-wrap">
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m(
                          "Enter the field survey inspection number from (Mumtathil) system"
                        )}
                      </div>
                      <div className="info-content">
                        {it.survey_inspection_number}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Main classification")}
                      </div>
                      <div className="info-content">
                        {it.main_classification.name}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Sub classification")}
                      </div>
                      <div className="info-content">
                        {it.sub_classification.name}
                      </div>
                    </div>
                    {it.showReason && (
                      <div className="main-section_info-container_info col-md-4">
                        <div className="info-head">
                          {IntlService.m("What the reason of it?")}
                        </div>
                        <div className="info-content">
                          {it.reason_of_sub_classification.label}
                        </div>
                      </div>
                    )}
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("The estimated quantites")}
                      </div>
                      <div className="info-content">
                        {it.estimated_quantites}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Description")}
                      </div>
                      <div className="info-content">{it.description}</div>
                    </div>
                  </div>
                  <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                  <div className="info-content ml-1 d-flex flex-column">
                    {get(
                      it,
                      it.picture?.images?.length ? "picture.images" : "picture",
                      []
                    ).map((im, index) => (
                      <div
                        className="link-div"
                        onClick={() => fetchImageAndOpenInNewTab(im)}
                      >
                        {IntlService.m("Picture link")}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  //maps
  const checkInLoc = item.checkInImageLocation[item.checkInImage[0]];
  const checkOutLoc = item.checkOutImageLocation[item.checkOutImage[0]];
  const origin = {
    lat: checkInLoc?.latitude || 0,
    lng: checkInLoc?.longitude || 0,
  };

  const destination = {
    lat: checkOutLoc?.latitude || 0,
    lng: checkOutLoc?.longitude || 0,
  };

  return (
    <>
      <div className="show-container d-block">
      <SubmissionReview  />

        <div className={`show-page p-1`}>
          <h2>{IntlService.m("Details")}</h2>

          <div className="main-section">
            <p className="heading mb-1">{IntlService.m("Check In")}</p>
            <div className="main-section_info-container row ">
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Check In time")}
                </div>
                <div className="info-content">
                  {moment(item?.checkinTime).format("lll")}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">{IntlService.m("Geo Location")}</div>
                <div className="info-content">
                  {(Object.values(item.checkOutImageLocation)[0]?.longitude ||
                    0) +
                    ", " +
                    (Object.values(item.checkOutImageLocation)[0]?.latitude ||
                      0)}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Check In Image")}
                </div>
                <div className="info-content">
                  {get(
                    item,
                    item.checkInImage?.images?.length
                      ? "checkInImage.images"
                      : "checkInImage",
                    []
                  )?.map?.((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <p className="heading mb-1">
              {IntlService.m("Observation Information")}
            </p>
            {item.observationInformations.map((it, index) => (
              <div
                className="main-section_info-container row card p-2"
                key={index}
              >
                <h4 className="info-head">
                  {IntlService.m("Observation Information")}:{index + 1}
                </h4>
                <div className="d-flex flex-wrap">
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m(
                        "Enter the field survey inspection number from (Mumtathil) system"
                      )}
                    </div>
                    <div className="info-content">
                      {it.survey_inspection_number}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("Enter the Main classification")}
                    </div>
                    <div className="info-content">
                      {it.main_classification.name}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("Enter the Sub classification")}
                    </div>
                    <div className="info-content">
                      {it.sub_classification.name}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("The estimated quantites")}
                    </div>
                    <div className="info-content">{it.estimated_quantites}</div>
                  </div>
                  {/* <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">{IntlService.m("Street")}</div>
                    <div className="info-content">
                      {it.street?.value || "N/A"}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">{IntlService.m("District")}</div>
                    <div className="info-content">
                      {it.district?.value || "N/A"}
                    </div>
                  </div>{" "} */}
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("what is the priority area")}
                    </div>
                    <div className="info-content">{it.priorityArea?.value}</div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("Description")}
                    </div>
                    <div className="info-content">{it.description}</div>
                  </div>
                </div>
                <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                <div className="info-content ml-1 d-flex flex-column">
                  {get(
                    it,
                    it.picture?.images?.length ? "picture.images" : "picture",
                    []
                  ).map((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="main-section">
            <p className="heading mb-1">{IntlService.m("Check Out")}</p>
            <div className="main-section_info-container row ">
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Check Out time")}
                </div>
                <div className="info-content">
                  {moment(item?.checkoutTime).format("lll")}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">{IntlService.m("Geo Location")}</div>
                <div className="info-content">
                  {(Object.values(item.checkOutImageLocation)[0]?.longitude ||
                    0) +
                    ", " +
                    (Object.values(item.checkOutImageLocation)[0]?.latitude ||
                      0)}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Check Out Image")}
                </div>
                <div className="info-content">
                  {get(
                    item,
                    item.checkOutImage.images?.length
                      ? "checkOutImage.images"
                      : "checkOutImage",
                    []
                  )?.map?.((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <MapContainer origin={origin} destination={destination} />
        </div>
      </div>
    </>
  );
};

export default observer(FieldObservation);
