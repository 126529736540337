import React, { Fragment, useState } from "react";
// ** Third Party Components
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";
import { ArrowLeft, ArrowRight } from "react-feather";
import { AppIcon } from "assets/images/icons/svgIcons";

const PaginationComponent = ({
  pagination,
  handlePageChange,
  isStopPaginationFirstCall,
  isModalPagination,
}) => {
  const [initialPageChange, setInitialPageChange] = useState(true);

  const Previous = () => {
    return (
      <button>
        {isModalPagination ? (
          <AppIcon icon="downToggleIcon" />
        ) : (
          <Fragment>
            <ArrowLeft size={12} className="align-middle" />
            <span className="align-middle d-none d-md-inline-block">
              <FormattedMessage id="Next" defaultMessage="Previous" />
            </span>
          </Fragment>
        )}
      </button>
    );
  };

  const Next = () => {
    return (
      <button>
        {isModalPagination ? (
          <AppIcon icon="downToggleIcon" />
        ) : (
          <Fragment>
            <span className="align-middle d-none d-md-inline-block">
              <FormattedMessage id="Next" defaultMessage="Next" />
            </span>
            <ArrowRight size={12} className="align-middle" />
          </Fragment>
        )}
      </button>
    );
  };

  const _handlePageChange = (selectedPage) => {
    if (initialPageChange && isStopPaginationFirstCall) {
      setInitialPageChange(false);
      return;
    }
    handlePageChange(selectedPage);
  };

  return (
    <ReactPaginate
      initialPage={pagination.page - 1}
      forcePage={pagination.page - 1}
      onPageChange={_handlePageChange}
      pageCount={pagination.pageCount}
      breakLabel="..."
      nextLabel={<Next />}
      previousLabel={<Previous />}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      nextClassName="page-item"
      breakLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      containerClassName={`pagination 
      ${!pagination.totalPages && "hidden"} ${
        isModalPagination && "modal-pagination"
      }`}
      prevRel={null}
      renderOnZeroPageCount={null}
    />
  );
};
export default PaginationComponent;
