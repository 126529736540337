import { Singleton, Resources } from "../Resource";
import moment from "moment";
import startCase from "lodash/startCase";
import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "LicenseInformation";
  allUsers = "allUsers";
  disableDefault = true
  licenseIlicenseInformationnformation = "LicenseInformation";

  constructor() {
    super(arguments);
  }

  getHeaders() {
    return [
      { title: "Department Name", dataIndex: "DEPT_NAME" },
      { title: "Service Type", dataIndex: "SERVICE_TYPE" },
      { title: "Order Date", dataIndex: "ORDER_DATE" },
      {
        title: "The excavation stage according to the project schedule",
        dataIndex: "STAGE",
      },
      { title: "Main Contractor Name", dataIndex: "CONT_NAME" },
      { title: "Sub Contractor Name", dataIndex: "region" },
      { title: "Consultant Name", dataIndex: "CONS_NAME" },
      { title: "Laboratory Name", dataIndex: "LAB_NAME" },
      { title: "Municipality Name", dataIndex: "MUNICIPALITY" },
      { title: "District Name", dataIndex: "DISTRICT" },
      { title: "Street Name", dataIndex: "STREET_NAME" },
      { title: "Longitude", dataIndex: "X" },
      {
        title: "Latitude",
        dataIndex: "Y",
      },
    ];
  }

  getUsers(params) {
    return SC.getCall({
      url: this.allUsers,
      params,
    });
  }

  mapExportData(data) {
    data = data.map((item) => {
      item.modules = item.modules.reduce((r, c) => r + c.name + ",", "");
      item.region = item.region.reduce((r, c) => r + c.name + ",", "");
      let sites = "";
      if (item?.clusters?.length) {
        item.cluster = item.clusters.reduce((r, c) => r + c.name + ",", "");
      }

      if (item?.sites?.length) {
        sites = item.sites.reduce((r, c) => r + c.name + ",", "");
      }

      let otherObj = {};
      if (item?.nupco_sites?.length) {
        sites = item.nupco_sites.reduce(
          (r, c) => r + c.phc_name_eng + ",",
          sites
        );

        otherObj = item.nupco_sites[0];
      }

      item.sites = sites;

      item.created_at = moment(item.created_at.created_at).format(
        "DD-MM-YYYY h:mm A"
      );
      return { ...otherObj, ...item };
    });

    return {
      headers: this.getHeaders(),
      data,
    };
  }

  getLicenseInformation() {
    return SC.getCall({
      url: this.licenseInformation,
      // params,
    });
  }
}

const LicenseService = new Service();
export default LicenseService;
