import {
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import PoweredByLogo from "./assets/MOH_C_AM.png";
import loginLogo from "./assets/Illustrations.svg";
import "./style.scss";
import AlertDismissable from "./assets/Alert";
import eficaXLogo from "../../assets/wasfaty/brandlogo.svg";
import { SC } from "../wasfaty/Api/serverCall";
import OTPInput from "react-otp-input";

import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";

import brandlogo from "./assets/static/img/brandlogo.svg";
import footor from "./assets/static/img/footor.svg";
import otpSvg from "./assets/static/img/otp.svg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { encryptPassword } from "utility/securePass/encryptPassword";

const Login = () => {
  const [validation, setValidation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useState("");
  const [counter, setCounter] = useState(60);
  const counterRef = useRef();
  counterRef.current = counter;
  const [Data, setData] = useState({
    email: "",
    password: "",
    otp: "",
    otpState: false,
    // captcha: false,true
  });
  const navigate = useNavigate();
  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counterRef.current > 0) {
        setCounter((prev) => prev - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const sendOtp = () => {
    setCounter(60);
    SC.postCallWithoutAuth({
      url: "sendOtp",
      data: { ...Data, password: encryptPassword(Data.password) },
    }).then((response) => {
      if (response.status === 200 && response.data) {
        toast.success(response.data.data);
        setData({ ...Data, otpState: true });
        setLoading(false);
        setVisible(false);
      } else {
        setCounter(0);
        toast.error(response?.response?.data?.error);

        setLoading(false);
      }
    });
  };

  const handleSubmit = async (data) => {
    data.preventDefault();

    if (Data.email === "" || Data.password === "") {
      // if (!Data.captcha) {
      //   toast.error("Please fill the captcha!");
      // }

      setValidation(true);
    } else {
      setLoading(true);
      setValidation(false);

      if (Data.otpState) {
        SC.postCallWithoutAuth({
          url: "loginWithOtp",
          data: { ...Data, password: encryptPassword(Data.password) },
        }).then((response) => {
          if (response.status === 200 && response.data) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response?.data?.accessToken)
            );

            localStorage.setItem(
              "refreshToken",
              JSON.stringify(response?.data?.refreshToken)
            );

            SC.getCall({ url: "token" }).then((res) => {
              if (res.status === 200 && res?.data?.data) {
                localStorage.setItem(
                  "userData",
                  JSON.stringify(res.data.data.user[0])
                );
                window.location.reload(false);
              } else {
                console.log(res);
                toast.error(res?.response?.data?.error);
              }
              setLoading(false);
            });
            setVisible(false);
          } else {
            toast.error(response?.response?.data?.error);
            // setMessage(response?.response?.data?.error);
            setLoading(false);
          }
        });
      } else {
        sendOtp();
      }
    }
  };

  return (
    <div className="d-flex">
      <div className="section">
        <div className="container-section">
          <img src={Data.otpState ? otpSvg : loginLogo} className="dashboard" />
          <div className="headline-description">
            <div className="headline">Empowering Trust</div>
            <p className="text-wrapper">Your Tailored Inspection Tool</p>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="header-navigation">
          <img
            className="isolation-mode"
            alt="Isolation mode"
            src={brandlogo}
          />
        </div>
        <div className="container-main">
          <div className="text-and-supporting">
            <div className="text-2">
              {Data.otpState ? "Verification Code" : "Log in"}
            </div>
            <p className="supporting-text">
              {Data.otpState
                ? `Please enter the verification code sent to "${Data.email}"`
                : "Welcome back! Please enter your details"}
            </p>
            <div className="">
              <AlertDismissable
                visible={visible}
                setVisible={setVisible}
                msg={Message?.toString?.()}
                invalid={"Email or Password invalid"}
              />
            </div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-container">
              {!Data.otpState ? (
                <>
                  <div>
                    <Label>Email</Label>
                    <Input
                      className="input-field-instance"
                      type="email"
                      placeholder="Email"
                      onChange={(e) =>
                        handlechange("email", e.target.value.toLowerCase())
                      }
                      invalid={Data.email === "" && validation ? true : false}
                    />
                    <FormFeedback
                      invalid={
                        Data.email === "" && validation ? "true" : "false"
                      }
                    >
                      Please enter email address"
                    </FormFeedback>
                  </div>
                  <div>
                    <Label>Password</Label>
                    <Input
                      className="input-field-instance"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => handlechange("password", e.target.value)}
                      invalid={
                        Data.password === "" && validation ? true : false
                      }
                    />
                    <FormFeedback
                      invalid={
                        Data.password === "" && validation ? "true" : "false"
                      }
                    >
                      Please enter Password"
                    </FormFeedback>
                  </div>
                </>
              ) : (
                <OTPInput
                  renderInput={(props) => <input {...props} />}
                  value={Data.otp}
                  onChange={(e) => handlechange("otp", e)}
                  numInputs={6}
                  inputStyle={{
                    width: "10%",
                    height: "70px",
                    fontSize: 19,
                    fontWeight: 600,
                    borderRadius: "7px",
                    maxWidth: "50px",
                    borderColor: "#D0D5DD",
                    color: "#667085",
                    borderWidth: 1,
                  }}
                  containerStyle="otp-custom"
                />
              )}
              <div className="link-row">
                {!Data.otpState && (
                  <>
                    <div className="checkbox-container">
                      <Input type="checkbox" id="remember-me" className="" />
                      <div className="text-3">Remember me</div>
                    </div>
                    <div>
                      <Link to="/forgot-password" className="button-instance">
                        <p className="m-0">Forgot Password?</p>
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <Button
                type="submit"
                color="primary button-main"
                block
                className="p-1 authButton"
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" className="mx-1" />
                    Signing in
                  </span>
                ) : Data.otpState ? (
                  "Verify Code"
                ) : (
                  "Sign in"
                )}
              </Button>
            </div>
          </Form>{" "}
          {Data.otpState && counter != 0 && (
            <div className="pulse-container">
              <div>
                <div className="progress-container">
                  <div className="react-circular-progressbar"></div>
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      zIndex: 1,
                      position: "absolute",
                      top: 15,
                      left: 15,
                    }}
                  >
                    <CircularProgressbar
                      value={counter}
                      text={`${counter}s`}
                      maxValue={60}
                      background={true}
                      counterClockwise={true}
                      backgroundPadding="11"
                      strokeWidth={6}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        textSize: "25px",
                        trail: {
                          stroke: "#28A671",
                        },
                        pathColor: `#fff`,
                        textColor: "#fff",
                        trailColor: "rgba(255, 255, 255, 0.20)",
                        backgroundColor: "#28A671",
                      })}
                    />
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
          {counter == 0 && Data.otpState && (
            <p style={{ cursor: "pointer", margin: 0 }}>
              No Code Received?{" "}
              <span className="link-code" onClick={sendOtp}>
                Resend Code
              </span>
            </p>
          )}
        </div>

        <footer className="footer">
          <a target="_blank" href="https://ascend.com.sa/">
            <img className="group" alt="Group" src={footor} />
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Login;
