import { useState, useEffect, useRef, useMemo, useContext } from "react";
import FileUploader from "../../../wasfaty/components/Add/FileUploader";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { MababniModal } from "../../../wasfaty/Models/MababniModal";
import ClosedInformation from "../../Show/ClosedInformation";
import { MababniService, IntlService } from "../../../wasfaty/services";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { observer } from "mobx-react";
import "./style.scss";

import { getUserData } from "@utils";
import Wizard from "@components/wizard";
import startCase from "lodash/startCase";
import { IntlContext } from "../../../../utility/context/IntelContext";
import AutoSave from "../components/AutoSave";
import { submitPageIcon } from "../../../../assets/wasfaty/SVG";
import { useParamsCutom } from "utility/hooks/useParamsCutom";

export const YesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

import moment from "moment";
import MababniShow from "views/pages/Show/MababniShow";

const MababniInspection = observer(
  ({ item, onClearModal, medicines, sitesModal, ...props }) => {
    const { task_id, license } = useParamsCutom();

    const intlContext = useContext(IntlContext);
    const isEn = intlContext.locale === "en";
    const navigate = useNavigate();
    const fileRef = useRef();
    const currentUrl = window.location.href;
    const match = currentUrl.match(/\/([^\/?]+)\?schedule_id=([^&]+)/);
    const scheduleId = match?.pop?.();
    const [data, setData] = useState();

    const handleChange = (name, value) => {
      item.setAttribute(name, value);
    };

    useEffect(() => {
      if (license && item && item?.closed_license_number != license)
        handleChange("closed_license_number", license.trim());
    }, [license, item, item?.closed_license_number]);

    const submit = async (e) => {
      e.preventDefault();
      item.setIsSubmit(true);

      // if (taskId) {
      //   TaskService.submitTask(item);
      //   return;
      // }

      let isValid = await item.isValid();

      // if (isValid) {
      let payload = item.getPayload();

      if (task_id) {
        payload.task_id = task_id;
      }

      if (payload.is_there_container === "No") {
        delete payload.is_there_container_file;
      }

      if (currentUrl.includes("schedule_id")) {
        payload.schedule_id = scheduleId;
      }
      let promise = null;
      if (item.isEdit) {
        promise = MababniService.update(item._id, payload);
      } else {
        promise = MababniService.create(payload);
      }
      promise
        .then(async (res) => {
          console.log(res, "res");

          try {
            if (res.status === 200 && res?.data) {
              await item?.draft?.clearDraft?.();
              setData(res.data.data);
              toast.success(res.data.data);
              item.setIsSubmit(false);
            } else {
              toast.error(res.data.data);
              item.setIsSubmit(false);
            }
          } catch (error) {
            item.setIsSubmit(false);
            throw res;
          }
        })
        .catch((error) => {
          let e = error.response?.data?.errors;
          if (e && typeof e === "object") {
            Object.entries(e).forEach(([key, value]) => toast.error(value));
          } else {
            toast.error("Network Error: Please fix your internet connection!");
          }

          item.setIsSubmit(false);
        });

      // return;
      // }

      // item.setValidation(true);
    };
    // ** State
    const ValidationHandler = observer(
      ({ isValid, message = `"This field is required!"` }) => {
        return (
          <>
            <Input type="text" hidden invalid={!isValid && item.isValidation} />
            <FormFeedback>
              <FormattedMessage id={message} defaultMessage={message} />
            </FormFeedback>
          </>
        );
      }
    );

    const intl = useIntl();
    const stepperRef = useRef();

    const getTranslation = (text) => {
      return intl.formatMessage({
        id: text,
        defaultMessage: text,
      });
    };

    const Submit = observer(() => {
      return (
        <div className="d-flex justify-content-end">
          <Button.Ripple
            className="round border-none "
            color="primary"
            type="submit"
            onClick={submit}
            disabled={item.isSubmit}
          >
            {item.isSubmit && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            <span style={{ color: "white" }}>
              {item.isEdit ? (
                <FormattedMessage id="Update" defaultMessage="Update" />
              ) : (
                <FormattedMessage id="Submit" defaultMessage="Submit" />
              )}
            </span>
          </Button.Ripple>
        </div>
      );
    });

    const Question = observer(
      ({
        title = "test",
        attribute = "test",
        isOptional = false,
        modal,
        onChange,
        md = 6,
        margin = isEn ? 5 : 15,
        number = null,
        defaultOptions = ["Yes", "No"],
      }) => {
        return (
          <Col md={md} sm="12" className="mb-2">
            <p className="m-0">
              {!!title && (
                <Label className="form-label text-input-label">
                  {number && (
                    <>
                      <strong>{number}</strong> {` - `}
                    </>
                  )}
                  <FormattedMessage id={title} defaultMessage={title} />
                </Label>
              )}
            </p>

            {defaultOptions.map((option) => {
              return (
                <GetOption
                  margin={margin}
                  onChange={onChange}
                  modal={modal}
                  attribute={attribute}
                  title={option}
                />
              );
            })}

            {!isOptional && (
              <ValidationHandler
                isValid={modal ? modal[attribute] : item[attribute]}
              />
            )}
          </Col>
        );
      }
    );

    const GetOption = observer(
      ({ margin, onChange, modal, attribute, title }) => {
        return (
          <>
            <Input
              style={{ marginRight: margin, marginLeft: 5 }}
              type="radio"
              value={title}
              onChange={(e) =>
                onChange
                  ? onChange(e.target.value)
                  : handleChange(attribute, e.target.value)
              }
              checked={(modal ? modal[attribute] : item[attribute]) === title}
            />
            <Label className="form-check-label">
              <FormattedMessage id={title} defaultMessage={title} />
            </Label>
          </>
        );
      }
    );

    const TextInput = observer(
      ({
        attribute,
        title,
        type = "text",
        md = "6",
        modal,
        onChange,
        isDisable = false,
        number = null,
        ValidComponet = null,
        placeholder = "",
        onWheel = null,
        isRequired = true,
        mask = null,
      }) => {
        return (
          <Col className="mb-1" md={md} sm="12">
            {!!title && (
              <Label className="form-label text-input-label">
                {number && (
                  <>
                    <strong>{number}</strong> {` - `}
                  </>
                )}
                <FormattedMessage id={title} defaultMessage={title} />
              </Label>
            )}
            <InputGroup>
              {mask ? <InputGroupText>{mask}</InputGroupText> : null}

              <Input
                step={0.1}
                // maxLength="6"
                // pattern={pattern}
                onWheel={onWheel}
                disabled={isDisable}
                className={mask ? "input-style mask" : "input-style"}
                type={type}
                value={modal ? modal[attribute] : item[attribute]}
                onChange={(e) => {
                  onChange
                    ? onChange(e.target.value)
                    : handleChange(attribute, e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: placeholder || title,
                  defaultMessage: placeholder || title,
                })}
              />
            </InputGroup>
            {isRequired &&
              (ValidComponet ? (
                ValidComponet
              ) : (
                <ValidationHandler
                  isValid={modal ? modal[attribute] : item[attribute]}
                />
              ))}
          </Col>
        );
      }
    );

    const FileUploaderWrapper = observer(
      ({
        attribute,
        md = 12,
        isOptional = true,
        file,
        isSm = true,
        title = "Attachment",
        header = "",
        maxFiles = 20,
        isFileLimit = true,
        isAllFiles = false,
        required = false,
        modal,
      }) => {
        const fileHandler = file ? modal[file] : item[attribute];

        if (!fileHandler) return null;
        return (
          <Col className="mb-1" md={md} sm="12">
            <div className="file-header-style">
              {!!header && (
                <span className="title-style">
                  <FormattedMessage id={header} defaultMessage={header} />
                </span>
              )}

              <Label className="form-label text-input-label">
                <FormattedMessage id={title} defaultMessage={title} />
              </Label>
            </div>

            <FileUploader
              isFileLimit={isFileLimit}
              isAllFiles={isAllFiles}
              width={20}
              isSm={isSm}
              images={fileHandler.images}
              ref={fileHandler.fileRef}
              onUploadingComplete={() => {
                fileHandler.setIsUploading(
                  fileRef.current?.checkIsAllFilesUploaded?.()
                );
              }}
              onUploadingStart={() => {
                fileHandler.setIsUploading(false);
              }}
              handleChange={(images) => fileHandler.setImages(images)}
              maxFiles={maxFiles}
            />
            {required && (
              <ValidationHandler isValid={fileHandler.images?.length} />
            )}
          </Col>
        );
      }
    );

    const TextView = ({ obj, name, head }) => {
      // if (!obj[name]) return null;
      if (!obj[name] && obj[name] != "") return null;

      return (
        <div className="main-section_info-container_info col-md-3">
          <div className="info-head">{IntlService.m(head)}</div>
          <div className="info-content">
            {IntlService.m(obj[name]) || "N/A"}
          </div>
        </div>
      );
    };

    const DropDown = observer(
      ({
        attribute,
        title,
        type = "text",
        md = "6",
        modal,
        isRequired = true,
        onChange,
        isDisable = false,
        number = null,
        ValidComponet = null,
        placeholder = "",
        options,
        ...props
      }) => {
        return (
          <Col className="mb-1" md={md} sm="12">
            <Label className="form-label text-input-label">
              {number && (
                <>
                  <strong>{number}</strong> {` - `}
                </>
              )}
              <FormattedMessage id={title} defaultMessage={title} />
            </Label>
            <Select
              isDisabled={isDisable}
              placeholder={intl.formatMessage({
                id: placeholder || "Select",
                defaultMessage: placeholder || "Select",
              })}
              onChange={(e) =>
                onChange ? onChange(e) : handleChange(attribute, e)
              }
              value={modal ? modal[attribute] : item[attribute]}
              theme={selectThemeColors}
              className="react-select"
              classNamePrefix="select"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: 8,
                  borderColor: "#CFD3D4",
                }),
              }}
              options={options.map((item) => {
                if (typeof item == "object") {
                  return { ...item };
                }
                return { label: getTranslation(item), value: item };
              })}
              {...props}
            />
            {isRequired && (
              <ValidationHandler
                isValid={modal ? modal[attribute] : item[attribute]}
              />
            )}
          </Col>
        );
      }
    );

    const IdentifyLicenseInformation = observer(() => {
      return (
        <div>
          <Row className="d-flex align-items-center">
            <Row className="d-flex align-items-center">
              <TextInput
                md={"4"}
                type="text"
                attribute={"closed_license_number"}
                title="License Number"
                placeholder="Eg. 682552424"
                onWheel={(e) => {
                  e.target.blur(); // Unfocus the input field
                }}
                ValidComponet={
                  item["closed_license_number"] &&
                  !item.license_information &&
                  !item.idSearch_Loading && (
                    <p className="text-danger">Invalid License Number!</p>
                  )
                }
                onChange={(e) => {
                  handleChange(
                    "closed_license_number",
                    e.replace(/[^0-9]/g, "")
                  );
                }}
                isDisable={!!license}
              />
              {item.idSearch_Loading && (
                <Col md="2">
                  <Spinner color="primary" />
                </Col>
              )}
              {item.license_information && (
                <div className="main-section col-md-12">
                  <Label className="form-label text-input-label">
                    <FormattedMessage
                      id="The License Information"
                      defaultMessage="The License Information"
                    />
                  </Label>
                  <div className="main-section_info-container row">
                    {[
                      // { Attribute: "_id", Title: "Id" },
                      // { Attribute: "created_at", Title: "Created At" },
                      // { Attribute: "updated_at", Title: "Updated At" },
                      { Attribute: "license_id", Title: "License Id" },
                      { Attribute: "license_number", Title: "License Number" },
                      // {
                      //   Attribute: "license_type_id",
                      //   Title: "License Type Id",
                      // },
                      {
                        Attribute: "license_type_name",
                        Title: "License Type Name",
                      },
                      {
                        Attribute: "supervisor_office_id",
                        Title: "Supervisor Office Id",
                      },
                      {
                        Attribute: "supervisor_office_name",
                        Title: "Supervisor Office Name",
                      },
                      {
                        Attribute: "engineering_office_id",
                        Title: "Engineering Office Id",
                      },
                      {
                        Attribute: "engineering_office_name",
                        Title: "Engineering Office Name",
                      },
                      {
                        Attribute: "license_submission_date",
                        Title: "License Submission Date",
                        date: true,
                      },
                      {
                        Attribute: "license_expiration_date",
                        Title: "License Expiration Date",
                        date: true,
                      },
                      { Attribute: "payment_number", Title: "Payment Number" },
                      { Attribute: "payment_value", Title: "Payment Value" },
                      {
                        Attribute: "payment_date",
                        Title: "Payment Date",

                        date: true,
                      },
                      { Attribute: "payment_status", Title: "Payment Status" },
                      {
                        Attribute: "municipality_code",
                        Title: "Municipality Code",
                      },
                      {
                        Attribute: "municipality_name",
                        Title: "Municipality Name",
                      },
                      { Attribute: "district_code", Title: "District Code" },
                      { Attribute: "district_name", Title: "District Name" },
                      { Attribute: "street_name", Title: "Street Name" },
                      { Attribute: "land_area", Title: "Land Area" },
                      {
                        Attribute: "total_building_area",
                        Title: "Total Building Area",
                      },
                      {
                        Attribute: "number_of_floors",
                        Title: "Number Of Floors",
                      },
                      { Attribute: "total_of_units", Title: "Total Of Units" },
                      { Attribute: "usage_type_id", Title: "Usage Type Id" },
                      { Attribute: "usage_type", Title: "Usage Type" },
                      {
                        Attribute: "buildingtype_id",
                        Title: "Buildingtype Id",
                      },
                      {
                        Attribute: "buildingtype_name",
                        Title: "Buildingtype Name",
                      },
                      { Attribute: "fencing_length", Title: "Fencing Length" },
                      {
                        Attribute: "drilling_output_weight",
                        Title: "Drilling Output Weight",
                      },
                      {
                        Attribute: "finishing_output_weight",
                        Title: "Finishing Output Weight",
                      },
                      { Attribute: "total_remnants", Title: "Total Remnants" },
                      { Attribute: "latitude", Title: "Latitude" },
                      { Attribute: "longitude", Title: "Longitude" },
                    ].map((row) => (
                      <div className="main-section_info-container_info col-md-3">
                        <div className="info-head">
                          {IntlService.m(row.Title)}
                        </div>
                        <div className="info-content">
                          {row.date
                            ? moment(
                                item.license_information[row.Attribute]
                              ).format("ll")
                            : item.license_information[row.Attribute] || "N/A"}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Row>
            {item.verification_type?.value === "closed_license_number" && (
              <Question
                attribute={"visual_pollution"}
                title="Is there any visual pollution"
                onChange={(e) => {
                  handleChange("visual_pollution", e);
                }}
                md={12}
              />
            )}

            {(item.verification_type?.value === "mumtathil_report_number" ||
              item.verification_type?.value === "report_940_number") && (
              <>
                <Question
                  attribute={"visual_pollution"}
                  title="Has the visual pollution been processed"
                  onChange={(e) => {
                    handleChange("visual_pollution", e);
                  }}
                  md={12}
                />
                {item.visual_pollution == "Yes" && (
                  <DropDown
                    md={"12"}
                    attribute={"visual_pollution_dropdown"}
                    title={"ماهو عنصر التشوه البصري"}
                    options={item.visual_pollution_dropdown_options}
                  />
                )}
              </>
            )}
            {item.verification_type?.value && (
              <FileUploaderWrapper
                md={6}
                file={"visual_pollution_file"}
                isSm={false}
                required={true}
                // title="Picture"
                modal={item}
              />
            )}
            <>
              <DropDown
                md={"12"}
                attribute={"quality_check"}
                title={"Quality Check"}
                options={["Yes", "No"]}
                isRequired={false}
              />

              {/* <TextInput
                md={"12"}
                type="textarea"
                attribute={"reason_for_not_visiting"}
                title={"Reason for not visiting"}
                placeholder=""
                onChange={(e) => {
                  handleChange("reason_for_not_visiting", e);
                }}
                isRequired={false}
                modal={item}
              /> */}
              <DropDown
                md={"12"}
                attribute={"check_work_started"}
                title={"Is the work has been started?"}
                options={["Yes", "No", "Work Done"]}
                isRequired={false}
              />
              <DropDown
                md={"12"}
                attribute={"reason_for_not_visiting"}
                title={"Reason for not visiting"}
                options={[
                  "Blocked roadway",
                  "Unpaved roadway",
                  "Blocked area (restricted zone)",
                  "Other",
                ]}
                isRequired={false}
              />
              {item.reason_for_not_visiting?.value === "Blocked roadway" ||
              item.reason_for_not_visiting?.value === "Unpaved roadway" ||
              item.reason_for_not_visiting?.value ===
                "Blocked area (restricted zone)" ? (
                <FileUploaderWrapper
                  md={6}
                  file={"reason_for_not_visiting_file"}
                  isSm={false}
                  required={true}
                  // title="Picture"
                  modal={item}
                />
              ) : null}
              <DropDown
                md={"12"}
                attribute={"is_there_container"}
                title={"Is there container in the site?"}
                options={["Yes", "No"]}
                isRequired={false}
              />
              {item.is_there_container?.value === "Yes" ? (
                <FileUploaderWrapper
                  md={6}
                  file={"is_there_container_file"}
                  isSm={false}
                  required={true}
                  // title="Picture"
                  modal={item}
                />
              ) : null}
            </>
          </Row>
        </div>
      );
    });

    const ObservationInformation = observer(() => {
      if (!item.observationInformations?.length) return null;
      return (
        <div className="d-flex  flex-column align-items-end">
          {item.observationInformations?.map((obs, index) => (
            <Row key={index + obs.uuid}>
              <div>
                <Label>
                  {IntlService.m("Observation Information:")}
                  {index + 1}
                </Label>
                {item.observationInformations?.length > 1 && (
                  <a
                    onClick={() => item.removeObservation(obs.uuid)}
                    className="text-danger"
                  >
                    ({IntlService.m("Remove")})
                  </a>
                )}
              </div>
              <TextInput
                md={"6"}
                type={"Number"}
                attribute={"survey_inspection_number"}
                title={
                  "Enter the field survey inspection number from (Mumtathil) system"
                }
                mask="VFIN-"
                isRequired={false}
                placeholder=""
                onChange={(e) => {
                  obs.setAttribute("survey_inspection_number", e);
                }}
                modal={obs}
              />
              <DropDown
                md={"6"}
                onChange={(e) => {
                  obs.setAttribute("main_classification", e);
                  obs.getSubClasification(e._id);
                }}
                attribute={"main_classification"}
                title={"Enter the Main classification"}
                modal={obs}
                options={item.main_classificationOptions}
              />
              <DropDown
                onChange={(e) => {
                  obs.setAttribute("sub_classification", e);
                }}
                md={"6"}
                attribute={"sub_classification"}
                title={"Enter the Sub classification"}
                modal={obs}
                options={obs.sub_classificationOptions}
              />
              {obs.showReason && (
                <DropDown
                  onChange={(e) => {
                    obs.setAttribute("reason_of_sub_classification", e);
                  }}
                  md={"6"}
                  attribute={"reason_of_sub_classification"}
                  title={"What the reason of it?"}
                  modal={obs}
                  options={obs.sub_classificationrReasonOptions}
                />
              )}

              <TextInput
                md={"6"}
                onChange={(e) => {
                  obs.setAttribute("estimated_quantites", e);
                }}
                type={"Text"}
                attribute={"estimated_quantites"}
                title={"The estimated quantites"}
                // placeholder=""
                modal={obs}
              />
              <TextInput
                md={"12"}
                onChange={(e) => {
                  obs.setAttribute("description", e);
                }}
                type={"textarea"}
                attribute={"description"}
                title={"Description"}
                modal={obs}
                // placeholder="Ex. Address"
              />
              <FileUploaderWrapper
                md={12}
                file={"picture"}
                isSm={false}
                required={true}
                title="Picture"
                modal={obs}
              />
            </Row>
          ))}
          <Button
            // className="btn btn-primary"
            color="primary"
            onClick={() => item.addObservations()}
          >
            {IntlService.m("Add More")}
          </Button>
        </div>
      );
    });

    const steps = useMemo(
      () =>
        Object.entries(item?.formFields || {}).map(([key, attributes]) => {
          if (
            ["inspection", "identify_observation_information"].includes(key)
          ) {
            return {
              id: key,
              title: getTranslation(startCase(key)),
              content: <IdentifyLicenseInformation />,
            };
          }

          if (key === "observation_information") {
            return {
              id: key,
              title: getTranslation(startCase(key)),
              content: <ObservationInformation />,
            };
          }

          return {
            id: key,
            title: getTranslation(startCase(key)),
            content: (
              <div>
                <h1>{startCase(key)}</h1>
              </div>
            ),
          };
        }),
      [item?.formFields, item?.test, item?.image, item]
    );

    if (!item?.formFields) return null;

    if (data)
      return (
        <div className="submitted-page">
          <div className="wizard-header submitHeader">
            {/* <h1>hello</h1> */}
          </div>
          <div>
            <Card>
              <CardBody className="submit-card">
                <figure>{submitPageIcon}</figure>
                <p className="title">
                  {IntlService.m("Submitted Successfully")}
                </p>
                {/* <p className="para">
                  Please review all the information you previously typed in the
                  past steps, and if all is okay, submit your request , will
                  review and get back to you
                </p> */}

                {!!task_id ? (
                  <Button onClick={() => navigate("/Schedule-Task")}>
                    {IntlService.m("Go back to listing")}
                  </Button>
                ) : (
                  <Button onClick={() => navigate("/mababni-inspection-visit")}>
                    {IntlService.m("Go back to listing")}
                  </Button>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      );

    return (
      <>
        <Row>
          <Col>
            {item.isReview && <MababniShow itemProp={item} />}
            <div
              style={{
                display: item.isReview ? "none" : "unset",
              }}
            >
              {!!item?.formFields && (
                <Wizard
                  ref={stepperRef}
                  instance={(el) => handleChange("stepper", el)}
                  steps={steps}
                  onStepPress={item.onStepPress}
                />
              )}
            </div>

            <Card className="shadow-none mt-2">
              <CardBody>
                <Row>
                  <Col sm="12">
                    {item.isReview ? (
                      <div className="d-flex flex-row-reverse">
                        <Submit />
                        <Button.Ripple
                          style={{
                            backgroundColor: "#10A945",
                            color: "black",
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                          className="round btun"
                          onClick={(e) => {
                            e.preventDefault();
                            item.setIsReview(false);
                          }}
                          color="primary"
                        >
                          <span style={{ color: "white" }}>
                            <FormattedMessage
                              id="Back"
                              defaultMessage={"Back"}
                            />
                          </span>
                        </Button.Ripple>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between flex-wrap">
                        <AutoSave draft={item?.draft} />
                        <div>
                          <Button.Ripple
                            className="round btn mx-3"
                            onClick={(e) => {
                              e.preventDefault();
                              item.previousStep();
                            }}
                            disabled={item.isFirstStep}
                            color="primary"
                            outline
                          >
                            <span>
                              <FormattedMessage
                                id="Previous"
                                defaultMessage="Previous"
                              />
                            </span>
                          </Button.Ripple>
                          <Button.Ripple
                            className="round btn"
                            onClick={(e) => {
                              e.preventDefault();

                              if (item.isLastStep) {
                                item.setIsReview(true);
                              } else {
                                item.nextStep();
                              }
                            }}
                            color="primary"
                          >
                            <span style={{ color: "white" }}>
                              <FormattedMessage
                                id={item.isLastStep ? "Review" : "Next"}
                                defaultMessage={
                                  item.isLastStep ? "Review" : "Next"
                                }
                              />
                            </span>
                          </Button.Ripple>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
);

const MababniInspectionObserver = observer((props) => {
  const [item, setInspection] = useState();
  const [medicines] = useState([]);
  const { id, schedule_id } = useParams();

  useEffect(() => {
    init();
  }, [id]);

  const init = async () => {
    let isEdit = !!id;
    let _closeVerification = null;

    if (!_closeVerification) {
      _closeVerification = new MababniModal();
    }

    // Find all regions to present in the dropdown menu

    // Set the current login user id
    if (!isEdit) {
      _closeVerification.setUserId(getUserData());
    } else {
      let res = await MababniService.show(id);
      _closeVerification = new MababniModal(res.data.data);

      _closeVerification.isEdit = true;
    }
    setInspection(_closeVerification);
  };

  const onClearModal = () => {
    let modal = new MababniModal();
    modal.setUserId(getUserData()._id);
    setInspection(modal);
  };

  return (
    <MababniInspection
      {...props}
      item={item}
      onClearModal={onClearModal}
      medicines={medicines}
      scheduleId={schedule_id}
    />
  );
});

export default MababniInspectionObserver;
