import { lazy } from "react";
import CheckInFormObserver from "views/pages/Add/CheckinForm/CheckInForm";
import ClosedReportObserver from "views/pages/Add/ClosedReport/ClosedReport";
import ClosedUnderWarantyObserver from "views/pages/Add/ClosedUnderWaranty/ClosedUnderWaranty";
import EmergencyObserver from "views/pages/Add/Emergency/Emergency";
import MababniInspectionObserver from "views/pages/Add/MababniInspection/MababniInspection";
import MumtahilReportObserver from "views/pages/Add/MumtahilReport/MumtahilReport";
const Inspection = lazy(() =>
  import("../../views/pages/Add/inspection/Inspection")
);

const CloseVerification = lazy(() =>
  import("../../views/pages/Add/closeVerification/CloseVerification")
);

const Modules = lazy(() => import("../../views/pages/Add/Modules/Modules"));

// const Regions = lazy(() => import("../../views/pages/Add/Regions/Regions"));
const Translation = lazy(() =>
  import("../../views/pages/Add/Translation/Translation")
);
const Users = lazy(() => import("../../views/pages/Add/User/User"));
const License = lazy(() => import("../../views/pages/Add/License/License"));
const ScheduleTask = lazy(() => import("../../views/pages/Add/ScheduleTask/ScheduleTask"));


const AddRoutes = [
  {
    element: <Users />,
    path: "/Users/Add",
    isAddRoute: true,
    default: true,
    route: "users",
    slug: "users",
    title: "users",
    isForm: true,
  },

  {
    element: <Modules />,
    path: "/Modules/Add",
    isAddRoute: true,
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },
  {
    element: <Modules key="editmodules" />,
    path: "/Modules/Edit/:id",
    permissions: ["admin"],
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },

  // {
  //   element: <Regions />,
  //   path: "/Regions/Add",
  //   isAddRoute: true,
  //   route: "Add Regions",
  //   slug: "Regions",
  //   title: "Add Regions",
  //   isForm: true,
  // },
  // {
  //   element: <Regions key="editregions" />,
  //   path: "/Regions/Edit/:id",
  //   route: "Regions",
  //   slug: "Regions",
  //   title: "Regions",
  //   isForm: true,
  // },
  {
    element: <Translation />,
    path: "/Translation",
    route: "Translation",
    slug: "Translation",
    title: "Translation",
    isForm: true,
  },
  {
    element: <License />,
    path: "/License",
    route: "License",
    slug: "License",
    title: "License",
    isForm: true,
  },
  // {
  //   element: <Sites />,
  //   path: "Sites/Add",
  //   isAddRoute: true,
  //   route: "Sites",
  //   slug: "Sites",
  //   title: "Sites",
  //   isForm: true,
  // },
  // {
  //   element: <Sites key="editsites" />,
  //   path: "/Sites/Edit/:id",
  //   route: "Sites",
  //   slug: "Sites",
  //   title: "Sites",
  //   isForm: true,
  // },

  {
    element: <Users key={"UsersEdit"} />,
    path: "Users/Edit/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
    isForm: true,
  },
  {
    element: <Inspection />,
    path: "/inspection-visit",
    isAddRoute: true,
    route: "inspection-visit",
    slug: "inspection-visit",
    title: "Inspection Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <Inspection key="edit" />,
    path: "/inspection-visit/edit/:id",
    isAddRoute: true,
    route: "inspection-visit",
    slug: "inspection-visit",
    title: "Inspection Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <EmergencyObserver key={"schedule-task-emergency"} />,
    path: "/emergency-visit/:schedule_id",
    isAddRoute: true,
    route: "emergency-visit",
    slug: "emergency-visit",
    title: "Emergency Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <EmergencyObserver />,
    path: "/emergency-visit",
    isAddRoute: true,
    route: "emergency-visit",
    slug: "emergency-visit",
    title: "Emergency Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <EmergencyObserver key="edit" />,
    path: "/emergency-visit/edit/:id",
    isAddRoute: true,
    route: "emergency-visit",
    slug: "emergency-visit",
    title: "Emergency Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <CloseVerification key={"940_varification"} />,
    path: "/940-verification-visit",
    isAddRoute: true,
    route: "940-verification-visit",
    slug: "940-verification-visit",
    title: "940 Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <CloseVerification key={"schedule-task-940"} />,
    path: "/940-verification-visit/:schedule_id",
    isAddRoute: true,
    route: "940-verification-visit",
    slug: "940-verification-visit",
    title: "940 Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <CloseVerification key={"940_varificationedit"} />,
    path: "/940-verification-visit/edit/:id",
    isAddRoute: true,
    route: "940-verification-visit",
    slug: "940-verification-visit",
    title: "940 Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MumtahilReportObserver key={"Mumtahil"} />,
    path: "/operation_and_maintenance_visit",
    isAddRoute: true,
    route: "/operation_and_maintenance_visit",
    slug: "/operation_and_maintenance_visit",
    title: "Mumtahil Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MumtahilReportObserver key={"schedule-task-mumtahi"} />,
    path: "/operation_and_maintenance_visit/:schedule_id",
    isAddRoute: true,
    route: "/operation_and_maintenance_visit",
    slug: "/operation_and_maintenance_visit",
    title: "Mumtahil Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MumtahilReportObserver key={"Mumtahiledit"} />,
    path: "/operation_and_maintenance_visit/edit/:id",
    isAddRoute: true,
    route: "/operation_and_maintenance_visit",
    slug: "/operation_and_maintenance_visit",
    title: "Mumtahil Verification Visit",
    isForm: true,
    isAuth: true,
  },
  // {
  //   element: <ClosedReportObserver key={"close_varification"} />,
  //   path: "/expired-verfication-license-form",
  //   isAddRoute: true,
  //   route: "/expired-verfication-license",
  //   slug: "/expired-verfication-license",
  //   title: "Expired verfication license",
  //   isForm: true,
  //   isAuth: true,
  // },
  // {
  //   element: <ClosedReportObserver key={"schedule-task-expired-license"} />,
  //   path: "/expired-verfication-license-form/:schedule_id",
  //   isAddRoute: true,
  //   route: "/expired-verfication-license",
  //   slug: "/expired-verfication-license",
  //   title: "Expired verfication license",
  //   isForm: true,
  //   isAuth: true,
  // },
  // {
  //   element: <ClosedReportObserver key={"edit"} />,
  //   path: "/expired-verfication-license-form/edit/:id",
  //   isAddRoute: true,
  //   route: "/expired-verfication-license",
  //   slug: "/expired-verfication-license",
  //   title: "Expired verfication license",
  //   isForm: true,
  //   isAuth: true,
  // },
  {
    element: (
      <ClosedUnderWarantyObserver key={"schedule-task-close-warranty"} />
    ),
    path: "/close-license-under-warranty-visit/:schedule_id",
    isAddRoute: true,
    route: "/close-license-under-warranty-visit",
    slug: "/close-license-under-warranty-visit",
    title: "Close license under warranty",
    isForm: true,
    isAuth: true,
  },
  {
    element: (
      <ClosedUnderWarantyObserver key={"close-license-under-warranty"} />
    ),
    path: "/close-license-under-warranty-visit",
    isAddRoute: true,
    route: "/close-license-under-warranty-visit",
    slug: "/close-license-under-warranty-visit",
    title: "Close license under warranty",
    isForm: true,
    isAuth: true,
  },
  {
    element: (
      <ClosedUnderWarantyObserver key={"close-license-under-warranty-edit"} />
    ),
    path: "/close-license-under-warranty-visit/edit/:id",
    isAddRoute: true,
    route: "/close-license-under-warranty-visit",
    slug: "/close-license-under-warranty-visit",
    title: "Close license under warranty",
    isForm: true,
    isAuth: true,
  },



  // Schedule Task
  {
    element: <ScheduleTask />,
    path: "/Schedule-Task/Add",
    isAddRoute: true,
    route: "Schedule Task",
    slug: "Schedule-Task",
    title: "Schedule Task Form",
    isForm: true,
    isAuth: true,
  },
  {
    element: <ScheduleTask />,
    path: "/Schedule-Task/Edit/:id",
    route: "Schedule Task",
    slug: "Schedule-Task",
    title: "Schedule Task Form",
    isForm: true,
    isAuth: true,
  },

  {
    element: <CheckInFormObserver key={"field_ObservationVisit"} />,
    path: "/field-observation-visit",
    isAddRoute: true,
    route: "field-observation-visit",
    slug: "field-observation-visit",
    title: "Close Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <CheckInFormObserver key={"schedule-task-obs"} />,
    path: "/field-observation-visit/:schedule_id",
    isAddRoute: true,
    route: "field-observation-visit",
    slug: "field-observation-visit",
    title: "Close Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <CheckInFormObserver key={"field_ObservationVisitedit"} />,
    path: "/field-observation-visit/edit/:id",
    isAddRoute: true,
    route: "field-observation-visit",
    slug: "field-observation-visit",
    title: "Close Verification Visit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <Inspection key={"schedule-task-inspection"} />,
    path: "/inspection-visit/:schedule_id",
    route: "inspection-visit",
    slug: "inspection-visit",
    title: "Inspection Visit Form",
    isForm: true,
    isAuth: true,

  },
  {
    element: <Inspection key={"inspection-edit"} />,
    path: "/inspection-visit/Edit/:id",
    route: "inspection-visit",
    slug: "inspection-visit",
    title: "Inspection Visit Edit",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MababniInspectionObserver key={"edit"} />,
    path: "/mababni-inspection-form/edit/:id",
    isAddRoute: true,
    route: "mababni-inspection-visit",
    slug: "mababni-inspection-visit",
    title: "Mababn verfication license",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MababniInspectionObserver key={"add"} />,
    path: "/mababni-inspection-form",
    isAddRoute: true,
    route: "Mababni Inspection Visit",
    slug: "Mababni Inspection Visit",
    title: "Mababn verfication license",
    isForm: true,
    isAuth: true,
  },
  {
    element: <MababniInspectionObserver key={"schedule-task-mabani"} />,
    path: "/mababni-inspection-form/:schedule_id",
    isAddRoute: true,
    route: "Mababni Inspection Visit",
    slug: "Mababni Inspection Visit",
    title: "Mababn verfication license",
    isForm: true,
    isAuth: true,
  },

  // %template%
];
export default AddRoutes;

export const defaultRoutes = {
  user: Users,
};
