import { lazy } from "react";
import DashboardJD from "views/pages/Graph/DashboardJD";

const DashboardRoutes = [
  {
    element: <DashboardJD />,
    path: "/dashboard",
    route: "dasboard",
    slug: "dasboard",
    title: "Dasboard",
    isAuth: true,
  },

  // %template%
];
export default DashboardRoutes;
