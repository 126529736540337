// ** React Imports
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "@components/avatar";
//import avatarImg from "@src/assets/images/portrait/small/avatar-s-20.jpg";
import User from "../../../../../assets/images/user.png";
// ** Icons Imports
import { Disc, X, Circle } from "react-feather";
// ** Config
//import themeConfig from "@configs/themeConfig";

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils";
// import Logo from "../../../../../assets/images/brandlogoWhite.svg";
import Logo from "../../../../../assets/wasfaty/brandlogo.svg";
// import Favicon from "../../../../../assets/images/mohsmallicon.png";
import LogoutDropdown from "../../../../../views/wasfaty/Helper/LogoutDropdown";
import colpsedMOHIcon from "../../../../../assets/wasfaty/MOH-small.svg";
import Header from "../../../../../views/AuthScreens/assets/static/img/brandlogowhite.svg";

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    //setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props;

  // ** Vars
  const user = getUserData();
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);
  const navigate = useNavigate();
  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      );
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      );
    }
  };

  return (
    <div style={{ width: "100%" }} className=" ">
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
          marginTop: 10,
          marginRight: -10,
        }}
        onClick={() => navigate("/")}
      >
        {!menuCollapsed || menuHover ? (
          <>
            <img
              src={Header}
              alt="logo"
              style={{ width: "50%", marginTop: 20 }}
            />
            {/* <Toggler /> */}
          </>
        ) : (
          <img src={colpsedMOHIcon} width="50" />
        )}
      </div>
    </div>
  );
};

export default VerticalMenuHeader;
