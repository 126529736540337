/**
 *
 * @param {Object} props
 * @param {String} props.text
 * @param {String} props.iconSrc
 * @param {String} props.textClassName
 * @param {Number} props.id
 * @param {Number} props.totalRecords
 * @param {Number} props.activeIndex
 * @param {Function} props.onClick
 *
 */

const Tab = (props) => {
  const width = 200;
  const scaleFactor = width / 212; // Adjust the scale factor based on original width
  const isActive = props.activeIndex === props.id;
  const handleClick = () => props.onClick(props.id);
  const zIndex = isActive ? props.totalRecords : props.totalRecords - props.id;
  return (
    <svg
      width={width}
      height={48}
      fill="none"
      className="app-tabs--tab"
      onClick={handleClick}
      style={{ zIndex }}
    >
      <path
        fill={isActive ? "#263238" : "#E4E5E5"}
        stroke="#D0D3E8"
        strokeWidth={0.5}
        d={`M${180.72 * scaleFactor} 47.746
          .25 47.516
          V${13.36 * scaleFactor}
          C.25 ${5.979 * scaleFactor} ${5.56 * scaleFactor} .25 ${
          12.318 * scaleFactor
        } .25
          h${156.245 * scaleFactor}
          c3.808 0 7.399 1.834 9.512 5.272
          l.003 .005 23.987 37.217 .008 .011
          c1.726 2.383 4.021 4.168 6.647 4.991
          Z`}
        // d="M208.72 47.746.25 47.516V13.36C.25 5.979 5.56.25 12.318.25h156.245c3.808 0 7.399 1.834 9.512 5.272l.003.005 23.987 37.217.008.011c1.726 2.383 4.021 4.168 6.647 4.991Z"
      />
      {props.iconSrc && (
        <image
          x={26} // Adjust horizontal position of the icon within the shape
          y={10} // Adjust vertical position of the icon within the shape
          width={24} // Adjust icon width
          height={28} // Adjust icon height
          xlinkHref={props.iconSrc}
        />
      )}
      <text
        x={width / 2} // Center text horizontally
        y={30} // Adjust vertical position of text within the shape
        textAnchor="middle" // Center-align the text
        fill={isActive ? "#fff" : "#656565"} // Text color
        fontSize="15" // Font size
        className={`tab-text ${props.textClassName}`}
      >
        {props.text}
      </text>
    </svg>
  );
};

Tab.defaultProps = {
  id: -1,
  text: "Tab",
  iconSrc: "",
  activeIndex: 0,
  textClassName: "",
  onClick: (e) => console.log(e),
};
export default Tab;
