import Tab from "./Tab";

import "./style.scss";

/**
 * @param {Object} props
 * @param {Array} props.tabs
 * @param {Number} props.activeIndex
 * @param {Object} props.tabProps
 * @param {Function} props.tabProps.onClick
 */

const AppTabs = (props) => {
  return (
    <div className="app-tabs">
      {props.tabs.map((item, index) => (
        <Tab
          key={index}
          id={index}
          text={item}
          activeIndex={props.activeIndex}
          totalRecords={props.tabs.length}
          {...props.tabProps}
        />
      ))}
    </div>
  );
};

AppTabs.defaultProps = {
  tabs: [],
};
export default AppTabs;
