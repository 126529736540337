import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  // CountdownTimer,
  EmergencyModel,
} from "../../wasfaty/Models/EmergencyModel";
import { observer } from "mobx-react-lite";
import { EmergencyService, IntlService } from "../../wasfaty/services";
import { fileIconDetails } from "../../../assets/wasfaty/SVG";
import moment from "moment";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";

import get from "lodash/get";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";
import SubmissionReview from "views/components/SubmissionReview";

/**
 * @param {Object} props
 * @param {EmergencyModel} props.itemProp
 */

const InspectionDetails = ({ itemProp }) => {
  const [item, setItem] = useState(itemProp);

  const { id, task_id } = useParams();
  const [errorMess, seterrorMess] = useState("");
  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);

  const getData = () => {
    setItem(null);
    EmergencyService.show(id).then((res) => {
      if (res.status == 200) {
        setItem(new EmergencyModel(res.data?.data, true));

        // countDown.setPayload(res.data?.data);
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      // countDown.updateElapsedTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );

  const FilesViewer = observer(({ title, attribute }) => {
    // console.log("item[attribute]", item[attribute]);
    return (
      <div className="main-section">
        <p className="heading">{IntlService.m(title)}</p>
        <div className="main-section_info-container row">
          <div className="main-section_info-container_file ">
            {item[attribute].images.map((url) => {
              const fileName =
                url.length > 12 ? url.substr(url.length - 12) : url;
              return (
                <div className="name" onClick={() => item.seeFile(url)}>
                  <figure>{fileIconDetails}</figure>
                  <p className="title">
                    {fileName}
                    {/* <br />
          10 kb */}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  });

  // const tabs = ["time-line", "comments", "document-check", "e-signature"];

  return (
    <>
      <div className="show-container d-block">
      <SubmissionReview  />
        <div className={`show-page p-1`}>
          <h2>{IntlService.m("Details")}</h2>
          <div className="main-section">
            <p className="heading mb-1">
              {IntlService.m("Identify License Information")}
            </p>
            <div className="main-section_info-container row">
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("License Number")}
                </div>
                <div className="info-content">{item.license_number}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>


              {/* <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m(
                    "Select the excavation stage according to the project schedule"
                  )}
                </div>
                <div className="info-content">
                  {IntlService.m(item.excavation_stage?.value)}
                </div>
              </div> */}
            </div>
          </div>{" "}
          <div className="main-section">
            <p className="heading mb-1">
              {IntlService.m("Operational Information")}
            </p>
            <div className="main-section_info-container row">
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Is there any excvaction work?")}
                </div>
                <div className="info-content">{item.any_excvaction_work}</div>
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.any_excvaction_work_file?.images?.length
                      ? "any_excvaction_work_file.images"
                      : "any_excvaction_work_file",
                    []
                  ).map((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Is the work finished?")}
                </div>
                <div className="info-content">{item.work_finished}</div>
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.work_finished_file?.images?.length
                      ? "work_finished_file.images"
                      : "work_finished_file",
                    []
                  ).map((im, index) => (
                    <div
                      className="link-div"
                      onClick={() => fetchImageAndOpenInNewTab(im)}
                    >
                      {IntlService.m("Picture link")}
                    </div>
                  ))}
                </div>
              </div>

              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Is there any visual pollution")}
                </div>
                <div className="info-content">{item.visual_pollution}</div>
              </div>

              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Quality Check")}
                </div>
                <div className="info-content">{item.quality_check?.label}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Reason for not visiting")}
                </div>
                <div className="info-content">
                  {item.reason_for_not_visiting}
                </div>
              </div>
            </div>
          </div>
          {!item.is_skip_observation && (
            <div className="main-section">
              <p className="heading mb-1">
                {IntlService.m("Observation Information")}
              </p>
              {item.observationInformations.map((it, index) => (
                <div
                  className="main-section_info-container row card p-2"
                  key={index}
                >
                  <h4 className="info-head">
                    {IntlService.m("Observation Information")}:{index + 1}
                  </h4>
                  <div className="d-flex flex-wrap">
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m(
                          "Enter the field survey inspection number from (Mumtathil) system"
                        )}
                      </div>
                      <div className="info-content">
                        {it.survey_inspection_number}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Main classification")}
                      </div>
                      <div className="info-content">
                        {it.main_classification.name}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Sub classification")}
                      </div>
                      <div className="info-content">
                        {it.sub_classification.name}
                      </div>
                    </div>
                    {it.showReason && (
                      <div className="main-section_info-container_info col-md-4">
                        <div className="info-head">
                          {IntlService.m("What the reason of it?")}
                        </div>
                        <div className="info-content">
                          {it.reason_of_sub_classification.label}
                        </div>
                      </div>
                    )}

                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("The estimated quantites")}
                      </div>
                      <div className="info-content">
                        {it.estimated_quantites}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Description")}
                      </div>
                      <div className="info-content">{it.description}</div>
                    </div>
                  </div>
                  <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                  <div className="info-content ml-1 d-flex flex-column">
                    {get(
                      it,
                      it.picture?.images?.length ? "picture.images" : "picture",
                      []
                    ).map((im, index) => (
                      <div
                        className="link-div"
                        onClick={() => fetchImageAndOpenInNewTab(im)}
                      >
                        {IntlService.m("Picture link")}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(InspectionDetails);
