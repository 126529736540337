import { Resources } from "../Resource";
import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "filedSurveyForm";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  ranking = {};

  constructor() {
    super(arguments);
  }

  usersBySuperviser = (text = "") => {
    return SC.getCall({
      url: "getUsersBySuperviser",
      params: {
        search: text,
      },
    });
  };

  assignOperator = (postData) => {
    return SC.postCall({ url: this.route + "/assigned-to", data: postData });
  };
  reAssignOperator = (postData) => {
    return SC.postCall({ url: this.route + "/reassign", data: postData });
  };

  async downloadPdf(id) {
    return new Promise((resolve, reject) => {
      SC.getCall({
        url: this.route + `/rcPdf/${id}`,
        params: {},
        responseType: "blob",
      }).then((res) => {
        try {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(res.data);
          link.download = "Completed Request";

          // console.log(link.href)

          resolve(link);

          // link.click();
        } catch (error) {
          // toast.error(IntlService.m("There is a problem with your network connection!"));
        }
      });
    });
  }

  uploadAttachment(data) {
    return new Promise((resolve, reject) => {
      SC.postCall({
        url: this.route + `/attachment`,
        data,
      }).then((res) => {
        try {
          resolve(res.data.data);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  deleteAttachment(id) {
    return new Promise((resolve, reject) => {
      SC.deleteCall({
        url: this.route + `/attachment/${id}`,
      }).then((res) => {
        resolve(true);
        // try {
        //   resolve(res.data.data);
        // } catch (error) {
        //   reject(error)
        // }
      });
    });
  }
}

const MumtahilService = new Service();

export default MumtahilService;
