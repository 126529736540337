import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "reactstrap";
import ScheduleCard from "./ScheduleCard";
import "./style.scss";
import { observer } from "mobx-react";
// import "../../../../../@core/components/ripple-button/ripple-button.scss";

function ScheduleCardView({
  schedules,
  DeleteData,

  collapse,
  setCollapse,
}) {
  return (
    <div>
      <Row className="container-margin">
        {schedules.map((schedule, index) => (
          <ScheduleCard
            schedule={schedule}
            DeleteData={DeleteData}
            key={index + "12"}
            collapse={collapse}
            setCollapse={setCollapse}
          />
        ))}
      </Row>
    </div>
  );
}

export default observer(ScheduleCardView);
