import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import InspectionService from "../../wasfaty/services/InspectionService";
import {
  // CountdownTimer,
  CloseVerificationModal,
} from "../../wasfaty/Models/CloseVerificationModal";
import { observer } from "mobx-react-lite";
import {
  CloseVerificationService,
  IntlService,
  MumtahilService,
  Verification940Service,
} from "../../wasfaty/services";
import { fileIconDetails } from "../../../assets/wasfaty/SVG";
import moment from "moment";
import { Label } from "reactstrap";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";
import AssignToModal from "../../wasfaty/Models/AssignToModal";

import get from "lodash/get";
import { useMemo } from "react";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";

const ScheduleTaskDetails = ({ itemProp }, props) => {
  const [item, setItem] = useState(itemProp);
  // // const countDown = useMemo(() => new CountdownTimer(), []);
  const data = useMemo(() => new AssignToModal(), []);

  const { id } = useParams();

  const route = useLocation();
  const [errorMess, seterrorMess] = useState("");
  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);

  const Service = {
    "expired-verfication-license": CloseVerificationService,
    "940-verification": Verification940Service,
    "Mumtahil-verification": MumtahilService,
  };

  const getData = () => {
    setItem(null);
    Service[route.pathname.split("/")[1]].show(id).then((res) => {
      if (res.status == 200) {
        setItem(new CloseVerificationModal(res.data?.data, true));

        // countDown.setPayload(res.data?.data);
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      // countDown.updateElapsedTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );
  }

  const TextView = ({ obj, name, head, md = 3 }) => {
    // if (!obj[name]) return null;
    if (!obj[name] && obj[name] != "") return null;

    return (
      <div className={`main-section_info-container_info col-md-${md}`}>
        <div className="info-head">{IntlService.m(head)}</div>
        <div className="info-content">
          {IntlService.m(obj[name]?.label || obj[name]) || "N/A"}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="show-container ">
        <div className="show-page p-1">
          <h2>{IntlService.m("Details")}</h2>
          <TextView
            head={"Form"}
            obj={item}
            name={"form_name"}
            md={12}
          />
          <TextView
            head={"Main Classification"}
            obj={item}
            name={"main_classification"}
            md={6}
          />
          <TextView
            head={"Sub Classification"}
            obj={item}
            name={"sub_classification"}
            md={6}
          />
          <TextView
            head={"Due Date"}
            obj={item}
            name={"due_date"}
            md={6}
          />
          <TextView
            head={"Inspector"}
            obj={item}
            name={"inspector_id"}
            md={6}
          />
        </div>
      </div>
    </>
  );
};

export default observer(ScheduleTaskDetails);
