import React from "react";
import { observer } from "mobx-react";
import PaginationComponent from "./Pagination";

function PaginationFooter({
  handlePageChange,
  pagination = {},
  isStopPaginationFirstCall,
}) {
  return (
    <PaginationComponent
      pagination={pagination}
      handlePageChange={handlePageChange}
      isStopPaginationFirstCall={isStopPaginationFirstCall}
    />
  );
}

export default observer(PaginationFooter);
