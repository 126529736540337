import { makeAutoObservable } from "mobx";
import { SiteService, UserService } from "../services";
import get from "lodash/get";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";

export class UserSearchModal {
  users = [];
  isLoading = false;
  search = "a";

  constructor(props) {
    this.getUsers();
    makeAutoObservable(this);
  }

  getUsers = () => {
    // // Find all sites to present in the dropdown menu
    let search = this.search;

    this.isLoading = true;
    this.users = [];
    UserService.getUsers({
      search,
    })
      .then((res) => {
        let _users = get(res, "data.data");
        if (_users?.length) {
          this.users = _users.map((user) => {
            let label = `${user.name} (${user.email})`;

            return {
              ...user,
              label,
              value: user._id,
            };
          });
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  };

  onSearch = debounce((search) => {
    if (search) {
      this.search = search;
      this.getUsers();
    }
  }, 1000);
}
