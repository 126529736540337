import { ReplaySubject } from "rxjs";
import AuthUser from "./AuthService";
const scheduleReplayObject = new ReplaySubject(0);

Pusher.logToConsole = false;
class Service {
  pusher = null;
  channel = null;

  channelName = "request-notification-channel";
  channelBind = "";

  constructor(user_id) {
    // if (user_id) {
    this.channelBind = AuthUser?.user?._id;

    this.pusher = new Pusher("d7fd01c60a68b37c5af4", {
      cluster: "mt1",
    });
    this.channel = this.pusher.subscribe(this.channelName);
    this.bindChannel();
    // }
  }

  bindChannel = () => {
    this.channel.bind(this.channelBind, (data) => {
      this.next(data);
    });
  };

  subscribe = (userId, callBack) => {
    return scheduleReplayObject.subscribe((res) => {
      // if (res?.task?._id === taskId) {
      // console.log(2, "====>", res);
      callBack(res.data);
      // }
    });
  };

  next = scheduleReplayObject.next.bind(scheduleReplayObject);
}

const NotificationPusherService = new Service();
export default NotificationPusherService;
