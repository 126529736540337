import React, { useState } from "react";
import { AppIcon } from "../../../../assets/images/icons/svgIcons";
import { Spinner } from "reactstrap";
import { SC } from "../../Api/serverCall";

const ExportList = (props) => {
  const [loading, setLoading] = useState(false);

  const exportData = async () => {
    const { url, params, exportName, filters = {} } = props;
    setLoading(true);
    await SC.getCall({
      url: url,
      params: { ...filters },
      responseType: "blob",
    }).then((res) => {
      try {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.download = exportName || "Export";
        link.click();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // toast.error(IntlService.m("There is a problem with your network connection!"));
      }
    });
  };

  return (
    <button disabled={loading} className="export-label" onClick={exportData}>
      {loading ? <Spinner /> : <AppIcon icon="downloadIcon" />}
      <span className="align-middle">Export</span>
    </button>
  );
};

export default ExportList;
