import { autorun, makeAutoObservable, reaction } from "mobx";
import { getValidPhone, scrollToRequired } from "../Helper/utils";
import FileUploadModal from "./FileUploadModel";

import AuthUser from "../services/AuthService";

import last from "lodash/last";
import { SC } from "../Api/serverCall";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { IntlService } from "../services";
import toast from "react-hot-toast";

import FileViewer from "react-file-viewer";
import { get } from "lodash";
import DraftModal from "./DraftModel";
const MySwal = withReactContent(Swal);

import { v4 as uuidv4 } from 'uuid';

class ObservationInformationRepeater {
  // Observation Information
  uuid = uuidv4();
  survey_inspection_number = "";
  main_classification = "";
  reason_of_sub_classification = "";
  sub_classification = "";
  estimated_quantites = "";
  district = null;
  street = null;
  priorityArea = null;
  description = "";
  picture = new FileUploadModal(null, true);

  sub_classificationOptions = [];
  sub_classificationrReasonOptions = [
    { label: "رخص البناء", value: "رخص البناء" },
    { label: "رخص الحفريات مياه جوفية", value: "رخص الحفريات مياه جوفية" },
    { label: "مباني سكنية", value: "مباني سكنية" },
    { label: "مباني تجارية", value: "مباني تجارية" },
    { label: "مباني حكومية", value: "مباني حكومية" },
  ];
  priorityAreaOptions = [
    { label: "الملك عبدالعزيز", value: "الملك عبدالعزيز" },
    { label: "الملك فهد", value: "الملك فهد" },
    { label: "الامير ماجد", value: "الامير ماجد" },
    { label: "الامير سلطان", value: "الامير سلطان" },
    { label: "الامير محمد بن عبدالعزيز", value: "الامير محمد بن عبدالعزيز" },
    { label: "صاري", value: "صاري" },
    { label: "قريش", value: "قريش" },
    { label: "حراء", value: "حراء" },
    { label: "الكورنيش", value: "الكورنيش" },
    { label: "المدينة المنورة", value: "المدينة المنورة" },
    { label: "فلسطين", value: "فلسطين" },
    { label: "المروة", value: "المروة" },
    { label: "الصفا", value: "الصفا" },
    { label: "الروضة", value: "الروضة" },
    { label: "النعيم", value: "النعيم" },
    { label: "السلامة", value: "السلامة" },
    { label: "السليمانية", value: "السليمانية" },
    { label: "مشرفة", value: "مشرفة" },
    { label: "النسيم", value: "النسيم" },
    { label: "الحمراء", value: "الحمراء" },
    { label: "البغدادية الغربية", value: "البغدادية الغربية" },
    { label: "ابحر الشمالية", value: "ابحر الشمالية" },
    { label: "الأمواج", value: "الأمواج" },
    { label: "البحيرات", value: "البحيرات" },
    { label: "الخليج", value: "الخليج" },
    { label: "الزمرد", value: "الزمرد" },
    { label: "الزهور", value: "الزهور" },
    { label: "الشراع", value: "الشراع" },
    { label: "الصواري", value: "الصواري" },
    { label: "الفردوس", value: "الفردوس" },
    { label: "الفنار", value: "الفنار" },
    { label: "اللؤلؤ", value: "اللؤلؤ" },
    { label: "المنارات", value: "المنارات" },
    { label: "النجمة", value: "النجمة" },
    { label: "النور", value: "النور" },
    { label: "الياقوت", value: "الياقوت" },
    { label: "حكومي", value: "حكومي" },
  ];
  districtOptions = [
    { label: "المروه", value: "المروه" },
    { label: "الصفا", value: "الصفا" },
    { label: "الروضه", value: "الروضه" },
    { label: "النعيم", value: "النعيم" },
    { label: "السلامه", value: "السلامه" },
    { label: "السليمانه", value: "السليمانه" },
    { label: "مشرفه", value: "مشرفه" },
    { label: "النسيم", value: "النسيم" },
    { label: "الحمراء", value: "الحمراء" },
    { label: "البغداديه الغربيه", value: "البغداديه الغربيه" },
  ];
  streetOptions = [
    { label: "طريق المدينه المنوره", value: "طريق المدينه المنوره" },
    { label: "طريق الكورنيش", value: "طريق الكورنيش" },
    { label: "طريق صاري", value: "طريق صاري" },
    { label: "شارع قريش", value: "شارع قريش" },
    { label: "شارع حراء", value: "شارع حراء" },
    { label: "طريق الامير سلطان", value: "طريق الامير سلطان" },
    { label: "شارع الامير ماجد", value: "شارع الامير ماجد" },
    {
      label: "شارع الامير محمد بن عبدالعزيز",
      value: "شارع الامير محمد بن عبدالعزيز",
    },
    { label: "طريق الملك عبدالعزيز", value: "طريق الملك عبدالعزيز" },
    { label: "طريق الملك فهد", value: "طريق الملك فهد" },
    { label: "شارع فلسطين", value: "شارع فلسطين" },
  ];

  constructor(payload) {
    if (payload) {
      this.setPayload(payload);
    }
    makeAutoObservable(this);
  }

  setPayload(payload) {
    if (payload) {
      Object.entries(payload).forEach(([name, value]) => {
        if (this.hasOwnProperty(name)) {
          if (name == "picture") {
            this.picture = new FileUploadModal(
              value,
              true,
              payload.pictureLocations
            );
          } else this[name] = value;
        }
      });
    }

    autorun(() => {});
    return payload;
  }

  setAttribute(name, value) {
    if (name == "main_classification") {
      this.sub_classification = "";
    }
     if(name==="survey_inspection_number" ){
      // console.log("value",value.length)

       this[name] = value.length <= 6?value:this.survey_inspection_number;
     }


    else if (name) {
      this[name] = value;
    }
  }

  getSubClasification(id) {
    SC.getCall({ url: `sub-classification/${id}` }).then((res) => {
      this.sub_classificationOptions = res.data.data.map((it) => {
        return {
          ...it,
          label: it.name,
          value: it._id,
        };
      });
    });
  }

  getPayload() {
    let payload = {};
    payload.survey_inspection_number = this.survey_inspection_number;
    payload.main_classification = this.main_classification;
    payload.sub_classification = this.sub_classification;
    payload.estimated_quantites = this.estimated_quantites;
    payload.description = this.description;
    // payload.district = this.district;
    // payload.street = this.street;
    payload.priorityArea = this.priorityArea;
    payload.picture = this.picture?.images;
    payload.pictureLocations = this.picture?.imageLocations;
    if (this.showReason) {
      payload.reason_of_sub_classification = this.reason_of_sub_classification;
    }
    return payload;
  }

  isValid() {
    let check = true;

    check =
      check &&
      // !!this.survey_inspection_number &&
      !!this.main_classification &&
      !!this.sub_classification &&
      !!this.estimated_quantites &&
      !!this.description &&
      !!this.priorityArea?.value &&
      // (!!this.street?.value || !!this.district?.value) &&
      this.picture?.images.length;
    if (this.showReason) {
      check = check && !!this.reason_of_sub_classification;
    }
    return check;
  }
  get showReason() {
    return this.sub_classification?.label == "تسرب المياه";
  }
}

export class CheckInFormModal {
  class_name = "CheckInFormModal";
  isValidation = false;
  isSiteFetching = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isAllFilesUploaded = false;

  _id = null;
  draft = null;
  // formFields = null;
  test = false;
  orignalItem = null;
  show = false;

  // Server fields require for payload
  checkInImage = new FileUploadModal(null, true);
  checkOutImage = new FileUploadModal(null, true);

  // Observation Information
  observationInformations = [];

  //locationPermsion
  locationPermsion = null;
  accessToken =
    "pk.eyJ1IjoiZWZmaWNheC1hZG1pbiIsImEiOiJjbHA5ZWw2eWIyZmsxMnBxdWhza2kwbGxxIn0.wJ-3s6fgva2U4qeyxp2GrQ";

  checkinLocation = null;
  checkoutLocation = null;

  checkinTime = null;
  checkoutTime = null;
  // options

  main_classificationOptions = [];
  getMainClasification() {
    SC.getCall({ url: "main-classification" }).then((res) => {
      this.main_classificationOptions = res.data.data.map((it) => {
        return {
          ...it,
          label: it.name,
          value: it._id,
        };
      });
    });
  }
  getSubClasification(id) {
    SC.getCall({ url: `sub-classification/${id}` }).then((res) => {
      this.sub_classificationOptions = res.data.data.map((it) => {
        return {
          ...it,
          label: it.name,
          value: it._id,
        };
      });
    });
  }

  constructor(props, show) {
    this.orignalItem = props;
    this.isEdit = !!props;
    this.getMainClasification();

    if (props) {
      this.init(props);
    } else {
      this.observationInformations.push(new ObservationInformationRepeater());
    }
    if (!this.isEdit) {
      this.draft = new DraftModal(this);
    }

    if (show) {
      this.show = show;
    }

    // getting locations adress
    reaction(
      () => this.checkInImage.imageLocations,
      (newImageLocations) => {
        let loc = Object.values(newImageLocations);
        this.getLocationWithCord(
          get(loc, "0.longitude", ""),
          get(loc, "0.latitude", ""),
          "checkinLocation"
        );
      }
    );
    reaction(
      () => this.checkOutImage.imageLocations,
      (newImageLocations) => {
        let loc = Object.values(newImageLocations);
        this.getLocationWithCord(
          get(loc, "0.longitude", ""),
          get(loc, "0.latitude", ""),
          "checkoutLocation"
        );
      }
    );

    reaction(
      () => this.checkInImage.imageTime,
      (time) => {
        this.checkinTime = time;
      }
    );

    reaction(
      () => this.checkOutImage.imageTime,
      (time) => {
        this.checkoutTime = time;
      }
    );

    makeAutoObservable(this);
  }
  getLocationWithCord = async (long, lat, attribute) => {
    try {
      // let loc = imageLocations[this.checkInImage.images[0]];
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${this.accessToken}`
      );
      const data = await response.json();
      const address = data.features[0].place_name;
      console.log("address", address);
      this[attribute] = address;
    } catch (error) {
      console.error("Error:", error);
    }

    //  .then((res) => {
    //   // let address = res.json().features[0].place_name;
    //   // console.log("address", address);
    // });
    // console.log("res.json()", response.json().features[0].place_name);
  };

  setlocationPermsion = (permission) => {
    this.locationPermsion = permission;
  };

  get formFields() {
    return {
      "check-in": [],
      observation_information: [],
      "check-out": [],
    };
  }

  //observation repeater
  addObservations() {
    this.observationInformations.push(new ObservationInformationRepeater());
  }

  removeObservation(id) {
    if (this.observationInformations.length > 1){
      this.observationInformations = this.observationInformations.filter(
        (it) => it.uuid != id
      );}
  }

  init(props) {
    if (props) {
      this.setPayload(props);
    }
  }

  getFileExtension(urlOrPath) {
    const parts = urlOrPath.split(".");
    return last(parts);
  }

  seeFile(url) {
    let fileUrl = url;
    let extention = this.getFileExtension(fileUrl);

    MySwal.fire({
      html: (
        <div className="file-viewr">
          <FileViewer
            fileType={extention}
            filePath={fileUrl}
            // onError={(err) => console.log("fileErr", err)}
          />
        </div>
      ),
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Close",
      focusConfirm: false,
      customClass: {
        cancelButton: "close",
      },
    });
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  setValidation(check) {
    toast.error(IntlService.m("PLease fill Required fields!"));
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    if (this.checkValid()) {
      this.isReview = check;
      this.isValidation = false;
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
  }

  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
    this.auditor_email = user.email;
    this.auditor_phone = getValidPhone(user.phone);
  }

  getCurrentStep() {
    return this.stepper?._steps[
      this.stepper?._currentIndex
    ]?.dataset?.target?.replace("#", "");
  }

  setPayload(payload) {
    if (payload) {
      let {
        _id,
        checkInImageLocation,
        checkOutImageLocation,
        checkInImage,
        checkOutImage,
        observationInformations,
        checkinTime,
        checkoutTime,
        checkinLocation,
        checkoutLocation,
      } = payload;

      this.checkinTime = checkinTime;
      this.checkoutTime = checkoutTime;
      this.checkinLocation = checkinLocation;
      this.checkInImage.setImages(checkInImage, checkinTime);
      this.checkInImage.setimageLocations(checkInImageLocation);
      this.observationInformations = observationInformations.map(
        (it) => new ObservationInformationRepeater(it)
      );
      this.checkoutLocation = checkoutLocation;
      this._id = _id;
      this.checkOutImage.setImages(checkOutImage, checkoutTime);
      this.checkOutImage.setimageLocations(checkOutImageLocation);
    }

    autorun(() => {});
    return payload;
  }

  getPayload() {
    let payload = {};
    payload.checkInImage = this.checkInImage?.images;
    payload.checkinLocation = this.checkinLocation;
    payload.checkInImageLocation = this.checkInImage?.imageLocations;
    payload.checkinTime = this.checkInImage?.imageTime;
    payload.checkOutImage = this.checkOutImage?.images;
    payload.checkoutLocation = this.checkoutLocation;

    payload.checkOutImageLocation = this.checkOutImage?.imageLocations;
    payload.observationInformations = this.observationInformations.map((item) =>
      item.getPayload()
    );
    return payload;
  }

  isValid = async () => {
    return true;
  };

  isObservationInformationValid() {
    let check = true;

    this.observationInformations.map((it) => {
      check = check && it.isValid();
    });

    return check || this.is_skip_observation;
  }

  checkValid() {
    let check = true;

    const stepName = this.getCurrentStep();
    // if (stepName == "check-in") {
    //   return check && this.checkInImage.images.length;
    // }
    if (stepName == "observation_information") {
      return check && this.isObservationInformationValid();
    }
    // if (stepName == "check-out") {
    //   return check && this.checkOutImage.images.length;
    // }

    scrollToRequired();
    return check;
  }

  //validation free all steps except
  nextStep() {
    // this.stepper.next();
    if (this.checkValid()) {
      this.stepper.next();
      this.isValidation = false;
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
    this.test = !this.test;
  }

  previousStep() {
    this.stepper.previous();
    this.test = !this.test;
  }
  onStepPress = ({ stepNumber }) => {
    if (this.checkValid()) {
      this.stepper.to(stepNumber);
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
  };

  get isFirstStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === 0;
  }

  get isLastStep() {
    return (
      this.isFinalStep ||
      (this.is_skip_observation && this.stepper?._currentIndex == 1) ||
      this.skipFor
    );
  }

  get isFinalStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === this.stepper?._steps?.length - 1;
  }

  get assignTo() {
    //false on submited
    return AuthUser.isSuperCurentDepartment(this.department.slug);
  }

  get isMovingBack() {
    return this.department.slug === "DSCE" || this.is_moving_backward;
  }
  // get checkinLocation() {
  //   let loc = this.checkInImage.imageLocations[this.checkInImage.images[0]];
  //   return get(loc, "longitude", "") + ", " + get(loc, "latitude", "");
  // }
  get checkInImageLocation() {
    return this.checkInImage.imageLocations;
  }
  // get checkinTime() {
  //   if (this.checkinLocation) return new Date();
  // }
  // get checkoutLocation() {
  //   let loc = this.checkOutImage.imageLocations[this.checkOutImage.images[0]];
  //   return get(loc, "longitude", "") + ", " + get(loc, "latitude", "");
  // }
  get checkOutImageLocation() {
    return this.checkOutImage.imageLocations;
  }
  // get checkoutTime() {
  //   if (this.checkoutLocation) return new Date();
  // }

  get labelForSubmit() {
    if (this.isMovingBack) {
      if (this.department.slug === "PSCO") return "Review";
      return "Approve";
    }

    return "Submit";
  }
}
