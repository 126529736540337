import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import InspectionService from "../../wasfaty/services//InspectionService";
import {
  // CountdownTimer,
  CloseVerificationModal,
} from "../../wasfaty/Models/CloseVerificationModal";
import { observer } from "mobx-react-lite";
import {
  CloseVerificationService,
  IntlService,
  MumtahilService,
  Verification940Service,
} from "../../wasfaty/services";
import { fileIconDetails } from "../../../assets/wasfaty/SVG";
import moment from "moment";
import { Label } from "reactstrap";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";
import AssignToModal from "../../wasfaty/Models/AssignToModal";

import get from "lodash/get";
import { useMemo } from "react";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";
import SubmissionReview from "views/components/SubmissionReview";

const InspectionDetails = ({ itemProp }, props) => {
  const [item, setItem] = useState(itemProp);
  // // const countDown = useMemo(() => new CountdownTimer(), []);
  const data = useMemo(() => new AssignToModal(), []);

  const { id } = useParams();

  const route = useLocation();
  const [errorMess, seterrorMess] = useState("");
  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);

  const Service = {
    "expired-verfication-license": CloseVerificationService,
    "940-verification": Verification940Service,
    "Mumtahil-verification": MumtahilService,
  };

  const getData = () => {
    setItem(null);
    Service[route.pathname.split("/")[1]].show(id).then((res) => {
      if (res.status == 200) {
        setItem(new CloseVerificationModal(res.data?.data, true));

        // countDown.setPayload(res.data?.data);
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      // countDown.updateElapsedTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );
  }

  const TextView = ({ obj, name, head, md = 3 }) => {
    // if (!obj[name]) return null;
    if (!obj[name] && obj[name] != "") return null;

    return (
      <div className={`main-section_info-container_info col-md-${md}`}>
        <div className="info-head">{IntlService.m(head)}</div>
        <div className="info-content">
          {IntlService.m(obj[name]?.label || obj[name]) || "N/A"}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="show-container d-block">
      <SubmissionReview  />
        <div className="show-page p-1">
          <h2>{IntlService.m("Details")}</h2>
          {item?.license_information?.PERMIT_NO && (
            <div>
              <div className="main-section">
                <p className="heading mb-1">
                  {IntlService.m("Identify License Information")}
                </p>
                <div className="main-section_info-container row">
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("License Number")}
                    </div>
                    <div className="info-content">
                      {item?.license_information?.PERMIT_NO}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("Quality Check")}
                    </div>
                    <div className="info-content">
                      {item.quality_check?.label}
                    </div>
                  </div>
                  <div className="main-section_info-container_info col-md-4">
                    <div className="info-head">
                      {IntlService.m("Reason for not visiting")}
                    </div>
                    <div className="info-content">
                      {item.reason_for_not_visiting}
                    </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>

                  {/* <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m(
                    "Select the excavation stage according to the project schedule"
                  )}
                </div>
                <div className="info-content">
                  {IntlService.m(item.excavation_stage?.value)}
                </div>
              </div> */}
                </div>
              </div>{" "}
              <p
                className="m-0 text-bold mb-2"
                style={{ fontSize: 15, fontWeight: 600, marginLeft: 5 }}
              >
                {IntlService.m("The License Information")}
              </p>
              <div className="d-flex flex-wrap">
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Department Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.DEPT_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Service Type")}
                  </div>
                  <div className="info-content">
                    {item.license_information.SERVICE_TYPE || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">{IntlService.m("Order Date")}</div>
                  <div className="info-content">
                    {item.license_information.PERMIT_DATE
                      ? moment(item.license_information.PERMIT_DATE).format(
                          "lll"
                        )
                      : "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Main Contractor Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.CONT_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Sub Contractor Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.CONT_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Consultant Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.CONS_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Laboratory Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.LAB_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Municipality Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.MUNICIPALITY || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("District Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.DISTRICT || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Street Name")}
                  </div>
                  <div className="info-content">
                    {item.license_information.STREET_NAME || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head"> {IntlService.m("Stage")}</div>
                  <div className="info-content">
                    {item.license_information.STAGE || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">{IntlService.m("Longitude")}</div>
                  <div className="info-content">
                    {item.license_information.Y || "N/A"}
                  </div>
                </div>
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head"> {IntlService.m("Latitude")}</div>
                  <div className="info-content">
                    {item.license_information.X || "N/A"}
                  </div>
                </div>
              </div>
              {/* <div className="main-section">
            <p className="heading mb-1">
              {IntlService.m("Operational Information")}
            </p>
            <div className="main-section_info-container row">
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Is there a consultant on site")}
                </div>
                <div className="info-content">{item.consultant_on_site}</div>
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.consultant_on_site_file?.images?.length
                      ? "consultant_on_site_file.images"
                      : "consultant_on_site_file",
                    []
                  ).map((im, index) => (
                    <a href={im}>{IntlService.m("Picture link")}</a>
                  ))}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m("Is there a contractor on site")}
                </div>
                <div className="info-content">{item.contractor_on_site}</div>
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.contractor_on_site_file?.images?.length
                      ? "contractor_on_site_file.images"
                      : "contractor_on_site_file",
                    []
                  ).map((im, index) => (
                    <a href={im}>{IntlService.m("Picture link")}</a>
                  ))}
                </div>
              </div>
              <div className="main-section_info-container_info col-md-4">
                <div className="info-head">
                  {IntlService.m(
                    "Is the excavation in the same stage according to the project schedule in the excavation licensing system"
                  )}
                </div>
                <div className="info-content">{item.same_stage_according}</div>
                <div className="info-content d-flex flex-column">
                  {get(
                    item,
                    item.same_stage_according_file?.images?.length
                      ? "same_stage_according_file.images"
                      : "same_stage_according_file",
                    []
                  ).map((im, index) => (
                    <a href={im}>{IntlService.m("Picture link")}</a>
                  ))}
                </div>
              </div>
              {item.is_evacuation_same ? (
                <div className="main-section_info-container_info col-md-4">
                  <div className="info-head">
                    {IntlService.m(
                      "What is the current stage of the excavation"
                    )}
                  </div>
                  <div className="info-content">{item.current_stage.value}</div>
                </div>
              ) : (
                <div className="main-section_info-container_info col-md-4">
                  <div className="info-head">
                    {IntlService.m("Is there any visual pollution")}
                  </div>
                  <div className="info-content">{item.visual_pollution}</div>
                </div>
              )}
            </div>
          </div> */}
              {!item.is_skip_observation && (
                <div className="main-section">
                  <p className="heading mb-1">
                    {IntlService.m("Observation Information")}
                  </p>
                  {item.observationInformations.map((it, index) => (
                    <div
                      className="main-section_info-container row card p-2"
                      key={index}
                    >
                      <h4 className="info-head">
                        {IntlService.m("Observation Information")}:{index + 1}
                      </h4>
                      <div className="d-flex flex-wrap">
                        <div className="main-section_info-container_info col-md-4">
                          <div className="info-head">
                            {IntlService.m(
                              "Enter the field survey inspection number from (Mumtathil) system"
                            )}
                          </div>
                          <div className="info-content">
                            {it.survey_inspection_number}
                          </div>
                        </div>
                        <div className="main-section_info-container_info col-md-4">
                          <div className="info-head">
                            {IntlService.m("Enter the Main classification")}
                          </div>
                          <div className="info-content">
                            {it.main_classification.name}
                          </div>
                        </div>
                        <div className="main-section_info-container_info col-md-4">
                          <div className="info-head">
                            {IntlService.m("Enter the Sub classification")}
                          </div>
                          <div className="info-content">
                            {it.sub_classification.name}
                          </div>
                        </div>
                        {it.showReason && (
                          <div className="main-section_info-container_info col-md-4">
                            <div className="info-head">
                              {IntlService.m("What the reason of it?")}
                            </div>
                            <div className="info-content">
                              {it.reason_of_sub_classification.label}
                            </div>
                          </div>
                        )}
                        <div className="main-section_info-container_info col-md-4">
                          <div className="info-head">
                            {IntlService.m("The estimated quantites")}
                          </div>
                          <div className="info-content">
                            {it.estimated_quantites}
                          </div>
                        </div>
                        <div className="main-section_info-container_info col-md-4">
                          <div className="info-head">
                            {IntlService.m("Description")}
                          </div>
                          <div className="info-content">{it.description}</div>
                        </div>
                      </div>

                      <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                      {!!it.picture && (
                        <div className="info-content ml-1 d-flex flex-column">
                          {get(
                            it,
                            it.picture?.images?.length
                              ? "picture.images"
                              : "picture",
                            []
                          ).map((im, index) => (
                            <div
                              key={index}
                              className="link-div"
                              onClick={() => fetchImageAndOpenInNewTab(im)}
                            >
                              {IntlService.m("Picture link")}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {item?.license_information?.reportNo && (
            <div className="main-section_info-container row mb-2">
              <p
                className="m-0 text-bold my-2"
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  marginLeft: 5,
                  marginBottom: 20,
                }}
              >
                {IntlService.m("Identify Observation Information")}
              </p>
              <TextView
                head={"Main Classification"}
                obj={item.license_information}
                name={"mainClassification"}
              />
              <TextView
                head={"Sub Classification"}
                obj={item.license_information}
                name={"subClassification"}
              />

              <div className="main-section_info-container_info col-md-3">
                <div className="info-head">{IntlService.m("Solving Date")}</div>
                <div className="info-content">
                  {item.license_information.solvingDate
                    ? moment(item.license_information.solvingDate).format("lll")
                    : "N/A"}
                </div>
              </div>
              <TextView
                head={"Sub Municipality"}
                obj={item.license_information}
                name={"subMunicipality"}
              />
              <TextView
                head={"Street"}
                obj={item.license_information}
                name={"street"}
              />
              <TextView
                head={"District"}
                obj={item.license_information}
                name={"district"}
              />
              <TextView
              head={"Quality Check"}
              obj={item}
              name={"quality_check"}
            />
            <TextView
              head={"Reason for not visiting"}
              obj={item}
              name={"reason_for_not_visiting"}
            />
            <div className="main-section_info-container_info col-md-3">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-3">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>
              <TextView
                head={"Sub Municipality Name"}
                obj={item.license_information}
                name={"subSubMunicipalityName"}
              />
              <TextView
                head={"Sla Track"}
                obj={item.license_information}
                name={"slatrack"}
              />
              <TextView
                head={"Interaction Type"}
                obj={item.license_information}
                name={"interaction_type"}
              />
              <TextView
                head={"Department"}
                obj={item.license_information}
                name={"department"}
              />
              <TextView
                head={"Spl Classification"}
                obj={item.license_information}
                name={"spl_classification"}
              />
              <TextView
                head={"Latitude"}
                obj={item.license_information}
                name={"latitude"}
              />
              <TextView
                head={"Longitude"}
                obj={item.license_information}
                name={"longitude"}
              />

              <div className="main-section_info-container_info col-md-3">
                <div className="info-head">
                  {IntlService.m("Report Location")}
                  <Label className="form-label mt-1">
                    {"\u00A0"}(
                    <a
                      target={"_blank"}
                      href={`${item.license_information.reportLocation}`}
                    >
                      Go To Direction
                    </a>
                    )
                  </Label>
                </div>
              </div>
            </div>
          )}
          {item?.license_information?.inspectionNumber && (
            <div className="main-section_info-container row mb-2">
              <p
                className="m-0 text-bold my-2"
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  marginLeft: 5,
                  marginBottom: 20,
                }}
              >
                {IntlService.m("Identify Observation Information")}
              </p>

              <div className="main-section_info-container row">
                <TextView
                  head={"Municipality"}
                  obj={item.license_information}
                  name={"municipality"}
                />
                <TextView
                  head={"Main Classification"}
                  obj={item.license_information}
                  name={"mainClassification"}
                />
                <TextView
                  head={"Sub Classification"}
                  obj={item.license_information}
                  name={"subClassification"}
                />
                <TextView
                  head={"The Estimated Quantities"}
                  obj={item.license_information}
                  name={"estimatedQuantities"}
                />
                <TextView
                  head={"Unit"}
                  obj={item.license_information}
                  name={"unit"}
                />
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Longitude")}/ {IntlService.m("Latitude")}
                    <Label className="form-label mt-1">
                      {"\u00A0"}(
                      <a
                        target={"_blank"}
                        href={`https://maps.google.com/?q=${item.license_information.longitude},${item.license_information.latitude}`}
                      >
                        Go To Direction
                      </a>
                      )
                    </Label>
                  </div>
                  <div className="info-content">
                    lng: {item.license_information.longitude || "N/A"}
                    <br />
                    lat: {item.license_information.latitude || "N/A"}
                  </div>
                </div>
                <TextView
                  head={"Stage"}
                  obj={item.license_information}
                  name={"CONT_NAME"}
                />
                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Quality Check")}
                  </div>
                  <div className="info-content">
                    {item.quality_check?.label}
                  </div>
                </div>

                <div className="main-section_info-container_info col-md-3">
                  <div className="info-head">
                    {IntlService.m("Reason for not visiting")}
                  </div>
                  <div className="info-content">
                    {item.reason_for_not_visiting}
                  </div>
                </div>
              </div>
            </div>
          )}
          <TextView
            head={"The pathway"}
            obj={item}
            name={"excavation_stage"}
            md={12}
          />
          <TextView
            head={
              item.verification_type?.value === "mumtathil_report_number"
                ? "Has the visual pollution been processed"
                : "Is there any visual pollution"
            }
            obj={item}
            name={"visual_pollution"}
            md={12}
          />{" "}
          {item?.visual_pollution_dropdown?.value && (
            <TextView
              head={"ماهو عنصر التشوه البصري"}
              obj={item}
              name={"visual_pollution_dropdown"}
              md={12}
            />
          )}
          <h3 className="mt-1">{IntlService.m("Visual Pollution Pictures")}</h3>
          {!!item?.visual_pollution_file && (
            <div className="info-content ml-1 d-flex flex-column">
              {get(
                item,
                item?.visual_pollution_file?.images?.length
                  ? "visual_pollution_file.images"
                  : "visual_pollution_file",
                []
              )?.map?.((im, index) => (
                <div
                  className="link-div"
                  onClick={() => fetchImageAndOpenInNewTab(im)}
                >
                  {IntlService.m("Picture link")}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(InspectionDetails);
