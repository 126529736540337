import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { IntlService, MumtahilService } from "../../wasfaty/services";
import "./style.scss";

import "../../pages/List/components/scheduleTask/style.scss";
import { Spinner } from "reactstrap";

import get from "lodash/get";
import { fetchImageAndOpenInNewTab } from "@core/layouts/utils";
import { MumtahilVerificationModal } from "views/wasfaty/Models/MumtahilVerificationModal";
import SubmissionReview from "views/components/SubmissionReview";
import moment from "moment";

const InspectionDetails = ({ itemProp }, props) => {
  const [item, setItem] = useState(itemProp);

  const { id } = useParams();

  const [errorMess, seterrorMess] = useState("");
  useEffect(() => {
    if (id && !itemProp) {
      getData();
    }
  }, [id]);

  const getData = () => {
    setItem(null);
    MumtahilService.show(id).then((res) => {
      if (res.status == 200) {
        setItem(new MumtahilVerificationModal(res.data?.data, true));
      } else {
        seterrorMess(res?.response?.data?.data);
      }
    });
  };

  if (errorMess) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h1>{errorMess}</h1>
      </div>
    );
  }

  if (!item) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner />
      </div>
    );
  }

  const TextView = ({ obj, name, head, md = 3 }) => {
    // if (!obj[name]) return null;
    if (!obj[name] && obj[name] != "") return null;

    return (
      <div className={`main-section_info-container_info col-md-${md}`}>
        <div className="info-head">{IntlService.m(head)}</div>
        <div className="info-content">
          {IntlService.m(obj[name]?.label || obj[name]) || "N/A"}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="show-container d-block ">
      <SubmissionReview  />
        <div className="show-page p-1">
          <h2>{IntlService.m("Details")}</h2>

          <TextView head={"Job Order"} obj={item} name={"job_order"} md={12} />

          <TextView
            head={"Quality Check"}
            obj={item}
            name={"quality_check"}
            md={12}
          />
          <TextView
            head={"Is there any work in the sites?"}
            obj={item}
            name={"is_there_work_in_site"}
            md={12}
          />
          <TextView
            head={"Reason for not visiting"}
            obj={item}
            name={"reason_for_not_visiting"}
            md={12}
          />
          <TextView
            head={"Has the visual pollution been processed"}
            obj={item}
            name={"visual_pollution"}
            md={12}
          />
          {item?.visual_pollution_dropdown?.value && (
            <TextView
              head={"ماهو عنصر التشوه البصري"}
              obj={item}
              name={"visual_pollution_dropdown"}
              md={12}
            />
          )}
          <div className="main-section_info-container_info col-md-12">
                <div className="info-head">
                  {IntlService.m("Submission By")}
                </div>
                <div className="info-content">{item.user.name}</div>
              </div>
              <div className="main-section_info-container_info col-md-12">
                <div className="info-head">
                  {IntlService.m("Date of Visit")}
                </div>
                <div className="info-content">{moment(item.created_at).format("lll")}</div>
              </div>
          <h3 className="mt-1">{IntlService.m("Visual Pollution Pictures")}</h3>
          {!!item?.visual_pollution_file && (
            <div className="info-content ml-1 d-flex flex-column">
              {get(
                item,
                item?.visual_pollution_file?.images?.length
                  ? "visual_pollution_file.images"
                  : "visual_pollution_file",
                []
              )?.map?.((im, index) => (
                <div
                  className="link-div"
                  onClick={() => fetchImageAndOpenInNewTab(im)}
                >
                  {IntlService.m("Picture link")}
                </div>
              ))}
            </div>

          )}

          {!item.is_skip_observation && (
            <div className="main-section">
              <p className="heading mb-1">
                {IntlService.m("Observation Information")}
              </p>
              {item.observationInformations.map((it, index) => (
                <div
                  className="main-section_info-container row card p-2"
                  key={index}
                >
                  <h4 className="info-head">
                    {IntlService.m("Observation Information")}:{index + 1}
                  </h4>
                  <div className="d-flex flex-wrap">
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m(
                          "Enter the field survey inspection number from (Mumtathil) system"
                        )}
                      </div>
                      <div className="info-content">
                        {it.survey_inspection_number}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Main classification")}
                      </div>
                      <div className="info-content">
                        {it.main_classification.name}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Enter the Sub classification")}
                      </div>
                      <div className="info-content">
                        {it.sub_classification.name}
                      </div>
                    </div>
                    {it.showReason && (
                      <div className="main-section_info-container_info col-md-4">
                        <div className="info-head">
                          {IntlService.m("What the reason of it?")}
                        </div>
                        <div className="info-content">
                          {it.reason_of_sub_classification.label}
                        </div>
                      </div>
                    )}
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("The estimated quantites")}
                      </div>
                      <div className="info-content">
                        {it.estimated_quantites}
                      </div>
                    </div>
                    <div className="main-section_info-container_info col-md-4">
                      <div className="info-head">
                        {IntlService.m("Description")}
                      </div>
                      <div className="info-content">{it.description}</div>
                    </div>
                  </div>

                  <h3 className="mt-1">{IntlService.m("Pictures")}</h3>
                  {!!it.picture && (
                    <div className="info-content ml-1 d-flex flex-column">
                      {get(
                        it,
                        it.picture?.images?.length
                          ? "picture.images"
                          : "picture",
                        []
                      ).map((im, index) => (
                        <div
                          key={index}
                          className="link-div"
                          onClick={() => fetchImageAndOpenInNewTab(im)}
                        >
                          {IntlService.m("Picture link")}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(InspectionDetails);
