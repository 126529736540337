import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Progress,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Collapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
import {
  acceptSvg,
  deletesvg,
  infoSvg,
  moreVerticle,
  rejectSvg,
  timeSvgbig,
} from "./SVGSheduleAsset";
import startCase from "lodash/startCase";
import moment from "moment";
import { Edit } from "react-feather";
import { BsClockHistory } from "react-icons/bs";
import Arrowdown from "../../../../../assets/wasfaty/Arrowdown.svg";

import ButtonGreenOutline from "../../../../components/buttons/ButtonGreenOutline";
import HistoryModal from "./HistoryModal";
import ReAssignTaskModal from "./ReAssignTaskModal";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import CalanderDropDown from "./CalanderDropDown";
import TaskModal from "../../../../wasfaty/Models/TaskModal";
import ScheduleTaskService from "views/wasfaty/services/SchduleTaskService";
import TaskService from "views/wasfaty/services/TaskService";
import { ScheduleTaskModal } from "views/wasfaty/Models/SchduleTaskModal";
import {
  IntlService,
  ModuleService,
  NavigationService,
} from "views/wasfaty/services";
import AuthUser from "views/wasfaty/services/AuthService";
import { useNavigate } from "react-router-dom";
import { SC } from "views/wasfaty/Api/serverCall";

/**
 * @param {Object} props
 * @param {TaskModal} props.taskSelected
 */
const RejectModel = observer(
  ({
    rejectModel,
    toggleRejectModel,
    inputValue,
    onChange,
    taskSelected,
    SubmitAction,
  }) => {
    return (
      <Modal isOpen={rejectModel} toggle={toggleRejectModel} centered>
        <ModalHeader>{IntlService.m("Reject Reason")}</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Input type="textarea" value={inputValue} onChange={onChange} />
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (inputValue != "") {
                SubmitAction(taskSelected);
                toggleRejectModel();
              } else {
                toast.error(IntlService.m("Please fill the Reject Reason!"));
              }
            }}
          >
            {IntlService.m("Submit")}
          </Button>
          <Button color="secondary" onClick={toggleRejectModel}>
            {IntlService.m("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

/**
 * @param {Object} props
 * @param {ScheduleTaskModal} props.schedule
 */
function ScheduleCard({ schedule, DeleteData, collapse, setCollapse }) {
  const [modal, setModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [taskSelected, setTaskSelected] = useState([]);
  const [taskHistory, setTaskHistory] = useState([]);
  const [taskToReassign, setTaskToReassign] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [rejectModel, setRejectModel] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);
  const [acceptModel, setAcceptModel] = useState(false);
  const [reasonModel, setReasonModel] = useState(false);
  const [actionTask, setActionTask] = useState(null);
  const [isDueDateUpdateing, setIsDueDateUpdating] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  let percentage = (schedule.completed / schedule.total) * (100).toFixed(2);

  let is_completed = schedule.completed == schedule.total;

  const toggleCollapse = (id) => {
    if (collapse != id) {
      schedule.getTasksForSchedule();
    }
    setCollapse(id);
  };
  const toggleRejectModel = () => {
    setRejectModel((prev) => !prev);
  };
  const toggleCancelModel = () => {
    setCancelModel((prev) => !prev);
  };
  const toggleAcceptModel = () => {
    setAcceptModel((prev) => !prev);
  };
  const toggleReasonModel = () => {
    setReasonModel((prev) => !prev);
  };
  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const onDateChange = (due_date) => {
    setIsDueDateUpdating(true);
    ScheduleTaskService.updateDueDate(schedule._id, due_date).then((res) => {
      setIsDueDateUpdating(false);
      if (res.status == 200) {
        toast.success(res?.data?.data);
        schedule.init(res?.data?.result);
      } else {
        toast.error(res.response.data.data);
      }
    });
  };

  const startDate = () => {
    setLoading(true);
    ScheduleTaskService.startDate(schedule._id).then((res) => {
      if (res.status == 200) {
        let data = res?.data?.data;
        if (data) {
          toast.success(res?.data?.data);
        } else null;

        setLoading(false);
      } else {
        toast.error(res.response.data.data);
        setLoading(false);
      }
    });
  };

  const updateStatus = (status, _id, message) => {
    setLoading(true);

    TaskService.updateStatus(status, _id, message).then((res) => {
      if (res.status == 200) {
        toast.success(res?.data?.data);
        // getAllItems();
      } else {
        toast.error(res.response.data.data);
      }
      setLoading(false);
    });
  };

  const formName = ScheduleTaskService.getFromate(schedule.form_name?.value);
  const taskId = schedule?.task_id;

  // Building the URL with query parameters
  const url = `/${formName}/Details/${taskId}?schedule_id=${schedule._id}`;

  // Navigating to the constructed URL

  const taskApproved = (isQa, taskid) => {
    if (isQa) {
      SC.postCall({
        url: "tasks/qa_approved",
        data: { id: taskid, list: true },
      }).then((res) => {
        if (res.status == 200) {
          toast.success(res?.data?.data);
          schedule.setPayload(res?.data.result);
        } else {
          toast.error(res.response.data.data);
        }
        setTaskSelected(null);
      });
    } else {
      SC.postCall({
        url: "tasks/approved",
        data: { id: taskid, list: true },
      }).then((res) => {
        if (res.status == 200) {
          toast.success(res?.data?.data);
          schedule.setPayload(res?.data.result);
        } else {
          toast.error(res.response.data.data);
        }
        setTaskSelected(null);
      });
    }
  };

  const taskCancel = (taskid) => {
    SC.postCall({ url: "tasks/cancelled", data: { id: taskid } }).then(
      (res) => {
        if (res.status == 200) {
          toast.success(res?.data?.data);
          schedule.setPayload(res?.data.result);
        } else {
          toast.error(res.response.data.data);
        }
        setTaskSelected(null);
      }
    );
  };

  const taskReject = (task) => {
    let data = {
      reason: inputValue,
    };

    // ScheduleTaskService.rejectTask(schedule._id, data).then((res) => {
    //   if (res.status == 200) {
    //     toast.success(res?.data?.data);
    //     schedule.init(res?.data?.result);
    //   } else {
    //     toast.error(res.response.data.data);
    //   }

    SC.postCall({
      url: "tasks/rejected",
      data: { ...data, id: task._id, list: true },
    }).then((res) => {
      if (res.status == 200) {
        toast.success(res?.data?.data);
        schedule.setPayload(res?.data.result);
      } else {
        toast.error(res.response.data.data);
      }
      setTaskSelected(null);
    });
    // });
  };

  /**
   * @param {object} props
   * @param {TaskModal} taskSelected
   */
  const AcceptModel = observer(({ taskSelected }) => {
    return (
      <Modal isOpen={acceptModel} toggle={toggleAcceptModel} centered>
        <ModalBody className="d-flex justify-content-center">
          <h3>
            {IntlService.m(
              "Are you sure want to accept without reviewing the submission!"
            )}
          </h3>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              taskApproved(
                taskSelected.status == "submitted" ||
                  taskSelected.status == "re-submitted"
                  ? true
                  : false,
                taskSelected._id
              );
              toggleAcceptModel();
            }}
          >
            {IntlService.m("Yes")}
          </Button>
          <Button color="secondary" onClick={toggleAcceptModel}>
            {IntlService.m("No")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  });
  const CancelModel = observer(({ taskSelected }) => {
    return (
      <Modal isOpen={cancelModel} toggle={toggleCancelModel} centered>
        <ModalBody className="d-flex justify-content-center">
          <h3>
            {IntlService.m(
              "Are you sure want to cancel without reviewing the submission!"
            )}
          </h3>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              taskCancel(taskSelected._id);
              toggleCancelModel();
            }}
          >
            {IntlService.m("Yes")}
          </Button>
          <Button color="secondary" onClick={toggleCancelModel}>
            {IntlService.m("No")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

  const ReasonModel = observer(({}) => {
    const rejectedEntries = schedule.task_histroy.filter(
      (entry) => entry.status === "rejected"
    );

    const reasonForRejection =
      rejectedEntries.length > 0 ? rejectedEntries[0].reason : "N/A";
    return (
      <Modal isOpen={reasonModel} toggle={toggleReasonModel} centered>
        <ModalHeader>
          <h3>{IntlService.m("Reason For Rejection")}</h3>
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <div>{reasonForRejection}</div>
        </ModalBody>
        <ModalFooter>
          {/* <Button
            color="primary"
            onClick={() => {
              taskApproved();
              toggleAcceptModel();
            }}
          >
            {IntlService.m("Yes")}
          </Button> */}
          <Button color="primary" onClick={toggleReasonModel}>
            {IntlService.m("Ok")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

  /**
   * @param {object} props
   * @param {TaskModal} props.task
   */
  const RenderTask = observer(({ task, schedule }) => {
    return (
      <div className="content row">
        <div className="col-md-2 col-sm-3  d-flex align-items-center">
          <UncontrolledTooltip placement="top" target="history-schdule">
            {IntlService.m("History")}
          </UncontrolledTooltip>
          <div>
            <BsClockHistory
              onClick={() => {
                setTaskHistory(task.history);
                toggle();
              }}
              size={15}
              style={{
                cursor: "pointer",
                marginRight: IntlService.isRtl ? 0 : 5,
                marginLeft: IntlService.isRtl ? 5 : 0,
              }}
              id="history-schdule"
            />
          </div>
          <p className="label d-flex align-items-start flex-column m-0">
            {task.user.name}
          </p>
        </div>
        <div className="col-md-2 col-sm-3">
          <p className="label d-flex align-items-start flex-column m-0">
            {task.license_number}
          </p>

          <ul className="p-0 ps-1">
            {(task?.license_data?.mainClassification ||
              task?.license_data?.MUNICIPALITY) && (
              <li className="title">
                {task?.license_data?.mainClassification ||
                  task?.license_data?.MUNICIPALITY}
              </li>
            )}

            {(task?.license_data?.subClassification ||
              task?.license_data?.subMunicipality) && (
              <li className="title">
                {task?.license_data?.subClassification ||
                  task?.license_data?.subMunicipality}
              </li>
            )}
          </ul>
        </div>

        <div className="col-md-2 col-sm-3">
          <span>
            <span
              className={`badge ${task.status || "text-black"} `}
              style={{ fontWeight: 600 }}
            >
              {IntlService.m(startCase(task.status) || "N/A")}
            </span>
          </span>
        </div>

        <div className="col-md-2 col-sm-9">
          <p className="label">{task.history[0]?.reason}</p>
        </div>
        <div className="col-md-3 col-sm-12 actions">
          {task.status != "pending" && task.status != "initiated" && (
            <>
              {AuthUser.user?.type == "quality_team" &&
                (task.status == "submitted" ||
                  task.status == "re-submitted") && (
                  <>
                    <figure
                      onClick={() => {
                        toggleAcceptModel();
                        setTaskSelected(task);
                      }}
                      id="Accept"
                    >
                      {acceptSvg}
                    </figure>
                    <UncontrolledTooltip placement="top" target="Accept">
                      <FormattedMessage id="Accept" defaultMessage="Accept" />
                    </UncontrolledTooltip>
                    <figure
                      id="Reject"
                      onClick={() => {
                        toggleRejectModel();
                        setTaskSelected(task);
                      }}
                    >
                      {rejectSvg}
                    </figure>
                    <UncontrolledTooltip placement="top" target="Reject">
                      <FormattedMessage id="Reject" defaultMessage="Reject" />
                    </UncontrolledTooltip>
                  </>
                )}

              {AuthUser.user?.type == "track_manager" &&
                task.status == "qa_approved" && (
                  <>
                    <figure
                      onClick={() => {
                        toggleAcceptModel();
                        setTaskSelected(task);
                      }}
                      id="Accept"
                    >
                      {acceptSvg}
                    </figure>
                    <UncontrolledTooltip placement="top" target="Accept">
                      <FormattedMessage id="Accept" defaultMessage="Accept" />
                    </UncontrolledTooltip>
                    <figure
                      id="Reject"
                      onClick={() => {
                        toggleRejectModel();
                        setTaskSelected(task);
                      }}
                    >
                      {rejectSvg}
                    </figure>
                    <UncontrolledTooltip placement="top" target="Reject">
                      <FormattedMessage id="Reject" defaultMessage="Reject" />
                    </UncontrolledTooltip>
                  </>
                )}
              <figure
                id="details"
                onClick={() =>
                  NavigationService.Navigation(
                    // url

                    `/${replacements[schedule.form_name.value]}/Details/${
                      task.submission_id
                    }?task_id=${task._id}`
                    // `/${schedule.form_name.value}/Details/${task._id}`
                  )
                }
              >
                {infoSvg}
              </figure>
              <UncontrolledTooltip placement="top" target="details">
                <FormattedMessage id="Details" defaultMessage="Details" />
              </UncontrolledTooltip>
            </>
          )}
          <div>
            {(task.status == "submitted" || task.status === "reassign") &&
              AuthUser.isInspector && (
                <ButtonGreenOutline
                  className={"mt-2 mb-2"}
                  title="ReSubmit"
                  onClick={() => {
                    navigate(
                      `/${schedule.form_name.value}/edit/${task.submission_id}?task_id=${task._id}`
                    );
                  }}
                />
              )}
            {(task.status === "pending" || task.status === "initiated") &&
            AuthUser.isInspector ? (
              <ButtonGreenOutline
                className={"mt-2 mb-2"}
                title={loading ? "Starting..." : "Start"}
                disabled={loading}
                onClick={() => {
                  if (task.is_pending) {
                    updateStatus("initiated", task._id);
                  }

                  navigate(
                    `/${schedule.form_name.value}?task_id=${task._id}&license=${task.license_number}`
                  );
                }}
              />
            ) : null}

            {(AuthUser.user.type == "inspection_leader" || AuthUser.isAdmin) &&
            task.status == "rejected" ? (
              <div className="d-flex justify-content-end">
                <ButtonGreenOutline
                  className={"mt-2 mb-2"}
                  title="Reassign"
                  onClick={() => {
                    setTaskToReassign(task);
                    setUserModal(true);
                  }}
                />
                <Button
                  color="danger"
                  onClick={() => {
                    // setTaskToReassign(schedule);
                    // setUserModal(true);
                    toggleCancelModel();
                    setTaskSelected(task);
                  }}
                >
                  Cancel{" "}
                </Button>
              </div>
            ) : null}
            {/* {task.status === "reassign" && (
              <ButtonGreenOutline
                className={"mt-2 mb-2"}
                disabled={loading}
                title={loading ? "Starting..." : "Start"}
                onClick={() => {
                  startDate(),
                    navigate(
                      `/${schedule.form_name.value}?task_id=${task._id}`
                    );
                }}
              />
            )} */}
          </div>
        </div>
      </div>
    );
  });

  const TaskHeader = observer(() => {
    return (
      <div
        className="content row"
        style={{
          background: "#EBEBEB",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <div className="col-md-2 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Inspector")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-2 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("License Number")}</div>
            </p>
          </div>
        </div>

        <div className="col-md-2 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Status")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Reason")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 actions">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Actions")}</div>
            </p>
          </div>
        </div>
      </div>
    );
  });

  const replacements = {
    "inspection-visit": "inspection-list",
    "close-license-under-warranty-visit": "close-license-under-warranty",
    "emergency-visit": "emergency-list",
    "940-verification-visit": "940-verification",
    "mababni-inspection-form": "mababni-inspection-visit",
  };

  return (
    <Card className="card-margin">
      <HistoryModal
        modal={modal}
        toggle={toggle}
        taskSelected={taskHistory}
        setTaskHistory={setTaskHistory}
      />

      <RejectModel
        taskSelected={taskSelected}
        onChange={handleInputChange}
        inputValue={inputValue}
        toggleRejectModel={toggleRejectModel}
        rejectModel={rejectModel}
        SubmitAction={taskReject}
      />
      <CancelModel
        taskSelected={taskSelected}
        // onChange={handleInputChange}
      />
      {/* toggleCancelModel */}
      <AcceptModel taskSelected={taskSelected} />
      <ReasonModel />
      {!!userModal && taskToReassign && (
        <ReAssignTaskModal
          modal={userModal}
          task={taskToReassign}
          toggle={() => {
            setUserModal((o) => !o);
            setTaskToReassign(null);
          }}
          close={() => {
            setTaskToReassign(null);
            setUserModal(false);
          }}
          schedule={schedule}
        />
      )}
      <CardBody className="schedule">
        <Row className="head">
          {/* {schedule.canDelete && ( */}

          {/* {!AuthUser.isInspector ? (
            <Col md="1" sm="2" xs="2">
              <UncontrolledDropdown direction="end">
                <DropdownToggle color="white" className="m-0 p-0">
                  {moreVerticle}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                  onClick={() =>
                    NavigationService.Navigation(
                      `/Schedule-Task/Edit/${schedule._id}`
                    )
                  }
                  className="w-100"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <Edit size={"10"} /> <p className="m-0 marginleft">Edit</p>
                  </div>
                </DropdownItem>
                  <DropdownItem
                    onClick={() => DeleteData(schedule._id)}
                    className="w-100"
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      {deletesvg}{" "}
                      <p className="m-0 marginleft">
                        {IntlService.m("Delete")}
                      </p>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          ) : null} */}

          {/* )} */}
          <Col
            md="3"
            sm="8"
            xs="8"
            className="d-flex justify-content-start align-items-center"
          >
            <div>
              <p
                className="title"
                style={{ width: 150, marginLeft: IntlService.isRtl ? 5 : 0 }}
              >
                {IntlService.m(schedule.form_name?.label)}
              </p>
            </div>
          </Col>
          {/* <Col className="bar " md={AuthUser.isInspector ? "2" : "3"}>
            <div className="w-100">
              
              </div>
            </div>
          </Col> */}

          <Col className="bar " md="3">
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-center">
                {/* <p className="label">{schedule.inspector?.name}</p> */}
                <p className="percent">{percentage} %</p>
              </div>
              <Progress
                value={percentage}
                color={is_completed ? "success" : "danger"}
                className="progress"
                style={{
                  backgroundColor: "#F5ABAB",
                  height: "8px",
                }}
              />
            </div>
            <div className="task">
              <p className="title">{IntlService.m("Task")}</p>
              <p className="label">
                {schedule.total < 10
                  ? `0${schedule.total}`
                  : `${schedule.total}`}
              </p>
            </div>
          </Col>
          <Col
            className="time-info"
            md={AuthUser.isInspector ? "3" : "2"}
            sm="6"
          >
            <p className="">{IntlService.m("Due Date")}</p>
            <div className="d-flex justify-content-between align-items-center w-100">
              {isDueDateUpdateing ? (
                <Spinner size={"sm"} />
              ) : (
                <div className="d-flex align-items-center">
                  <figure>{timeSvgbig}</figure>
                  <CalanderDropDown
                    date={schedule.due_date}
                    onDateChange={onDateChange}
                    schedule={schedule}
                  />
                </div>
              )}
            </div>{" "}
          </Col>

          <Col
            md={AuthUser.isInspector ? "3" : "3"}
            // md={"3"}
            sm="6"
            className="d-flex justify-content-between status-badge"
          >
            <span className="d-flex">
              <span
                className={`
               ${is_completed ? "approved" : "rejected" || "text-black"} `}
                style={{ fontWeight: 600 }}
              >
                {IntlService.m(
                  startCase(is_completed ? "completed" : "in-complete") || "N/A"
                )}
              </span>
            </span>

            <img
              src={Arrowdown}
              onClick={() =>
                toggleCollapse(collapse == schedule._id ? "" : schedule._id)
              }
              className={`dropdown-toggle ${
                collapse == schedule._id && "active"
              }`}
            />
          </Col>
          <Col md="12">
            <Collapse isOpen={collapse == schedule._id}>
              <TaskHeader />
              {schedule.taskLoading ? (
                <div className="d-flex justify-content-center my-3">
                  <Spinner />
                </div>
              ) : schedule?.tasks?.length ? (
                <div className="taskslist-container my-2">
                  {schedule?.tasks?.map((task, index) => (
                    <RenderTask task={task} key={index} schedule={schedule} />
                  ))}
                </div>
              ) : (
                "No data"
              )}
            </Collapse>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default observer(ScheduleCard);
