// ** Icons Import

import AuthUser from "views/wasfaty/services/AuthService";
import {
  Compliance,
  RegularVisitIcon,
  Medicine,
  Modules,
  Regions,
  Users,
  Sites,
  Settings,
  language,
} from "../../assets/wasfaty/SVG";

const navigationLinks = [
  {
    id: "Dashboard",
    slug: "dasboard",
    title: "Dashboard",
    icon: () => Compliance,
    navLink: "/dashboard",
    isAuth: true,
  },
  {
    id: "settings",
    slug: "Settings",
    title: "Settings",
    icon: () => Settings,

    children: [
      {
        id: "users",
        slug: "users",
        title: "Users",
        text: "User",
        navLink: "/users",
        icon: () => Users,
      },
      // {
      //   id: "Regions",
      //   slug: "Regions",
      //   title: "Regions",
      //   icon: () => Regions,
      //   navLink: "/Regions",
      // },

      // {
      //   id: "Sites",
      //   slug: "Sites",
      //   title: "Sites",
      //   icon: () => Sites,

      //   navLink: "/Sites",
      // },
      // {
      //   id: "Modules",
      //   slug: "Modules",
      //   title: "Modules",
      //   navLink: "/Modules",
      //   icon: () => Modules,
      // },
      {
        id: "Translation",
        slug: "Translation",
        title: "Translation",
        navLink: "/Translation",
        icon: () => language,
      },
      // {
      //   id: "License",
      //   slug: "License",
      //   title: "License",
      //   navLink: "/License",
      //   icon: () => RegularVisitIcon,
      // },
    ],
  },
  // {
  //   id: "Dashboard",
  //   slug: "Dashboard",
  //   title: "Dashboard",
  //   text: "Dashboard",

  //   navLink: "/Dashboard",
  //   icon: () => Compliance,
  //   skipForQuickAdd: true,
  // },
  // {
  //   id: "request",
  //   slug: "inspection-visit",
  //   title: "Inspection Visit",
  //   navLink: "/inspection-visit",
  //   icon: () => RegularVisitIcon,
  //   isAuth: true,
  // },
  // {
  //   id: "Requests",
  //   slug: "Requests",
  //   title: "Requests",
  //   navLink: "/Requests",
  //   icon: () => RegularVisitIcon,
  // },
  {
    id: "Schedule-Task",
    slug: "Schedule Task Form",
    title:
      AuthUser.isInspector ||
      AuthUser.isQualityTeam ||
      AuthUser?.user?.type == "track_manager"
        ? "My Tasks"
        : "Schedule Task Form",
    icon: () => RegularVisitIcon,
    navLink: "/Schedule-Task",
    isAuth: true,
  },
  {
    id: "Completed-Requests",
    slug: "inspection-visit",
    title: "Inspection Visit",
    navLink: "/inspection-list",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  // {
  //   id: "Completed-Requests",
  //   slug: "expired-verfication-license-form",
  //   title: "Expired verfication license",
  //   navLink: "/expired-verfication-license",
  //   icon: () => RegularVisitIcon,
  //   isAuth: true,
  // },
  {
    id: "Completed-Requests-940",
    slug: "940-verification-form",
    title: "940 Verification Form",
    navLink: "/940-verification",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  {
    id: "operation_and_maintenance_visit_form",
    slug: "operation_and_maintenance_visit_form",
    title: "Operation And Maintenance Visit Form",
    navLink: "/operation_and_maintenance_visit_form",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  {
    id: "field-observation",
    slug: "field-observation-form",
    title: "Field Observation Form",
    navLink: "/field-observation-form",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  {
    id: "Emergency-Visit",
    slug: "emergency-visit",
    title: "Emergency Visit Form",
    navLink: "/emergency-list",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  {
    id: "close-license-under-warranty",
    slug: "close-license-under-warranty",
    title: "Close license under warranty",
    navLink: "/close-license-under-warranty",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },
  {
    id: "mababni_inspection_visit",
    slug: "mababni-inspection-visit",
    title: "Mababni Inspection Visit",
    navLink: "/mababni-inspection-visit",
    icon: () => RegularVisitIcon,
    isAuth: true,
  },

  // %template%
];

export default navigationLinks;
