import { lazy } from "react";
import ClosedUnderWaranty from "views/pages/Show/ClosedUnderWaranty";
import Emergency from "views/pages/Show/Emergency";
import FieldObservation from "views/pages/Show/FieldObservation";
import MababniShow from "views/pages/Show/MababniShow";
import SchduleTask from "views/pages/Show/SchduleTask";

const UserDetails = lazy(() => import("../../views/pages/Show/UserDetails"));

const RegionDetails = lazy(() =>
  import("../../views/pages/Show/RegionDetails")
);
const SiteDetails = lazy(() => import("../../views/pages/Show/SiteDetails"));
const ModuleDetails = lazy(() =>
  import("../../views/pages/Show/ModuleDetails")
);

const Inspection = lazy(() => import("../../views/pages/Show/Inspection"));
const ClosedInformation = lazy(() =>
  import("../../views/pages/Show/ClosedInformation")
);

const OperationAndMaintenance = lazy(() =>
  import("../../views/pages/Show/OperationAndMaintenance")
);

const DetailsRouter = [
  {
    element: <UserDetails />,
    path: "/Users/Details/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
  },
  {
    element: <RegionDetails />,
    path: "/Regions/Details/:id",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
  },
  {
    element: <SiteDetails />,
    path: "/Sites/Details/:id",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
  },

  {
    element: <ModuleDetails />,
    path: "/Modules/Details/:id",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },

  {
    element: <Inspection />,
    path: "/inspection-list/Details/:id",
    route: "inspection-visit",
    slug: "inspection-visit",
    title: "Inspection Visit",
    isAuth: true,
  },
  {
    element: <Emergency />,
    path: "/emergency-list/Details/:id",
    route: "emergency-visit",
    slug: "emergency-visit",
    title: "Emergency Visit",
    isAuth: true,
  },
  {
    element: <ClosedInformation key="940" />,
    path: "/940-verification/Details/:id",
    route: "close-visit",
    slug: "close-visit",
    title: "Close Verification ",
    isAuth: true,
  },
  {
    element: <SchduleTask key="schdule" />,
    path: "/Schedule-Task/Details/:id",
    route: "Schedule-task",
    slug: "Schedule-task",
    title: "Close Verification ",
    isAuth: true,
  },
  {
    element: <OperationAndMaintenance key="mumtahil" />,
    path: "/operation_and_maintenance_visit_form/Details/:id",
    route: "close-visit",
    slug: "close-visit",
    title: "Close Verification ",
    isAuth: true,
  },
  // {
  //   element: <ClosedInformation key="closed" />,
  //   path: "/expired-verfication-license/Details/:id",
  //   route: "expired-verfication-license",
  //   slug: "expired-verfication-license",
  //   title: "Expired verfication license",
  //   isAuth: true,
  // },
  {
    element: <MababniShow key="mambni" />,
    path: "/mababni-inspection-visit/Details/:id",
    route: "mababni-inspection-visit",
    slug: "mababni-inspection-visit",
    title: "Mababni Inspection Visit",
    isAuth: true,
  },
  {
    element: <ClosedUnderWaranty key="closed" />,
    path: "/close-license-under-warranty/Details/:id",
    route: "close-license-under-warranty",
    slug: "close-license-under-warranty",
    title: "Close License Under Warranty",
    isAuth: true,
  },
  {
    element: <FieldObservation />,
    path: "/field-observation-form/Details/:id",
    route: "field-observation",
    slug: "field-observation",
    title: "Field Observation",
    isAuth: true,
  },

  // %template%
];
export default DetailsRouter;
