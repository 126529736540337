import React, { useState } from "react";
import { X } from "react-feather";
import AddFilter from "../listing/AddFilter";
import ExportList from "../listing/ExportList";
import FlatPicker from "react-flatpickr";

import { AppIcon } from "assets/images/icons/svgIcons";
import { Button, Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";

import "./style.scss";
import { IntlService } from "views/wasfaty/services";
export const searchIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
      fill="#606367"
    />
  </svg>
);

export const filterDateIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 14.05C5.35 14.05 4.80433 13.829 4.363 13.387C3.921 12.9457 3.7 12.4 3.7 11.75C3.7 11.1 3.921 10.554 4.363 10.112C4.80433 9.67067 5.35 9.45 6 9.45C6.65 9.45 7.196 9.67067 7.638 10.112C8.07933 10.554 8.3 11.1 8.3 11.75C8.3 12.4 8.07933 12.9457 7.638 13.387C7.196 13.829 6.65 14.05 6 14.05ZM2.3 19.5C1.8 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.2 0.5 17.7V4.3C0.5 3.8 0.675 3.375 1.025 3.025C1.375 2.675 1.8 2.5 2.3 2.5H3.7V0.375H5.225V2.5H12.8V0.375H14.3V2.5H15.7C16.2 2.5 16.625 2.675 16.975 3.025C17.325 3.375 17.5 3.8 17.5 4.3V17.7C17.5 18.2 17.325 18.625 16.975 18.975C16.625 19.325 16.2 19.5 15.7 19.5H2.3ZM2.3 18H15.7C15.7667 18 15.8333 17.9667 15.9 17.9C15.9667 17.8333 16 17.7667 16 17.7V8.3H2V17.7C2 17.7667 2.03333 17.8333 2.1 17.9C2.16667 17.9667 2.23333 18 2.3 18ZM2 6.8H16V4.3C16 4.23333 15.9667 4.16667 15.9 4.1C15.8333 4.03333 15.7667 4 15.7 4H2.3C2.23333 4 2.16667 4.03333 2.1 4.1C2.03333 4.16667 2 4.23333 2 4.3V6.8Z"
      fill="#606367"
    />
  </svg>
);

const ListingTableHeader = (props) => {
  const [selectedFilters, selectFilters] = useState({
    // 5: {
    //   uniqueKey: "5",
    //   label: "Types",
    //   value: ["Permit", "Staff"],
    // },
  });
  const removeFilter = (uniqueKey) => {
    const filteredArr = { ...selectedFilters };
    delete filteredArr[uniqueKey];
    selectFilters(filteredArr);
    props.onFilters(filteredArr);
  };
  const handleFilerSelection = (payload) => {
    const stateClone = { ...selectedFilters };
    let valueArr = stateClone?.[payload.key]?.value || [];
    if (!!payload.checked) {
      valueArr.push(payload.value);
    } else {
      valueArr = valueArr.filter((item) => item !== payload.value);
    }

    Object.assign(stateClone, {
      [payload.key]: {
        uniqueKey: payload.key,
        label: payload.label,
        value: valueArr,
      },
    });
    if (!stateClone?.[payload?.key]?.value?.length) {
      delete stateClone?.[payload?.key];
    }
    if (props.onFilters) {
      props.onFilters(stateClone);
    }
    selectFilters(stateClone);
  };

  return (
    <div className={`listing-header ${props.className}`}>
      {/* <div className="filter-label">
        <AppIcon icon="filterIcon" />
        <span className="align-middle ms-25">Filter</span>
      </div>
      <AddFilter
        onChange={handleFilerSelection}
        selectedFilters={selectedFilters}
        filters={props.filters}
      /> */}
      <div
        className={`selected-filters-container ${Object.values(selectedFilters).length ? "show" : ""
          }`}
      >
        {Object.values(selectedFilters).map((sfItem, sfIndex) => {
          return (
            <div className="selected-filters-item" key={sfIndex}>
              <span className="selected-filters--label">{sfItem.label}:</span>
              <span className="selected-filters--values">
                {sfItem.value.join(",")}
              </span>
              <X
                size={12}
                className="selected-filters--crossButton"
                onClick={() => removeFilter(sfItem.uniqueKey)}
              />
            </div>
          );
        })}
      </div>
      <Col md={"8"} sm="12" className="filter-container">
        {props.isFilter && (
          <div className="filter-date">
            <FlatPicker
              className="form-control date-picker-input"
              options={{
                dateFormat: "d-m-Y",
                mode: "range",
              }}
              onClose={(e) => {
                props.handleChange( e);
              }}
              placeholder={IntlService.m("Filter Date")}
            />
            <figure>
              {props.isLoading ? <Spinner size="sm" /> : filterDateIcon}
            </figure>
          </div>
        )}
        <div>
          <ExportList
            url={props.exportUrl}
            params={props?.exportParams}
            {...props}
          />
        </div>
      </Col>
    </div>
  );
};

export default ListingTableHeader;
