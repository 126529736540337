import { Singleton } from "../Resource";
import get from "lodash/get";
import { getUserData } from "@utils";
import navigation from "@src/navigation/vertical";
import { Navigate } from "react-router-dom";
import { AuthClass } from "./AuthService";
// import ESurveyService from "./ESurveyService";
import ModuleService from "./ModuleService";

class Service extends AuthClass {
  defaultFirstRouteName = "";
  routes = [];
  sideMenu = [];
  eSurveyItems = [];
  constructor() {
    super(arguments);
  }

  async init(routes) {
    this.routes = this.getAvailableRoutes(routes);
    this.sideMenu = this.updateSideMenu(navigation);
    // Add default root route (/) component in the routes
    this.addFirstRedirectRoute();

    // if (!ESurveyService.disable) {
    //   // Survey routes pushing in initial data
    //   const { esurveySideMenu, esurveyRoutes } =
    //     await ESurveyService.getSurveys();

    //   this.routes.push(...esurveyRoutes);
    //   this.sideMenu.push(...esurveySideMenu);
    // }
  }

  get allFormRoutes() {
    return this.routes.filter((r) => r.isForm).map((r) => r.path);
  }

  addFirstRedirectRoute() {
    // Get url of first child from the side menu
    let parentRoute = get(this.sideMenu, "0.children", []).filter(
      ({ isDisable }) => !isDisable
    );
    let route = get(parentRoute, "0.navLink", get(this.sideMenu, "0.navLink"));

    if (this.routes?.length && route) {
      this.routes.push({
        element: <Navigate to={route} />,
        path: "/",
      });
    }
  }

  getAvailableRoutes(routes) {
    return routes.filter((r) => {
      //  Admin can have access to all routes

      if (r.onlyFor) {
        return r.onlyFor?.includes?.(this.role);
      }

      if (this.isAdmin) {
        return true;
      }

      let check = this.availableModules.includes(r.slug?.toLowerCase());
      //check dashboard routes
      let dashboardRoute = r.path.split("/").includes("Dashboard");
      if (dashboardRoute && (this.isPharmacist || this.isAgent)) {
        check = false;
      }

      if (this.isModerator && r.isForm) {
        check = false;
      }

      return check || r.isAuth;
    });
  }

  isAvailableInRoutes(routeName) {
    // Support all routes including singular and plural
    let menuTitle = [
      routeName,
      routeName + "s",
      routeName?.substr(0, routeName.length - 1),
    ];
    if (routeName == "settings") return true;
    if (routeName == "hajj") return true;
    // Only available modules are visiable in the side menu
    return (
      this.availableModules.includes(menuTitle[0]) ||
      this.availableModules.includes(menuTitle[1]) ||
      this.availableModules.includes(menuTitle[2])
    );
  }

  updateSettingRoutes(menuData) {
    let SettingRoute = [];
    if (menuData && !this.isAdmin) {
      let settings = get(
        menuData.filter(({ id }, i) => id === "settings"),
        "0"
      );

      if (settings) {
        SettingRoute = settings.children.filter((sroute) =>
          this.availableModules.includes(sroute.title.toLowerCase())
        );
      }

      if (SettingRoute?.length) {
        settings.children = SettingRoute;
      } else {
        menuData = menuData.filter(({ id }, i) => id !== "settings");
      }
    }
    return menuData;
  }

  updateHajjRoutes(menuData) {
    let HajjRoutes = [];
    if (menuData && !this.isAdmin) {
      let hajj = get(
        menuData.filter(({ id }, i) => id === "hajj"),
        "0"
      );

      if (hajj) {
        HajjRoutes = hajj.children;
      }

      if (HajjRoutes?.length) {
        hajj.children = HajjRoutes;
      } else {
        menuData = menuData.filter(({ id }, i) => id !== "hajj");
      }
    }
    return menuData;
  }

  removeModuleRoutes(menuData) {
    return menuData.filter((menu) => {
      // All routes are accessable by the admin
      // Side menu will show all menu for admin
      if (menu.onlyFor) {
        return menu.onlyFor?.includes?.(this.role);
      }

      if (this.isAdmin) {
        return true;
      }
      // Remove all modules which are not available for the user
      let routeName = menu?.slug?.toLowerCase().replace(" form", ""); //TODO: need to change
      return this.isAvailableInRoutes(routeName) || menu.isAuth;
    });
  }

  removeChildRoutes(menuData) {
    return menuData.map((route) => {
      if (this.isAdmin) {
        return route;
      }
      // Remove all child routes which are not avalilable for the user
      if (route.children) {
        route.children = route.children.filter((child) => {
          if (child.isAvailableForAll) {
            return true;
          }
          if (this.isModerator) {
            if (child.title === "Form") {
              return false;
            }
          }

          if (this.isAgent) {
            //  Agent can't access to any of the dashbords
            if (child.title === "Dashboard") {
              return false;
            }
          }

          if (!this.availableModules.includes(child.slug?.toLowerCase())) {
            return false;
          }

          return true;
        });
      }

      return route;
    });
  }

  getNameByRoute() {
    let slug = location.pathname.split("/")[1];
    const route =
      this.routes.filter((item) => item?.path.includes(slug))?.[0] || {};
    let name = ModuleService.getNameBySlug(slug);
    if (name) {
      route.moduleName = name;
    }
    return route;
  }

  updateNameBySlug(menuData) {
    return menuData.map((menu) => {
      menu.title = ModuleService.getNameBySlug(menu.slug) || menu.title;
      menu.text = ModuleService.getNameBySlug(menu.slug) || menu.text;
      if (menu?.children?.length) {
        this.updateNameBySlug(menu.children);
      }
      return menu;
    });
  }

  getAllForms() {
    // console.log(
    //   "this.routes.filter((route) => route.isAddRoute);",

    // );

    return this.sideMenu
      .map((item) => {
        if (item?.children?.length) {
          return item.children;
        }
        return item;
      })
      .flat()
      .filter((r) => !r.skipForQuickAdd && r.navLink);

    return this.routes.filter((route) => route.isAddRoute);
  }

  /**
   * Update the menu data based on user role and accessablity to the available module
   */
  updateSideMenu(menuData) {
    // Removed Parent Module
    menuData = this.removeModuleRoutes(menuData);

    //setting routes that includes setting modules for all roles
    menuData = this.updateSettingRoutes(menuData);

    // Removed Child route
    menuData = this.removeChildRoutes(menuData);

    // Update name according to module name
    menuData = this.updateNameBySlug(menuData);

    return menuData;
  }
}

const RoutingService = new Service();
export default RoutingService;
