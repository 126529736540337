import { useCallback } from "react";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import debounce from "lodash/debounce";
import { AppIcon } from "assets/images/icons/svgIcons";
import { Input, InputGroup, InputGroupText, Spinner } from "reactstrap";

import "./style.scss";

/**
 * @param {Object} props
 * @param {function} props.onSearchCall
 */

const SearchField = (props) => {
  const intl = useIntl();
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
    ParentDelayedCall(e.target.value);
  };

  const ParentDelayedCall = useCallback(
    debounce((e) => props.onSearchCall(e), 1000),
    []
  );

  return (
    <InputGroup className="searchField">
      <InputGroupText>
        <AppIcon icon="searchIcon" />
      </InputGroupText>
      <Input
        type="text"
        value={search}
        placeholder={intl.formatMessage({
          id: "Search",
          defaultMessage: "Search",
        })}
        onChange={handleSearch}
      />
      {props.searchLoading && (
        <div className="search-spinner">
          <Spinner />
        </div>
      )}
    </InputGroup>
  );
};

export default SearchField;
