export const useParamsCutom = () => {
  const params = new URLSearchParams(new URL(window.location.href).search);

  const task_id = params.get("task_id");
  const license = params.get("license");

  return {
    task_id,
    license,
  };
};
