import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";

import { useState } from "react";
import { validateFields } from "./ValidateFields";
import toast from "react-hot-toast";
import { FieldValidation } from "./RenderFeedback";
import "./style.scss";
import forgotsvg from "./assets/static/img/forgot.svg";

import brandlogowhite from "./assets/static/img/brandlogowhite.svg";
import footor from "./assets/static/img/footor.svg";
import { SC } from "../wasfaty/Api/serverCall";
import { backButton } from "../../assets/wasfaty/SVG";

const ForgotPasswordV1 = () => {
  const frogetPasswordObj = {
    email: "",
  };
  const frogetPasswordObj2 = {
    email: [],
  };
  const [Data, setData] = useState(frogetPasswordObj);
  const [validation, setValidation] = useState(frogetPasswordObj2);
  const [isValidationError, setIsValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
    handleValidationOnChange({ key: state, value });
  };
  const handleValidationOnChange = ({ key, value }) => {
    const response = validateFields({
      value: value,
      not: true,
      isEmail: key === "email",
    });
    setValidation({ ...validation, [key]: response });
  };
  const handleSubmit = async (data) => {
    data.preventDefault();
    if (loading) return;
    const final = {
      email: Data.email,
    };
    // VO === Validation Object
    let VO = {};
    // validating All required fields
    Object.keys(frogetPasswordObj2).map((item) => {
      const response = validateFields({
        value: final[item],
        not: true,
        isEmail: item === "email",
      });
      if (response?.length > 0) {
        VO = {
          ...VO,
          [item]: response,
        };
      }
    });
    if (Object.keys(VO).length) {
      // set validation
      setValidation(VO);
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      setLoading(true);

      {
        SC.postCall({ url: "forget-password", data: final }).then(
          (response) => {
            if (response?.status === 200 && response.data) {
              toast.success("Email Send successfully!");
              setData(frogetPasswordObj);
              navigate("/check-reset");
            } else {
              toast.error(response?.response?.data?.message);
            }
            setLoading(false);
          }
        );
      }
    }
  };

  return (
    <div className="d-flex flex-row-reverse">
      <div className="section">
        <div className="header-navigation">
          <img
            className="isolation-mode"
            alt="Isolation mode"
            src={brandlogowhite}
          />
        </div>
        <div className="container-section">
          <img src={forgotsvg} className="dashboard" />
          <div className="headline-description">
            <div className="headline">Empowering Trust</div>
            <p className="text-wrapper">
              Your Tailored Document Verification and Scrutiny Solution
            </p>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="header-navigation justify-content-start">
          {/* <img
            className="isolation-mode"
            alt="Isolation mode"
            src={brandlogo}
          /> */}

          <div className="back-button" onClick={() => navigate(-1)}>
            <figure>{backButton}</figure>
            <p>Back to Home</p>
          </div>
        </div>
        <div className="container-main">
          <div className="text-and-supporting">
            <div className="text-2">Forgot Password</div>
            <p className="supporting-text">
              Please enter the registered email to reset password
            </p>
            {/* <div className="">
              <AlertDismissable
                visible={visible}
                setVisible={setVisible}
                msg={Message?.toString?.()}
                invalid={"Email or Password invalid"}
              />
            </div> */}
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-container">
              <div>
                <Label>Email</Label>
                <Input
                  type="text"
                  className="input-field-instance"
                  placeholder="Email"
                  value={Data.email}
                  onChange={(e) => {
                    handlechange("email", e.target.value.toLowerCase());
                  }}
                />

                {isValidationError && (
                  <FieldValidation
                    validations={validation.email}
                    isTransparent={true}
                  />
                )}
              </div>

              <Button
                type="submit"
                color="primary button-main"
                block
                className="p-1 authButton mt-1"
              >
                {!loading ? (
                  "Forgot Password"
                ) : (
                  <>
                    <Spinner size="sm" />
                    <span>Submiting...</span>
                  </>
                )}
              </Button>
            </div>
          </Form>{" "}
        </div>

        <footer className="footer">
          <a target="_blank" href="https://ascend.com.sa/">
            <img className="group" alt="Group" src={footor} />
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ForgotPasswordV1;
