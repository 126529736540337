import React, { forwardRef } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import startCase from "lodash/startCase";
import DataTable from "react-data-table-component";
import { IntlService } from "views/wasfaty/services";
import ListingTableHeader from "../ListingTableHeader";
import Pagination from "views/wasfaty/components/AppPagination";
import { Card, CardBody, CardFooter, CardHeader, Input } from "reactstrap";

/**
 *
 * @param {Object} props
 * @param {Boolean} props.isStopPaginationFirstCall
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.noFilters
 * @param {Object} props.pagination
 * @param {Object} props.tableProps
 * @param {Array} props.basicColumns
 * @param {Array} props.Mock
 * @param {Function} props.handlePageChange
 */

const List = (props) => {
  const intl = useIntl();

  const BootstrapCheckbox = forwardRef((props, ref) => (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  return (
    <Card className="app-primary-table">
      {!props.noFilters && <ListingTableHeader {...props} />}
      <CardBody>
        <DataTable
          // noHeader
          fixedHeader={true}
          noDataComponent={
            <div className="p-2">
              {IntlService.m(
                props.isLoading
                  ? "Loading Data..."
                  : "There are no records to display"
              )}
            </div>
          }
          // pagination/
          // selectableRows
          columns={props.basicColumns.map((column) => {
            if (!column.disableStartCase) {
              column.name = intl.formatMessage({
                id: startCase(column.name.toLowerCase()),
                defaultMessage: startCase(column.name.toLowerCase()),
              });
            } else {
              if (column.name instanceof String) {
                column.name = intl.formatMessage({
                  id: column.name,
                  defaultMessage: column.name,
                });
              }
            }

            return column;
          })}
          data={props.Mock}
          selectableRowsComponent={BootstrapCheckbox}
          {...props.tableProps}
        />
      </CardBody>
      <CardFooter>
        <Pagination
          pagination={props.pagination}
          handlePageChange={props.handlePageChange}
          isStopPaginationFirstCall={props.isStopPaginationFirstCall}
        />
      </CardFooter>
    </Card>
  );
};
List.defaultProps = {
  isLoading: false,
  noFilters: false,
};

export default observer(List);
