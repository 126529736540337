import { useNavigate } from "react-router-dom";
import { Form, Button, Input, Container, Row, Col, Label } from "reactstrap";
import { useEffect, useState } from "react";
// import { SC } from "../../utility/Helper/helper";
// import { validatePassword2 } from "../../utility/Helper/ValidatePassword";
// import { reset_password } from "../../utility/Helper/endPoints";
import AlertDismissable from "./assets/Alert";
import { toast } from "react-hot-toast";
import PoweredByLogo from "./assets/MOH_C_AM.png";
import { useParams } from "react-router-dom";
import "./style.scss";
import eficaXLogo from "../../assets/wasfaty/brandlogo.svg";
// import { eficaXLogo } from "../../assets/images/svg/SvgJSX";
// import Language from "../../utility/Helper/Language/Language";
import { validatePassword2 } from "./RenderFeedback";
import { SC } from "../wasfaty/Api/serverCall";

import forgotsvg from "./assets/static/img/forgot.svg";

import brandlogowhite from "./assets/static/img/brandlogowhite.svg";
import footor from "./assets/static/img/footor.svg";
import { backButton } from "../../assets/wasfaty/SVG";
const ResetPasswordV1 = () => {
  const [Data, setData] = useState({ email: "", password: "" });
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();
  let { id } = useParams();
  let url = window.location.pathname;
  const params = useParams();
  let token = params.token;

  useEffect(() => {
    localStorage.clear();

    setData({
      ...Data,
      email: params.email,
    });
  }, []);
  const handleChange = (key, value) => {
    setValidation(false);
    setData({ ...Data, [key]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: Data.email,
      password: Data.password,
      token: token,
      password_confirmation: Data.password,
    };

    if (!validatePassword2(data.password)) {
      setValidation(true);
      setErrorMessage(
        "Password should contain lowercase, uppercase, number and a special character! ex: Ms5C!lcB"
      );
    } else {
      setErrorMessage("");
      setValidation(false);
      setLoading(true);
      SC.postCall({ url: "reset-password", data }).then((res) => {
        if (res.status === 200) {
          toast.success("Password Changed Successfully!");
          navigate("/login");
        } else {
          toast.error(res.response?.data?.data?.[0]);
        }
        setLoading(false);
      });
    }
  };
  return (
    <div className="d-flex flex-row-reverse">
      <div className="section">
        <div className="header-navigation">
          <img
            className="isolation-mode"
            alt="Isolation mode"
            src={brandlogowhite}
          />
        </div>
        <div className="container-section">
          <img src={forgotsvg} className="dashboard" />
          <div className="headline-description">
            <div className="headline">Empowering Trust</div>
            <p className="text-wrapper">
              Your Tailored Document Verification and Scrutiny Solution
            </p>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="header-navigation justify-content-start">
          {/* <img
            className="isolation-mode"
            alt="Isolation mode"
            src={brandlogo}
          /> */}

          <div className="back-button" onClick={() => navigate("/login")}>
            <figure>{backButton}</figure>
            <p>Back to Login</p>
          </div>
        </div>
        <div className="container-main">
          <div className="text-and-supporting">
            <div className="text-2">Reset Password</div>
            <p className="supporting-text">
              Set the new password for your account "{Data.email}"
            </p>
            <div className="">
              <AlertDismissable
                visible={validation}
                setVisible={setValidation}
                msg={"Something went to wrong!"}
                invalid={errorMessage}
              />
            </div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-container">
              <div>
                <Label>New Password</Label>
                <Input
                  autoComplete="new-password"
                  type="password"
                  className="input-field-instance"
                  placeholder="Password"
                  value={Data.password}
                  autoFocus
                  onChange={(e) =>
                    handleChange("password", e.target.value.replace(/ /g, ""))
                  }
                />
              </div>

              <Button
                type="submit"
                color="primary button-main"
                block
                className="p-1 authButton mt-1"
              >
                {!loading ? (
                  "Reset Password"
                ) : (
                  <>
                    <span>Submitting...</span>
                  </>
                )}
              </Button>
            </div>
          </Form>{" "}
        </div>

        <footer className="footer">
          <a target="_blank" href="https://ascend.com.sa/">
            <img className="group" alt="Group" src={footor} />
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ResetPasswordV1;
