import React from "react";

import { Modal, ModalBody } from "reactstrap";

import "./style.scss";

/**
 * @param {Object} props
 * @param {Function} props.toggle
 * @param {Boolean} props.open
 * @param {JSX.Element} props.Content
 *
 *
 */

export default function AppModal(props) {
  const handleClose = () => {
    props.toggle();
  };

  return (
    <Modal
      isOpen={props.open}
      toggle={handleClose}
      className="app-dialog"
      size="lg"
    >
      <ModalBody>{props.Content}</ModalBody>
    </Modal>
  );
}

AppModal.defaultProps = {
  open: false,
};
