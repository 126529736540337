import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap(({ path, distance, center }) => {
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={center || path[0]}
        options={{
          // Customize other map options if needed
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {path.length > 0 && (
          <>
            <Polyline
              path={path}
              options={{
                strokeColor: "#FF0000",
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: "100%",
                  },
                ],
              }}
            />
            <Marker position={path[0]} label="In" />
            <Marker position={path[path.length - 1]} label="Out" />
          </>
        )}
        <div>Total Distance: {distance} km</div>
      </GoogleMap>
    );
  })
);

const MapContainer = ({ origin, destination }) => {
  const [path, setPath] = useState([]);
  const [distance, setDistance] = useState(0);
  const [center, setcenter] = useState(0);

  useEffect(() => {
    // Set the path array with the two points
    // setPath([origin, destination]);
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new window.google.maps.LatLng(origin),
        destination: new window.google.maps.LatLng(destination),
        travelMode: window.google.maps.TravelMode.DRIVING, // Change this if needed
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const route = result.routes[0].overview_path;
          setPath(route);

          // const bounds = new window.google.maps.LatLngBounds();
          // route.forEach((point) => bounds.extend(point));
          // setcenter(bounds.getCenter());

          let totalDistance = 0;
          result.routes[0].legs.forEach((leg) => {
            totalDistance += leg.distance.value;
          });

          const distanceInKm = totalDistance / 1000;
          setDistance(distanceInKm.toFixed(2));
        }
      }
    );

    const midPoint = {
      lat: (origin.lat + destination.lat) / 2,
      lng: (origin.lng + destination.lng) / 2,
    };

    setcenter(midPoint);
    // Calculate the distance between the two points
    const lineDistance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(origin),
        new window.google.maps.LatLng(destination)
      );

    // Convert meters to kilometers for simplicity
    const distanceInKm = lineDistance / 1000;

    setDistance(distanceInKm.toFixed(2));
  }, [origin, destination]);

  return (
    <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBbODegH7VECWmiZHYBTAqd4Bab5bp1bFo&v=3.exp&libraries=geometry,drawing,places,routes`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "400px" }} />}
      mapElement={<div style={{ height: "100%" }} />}
      path={path}
      distance={distance}
      center={center}
      
    />
  );
};

export default MapContainer;

// import html2canvas from "html2canvas";
// import { observer } from "mobx-react-lite";
// import React, { useState, useEffect, useRef } from "react";
// import {
//   withGoogleMap,
//   withScriptjs,
//   GoogleMap,
//   Polyline,
//   Marker,
// } from "react-google-maps";

// const MapContainer = ({ origin, destination, ETA }) => {
//   const [path, setPath] = useState([]);
//   const [distance, setDistance] = useState(0);
//   const [center, setcenter] = useState(0);

//   const mapRef = useRef(null);
//   const takeScreenshot = async () => {
//     // if (mapRef.current) {

//     console.log(
//       "document.querySele",
//       document.querySelector("#MapConta").children[0].children[0].children[1]
//     );
//     try {
//       // Use html2canvas to capture the map content
//       const canvas = await html2canvas(
//         document.querySelector("#MapConta").children[0]
//       );
//       // Convert canvas to image and open in a new tab or save as needed
//       const imgData = canvas.toDataURL("image/png");
//       const img = new Image();
//       img.src = imgData;
//       window.open().document.write(img.outerHTML);
//     } catch (error) {
//       console.log("Error capturing screenshot:", error);
//     }
//   };

//   const Map = withScriptjs(
//     withGoogleMap(({ path, distance, center, ETA }) => {
//       return (
//         <GoogleMap
//           defaultZoom={8}
//           defaultCenter={center || path[0]}
//           options={{
//             // Customize other map options if needed
//             styles: [
//               {
//                 featureType: "poi",
//                 elementType: "labels",
//                 stylers: [{ visibility: "off" }],
//               },
//             ],
//           }}
//         >
//           {path.length > 0 && (
//             <>
//               <Polyline
//                 path={path}
//                 options={{
//                   strokeColor: "#FF0000",
//                   icons: [
//                     {
//                       icon: {
//                         path: window.google.maps.SymbolPath
//                           .FORWARD_CLOSED_ARROW,
//                       },
//                       offset: "100%",
//                     },
//                   ],
//                 }}
//               />
//               <Marker position={path[0]} label="In" />
//               <Marker position={path[path.length - 1]} label="Out" />
//             </>
//           )}
//           <div>Total Distance: {distance} km</div>
//           <div>Total Time: {ETA} Minutes</div>
//         </GoogleMap>
//       );
//     })
//   );
//   useEffect(() => {
//     // Set the path array with the two points
//     // setPath([origin, destination]);
//     const directionsService = new window.google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin: new window.google.maps.LatLng(origin),
//         destination: new window.google.maps.LatLng(destination),
//         travelMode: window.google.maps.TravelMode.DRIVING, // Change this if needed
//       },
//       (result, status) => {
//         if (status === window.google.maps.DirectionsStatus.OK) {
//           const route = result.routes[0].overview_path;
//           setPath(route);

//           // const bounds = new window.google.maps.LatLngBounds();
//           // route.forEach((point) => bounds.extend(point));
//           // setcenter(bounds.getCenter());

//           let totalDistance = 0;
//           result.routes[0].legs.forEach((leg) => {
//             totalDistance += leg.distance.value;
//           });

//           const distanceInKm = totalDistance / 1000;
//           setDistance(distanceInKm.toFixed(2));
//         }
//       }
//     );

//     const midPoint = {
//       lat: (origin.lat + destination.lat) / 2,
//       lng: (origin.lng + destination.lng) / 2,
//     };

//     setcenter(midPoint);
//     // Calculate the distance between the two points
//     const lineDistance =
//       window.google.maps.geometry.spherical.computeDistanceBetween(
//         new window.google.maps.LatLng(origin),
//         new window.google.maps.LatLng(destination)
//       );

//     // Convert meters to kilometers for simplicity
//     const distanceInKm = lineDistance / 1000;

//     setDistance(distanceInKm.toFixed(2));
//   }, [origin, destination]);

//   return (
//     <>
//       <Map
//         googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBbODegH7VECWmiZHYBTAqd4Bab5bp1bFo&v=3.exp&libraries=geometry,drawing,places,routes`}
//         loadingElement={<div style={{ height: "100%" }} />}
//         containerElement={<div style={{ height: "400px" }} />}
//         mapElement={<div style={{ height: "90%" }} id="MapConta" />}
//         path={path}
//         distance={distance}
//         center={center}
//         ETA={ETA}
//       />

//       <button onClick={() => takeScreenshot()}>click</button>
//     </>
//   );
// };

// export default MapContainer;
