import moment from "moment";
import React, { useState } from "react";
import { selectThemeColors } from "@utils";
import toast from "react-hot-toast";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Spinner,
} from "reactstrap";
import ButtonGreen from "../../../../components/buttons/ButtonGreen";
import { UserSearchModal } from "../../../../wasfaty/Models/UserSearchModal";
import Select from "react-select";
import { IntlService, ModuleService } from "../../../../wasfaty/services";
import { observer } from "mobx-react/dist";
import { useMemo } from "react";
import ButtonGreenOutline from "../../../../components/buttons/ButtonGreenOutline";
import TaskModal, { TaskUser } from "../../../../wasfaty/Models/TaskModal";
import TaskService from "views/wasfaty/services/TaskService";
import ScheduleTaskService from "views/wasfaty/services/SchduleTaskService";
import { scheduled } from "rxjs";
import { SC } from "views/wasfaty/Api/serverCall";
/**
 * @param {Object} props
 * @param {TaskModal} props.task
 */
function ReAssignTaskModal({ modal, toggle, task, close, schedule }) {
  const [userModal] = useState(() => new UserSearchModal());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUser, setSelectedUser] = useState(() => {});

  // const moduleId = useMemo(
  //   () => ModuleService.getModuleIdBySlug(task?.form_name),
  //   [task?.form_name]
  // );

  // const isUserHasAccess = selectedUser?.module_ids?.includes?.(moduleId);

  // const isOtherUser = useMemo(() => {
  //   return selectedUser._id != task.user._id || task.is_cancelled;
  // }, [task.user, selectedUser._id]);

  const reassignTask = () => {
    let data = {
      inspector_id: selectedUser.value,
      id: task._id,
    };
    setIsSubmitting(true);
    SC.postCall({ url: "tasks/reassign", data }).then((res) => {
      if (res.status == 200) {
        toast.success(res?.data?.data);
        schedule.setPayload(res?.data.result);
      } else {
        toast.error(res.response.data.data);
      }
      close();
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} unmountOnClose={true}>
      <ModalHeader toggle={toggle}>Inspectors</ModalHeader>
      <ModalBody>
        <Col>
          {/* {!isUserHasAccess && (
            <>
              <i style={{ color: "red", marginRight: 5 }}>
                (User does't have access to selected module!)
              </i>
              {selectedUser.isAssigningModule ? (
                <Spinner color="primary" size="sm" style={{ marginLeft: 5 }} />
              ) : (
                <ButtonGreenOutline
                  title="Assign Module"
                  onClick={() => {
                    selectedUser.attachModule(moduleId);
                  }}
                  style={{ marginLeft: 5, marginBottom: 5 }}
                />
              )}
            </>
          )} */}
          <Select
            placeholder={IntlService.m(
              userModal.isLoading ? "Loading..." : "Select"
            )}
            name="inspector"
            onChange={(e) => {
              setSelectedUser(new TaskUser(e));
            }}
            value={selectedUser}
            theme={selectThemeColors}
            className="react-select mt-2"
            classNamePrefix="select"
            options={userModal.users}
            isLoading={userModal.isLoading}
            onInputChange={userModal.onSearch}
          />
        </Col>
      </ModalBody>

      <ModalFooter>
        {isSubmitting ? (
          <Spinner color="primary" />
        ) : (
          <ButtonGreen
            onClick={reassignTask}
            title="Submit"
            // disabled={!isOtherUser}
          />
        )}
      </ModalFooter>
    </Modal>
  );
}

export default observer(ReAssignTaskModal);
