// import { Link } from "react-router-dom";

import { Button, Container } from "reactstrap";
import PoweredByLogo from "../../assets/wasfaty/ascend-w.png";
import done from "./assets/static/img/done.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { backButtonwhite, infoIcon } from "../../assets/wasfaty/SVG";

const Inbox = () => {
  const navigate = useNavigate();
  return (
    <div className="check-email-screen">
      <div className="headercontent">
        <div className="back-button" onClick={() => navigate("/login")}>
          <figure>{backButtonwhite}</figure>
          <p>Back to Login</p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="box-content ">
          <div className="icon">
            <img src={done} />
          </div>
          <p className="message">Successfully Sent !</p>
          <p className="info">
            Your password reset request has been sent to
            <br /> your email <br />
            please check your inbox
          </p>
        </div>
      </div>

      <a
        href="https://ascend.com.sa/"
        target="_blank"
        rel="noopener noreferrer"
        className="footer"
      >
        <img src={PoweredByLogo} alt="logo" className=" m-2" width="250" />
      </a>
    </div>
  );
};

export default Inbox;
